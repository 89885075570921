import { AuthStatus, State, AuthStep, User } from "../../types/types";

export const getUser = (state: State): User | null => state.AUTH.user;

export const getEmail = (state: State): string => state.AUTH.email;

export const getStep = (state: State): AuthStep => state.AUTH.step;

export const getAuthStatus = (state: State): AuthStatus => state.AUTH.authStatus;

export const getIsAuthLoading = (state: State): boolean => state.AUTH.isLoading;

export const getAuthModalOpen = (state: State) => state.AUTH.isModalOpen;

export const getWhoAmILoading = (state: State) => state.AUTH.whoAmILoading;
