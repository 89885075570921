import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductType, OrderStep } from "../../types/types";

type OrderSliceState = {
  time: string;
  products: ProductType[];
  totalPrice: number;
  step: OrderStep;
  totalMinutesDuration: number;
  isModalOpen: boolean;
  isLoading: boolean;
  choosingProducts: boolean;
  createdId: string;
};

const initialState: OrderSliceState = {
  time: "",
  products: [],
  totalPrice: 0,
  step: "hidden",
  totalMinutesDuration: 0,
  isModalOpen: false,
  isLoading: false,
  choosingProducts: false,
  createdId: "",
};

export const orderSlice = createSlice({
  name: "ORDER",
  initialState,
  reducers: {
    changeTime: (state, action: PayloadAction<string>) => {
      state.time = action.payload;
    },
    addProduct: (state, action: PayloadAction<ProductType>) => {
      if (!state.products.map((e) => e.id).includes(action.payload.id)) {
        state.products.push(action.payload);
        state.totalPrice += action.payload.amount;

        const hours = new Date(action.payload.duration).getUTCHours();
        const minutes = new Date(action.payload.duration).getUTCMinutes();
        state.totalMinutesDuration += minutes + hours * 60;
      }
    },
    removeProduct: (state, action: PayloadAction<ProductType>) => {
      state.products = state.products.filter((e) => e.id !== action.payload.id);
      state.totalPrice -= action.payload.amount;

      const hours = new Date(action.payload.duration).getUTCHours();
      const minutes = new Date(action.payload.duration).getUTCMinutes();
      state.totalMinutesDuration -= minutes + hours * 60;
    },
    changeStep: (state, action: PayloadAction<OrderStep>) => {
      state.step = action.payload;
    },
    changeOrderModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
    changeOrderLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    changeChoosingProducts: (state, action: PayloadAction<boolean>) => {
      state.choosingProducts = action.payload;
    },
    changeCreatedId: (state, action: PayloadAction<string>) => {
      state.createdId = action.payload;
    },
    clearOrder: (state) => {
      state.time = "";
      state.products = [];
      state.totalPrice = 0;
      state.totalMinutesDuration = 0;
    },
  },
});

export default orderSlice.actions;
