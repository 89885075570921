import { FC, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid, CircularProgress } from "@mui/joy";
import { SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { MasterType } from "../../types/types";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getMasters, getMastersLoading } from "../../redux/data/selectors";
import { getFilterCategory } from "../../redux/filter/selectors";
import { Master } from "./master";
import { Swiper, Next, Prev } from "../../ui/swiper/swiper";
import { ArrowIcon } from "../../ui/icons/arrow-icon";
import { MasterSkeleton } from "./skeleton";
import { Button } from "../../ui/button/button";
import dataActions from "../../redux/data/data-slice";
import filterActions from "../../redux/filter/filter-slice";

import "swiper/css";
import "swiper/css/navigation";

const CutyApi = require("cuty_api");

const { setMasters, changeMastersLoading, changeMastersError } = dataActions;
const { changeTabValue } = filterActions;

export const Masters: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [nextPage, setNextPage] = useState<number>(2);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isMoreLoading, setIsMoreLoading] = useState<boolean>(false);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const masters = useAppSelector(getMasters);
  const isLoading = useAppSelector(getMastersLoading);
  const activeCategory = useAppSelector(getFilterCategory);

  const limit = 15;

  useEffect(() => {
    dispatch(changeMastersLoading(true));
    setHasMore(false);
    const apiInstance = new CutyApi.MasterApi();
    apiInstance.listMasters(
      {
        limit,
        page: 1,
        onlineRecordingAllowed: true,
        categoryId: activeCategory,
      },
      (error: string, data: MasterType[]) => {
        if (error) {
          dispatch(changeMastersLoading(false));
          dispatch(changeMastersError(error));
        } else {
          dispatch(changeMastersLoading(false));
          dispatch(setMasters(data));
          setNextPage(2);
          if (data.length < limit) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        }
      }
    );
  }, [dispatch, activeCategory]);

  const showAllClickHandler = () => {
    navigate("/search");
    dispatch(changeTabValue("masters"));
  };

  const loadMore = () => {
    if (masters.length !== 0 && !isLoading && hasMore) {
      const masterInstance = new CutyApi.MasterApi();
      setIsMoreLoading(true);
      masterInstance.listMasters(
        {
          limit,
          page: nextPage,
          onlineRecordingAllowed: true,
          categoryId: activeCategory,
        },
        (error: string, data: MasterType[]) => {
          if (error) {
            setIsMoreLoading(false);
            dispatch(changeMastersError(error));
          } else {
            setIsMoreLoading(false);
            dispatch(setMasters([...masters, ...data]));
            setNextPage((prev) => prev + 1);
            if (data.length < limit) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          }
        }
      );
    }
  };

  return (
    <Box
      sx={{
        marginY: "15px",
        backgroundColor: "#fff",
        borderRadius: "40px",
        padding: "25px",
      }}
    >
      <Grid container display="flex" flexDirection={{ xs: "column", lg: "row" }} spacing={3}>
        <Grid
          display="flex"
          flexDirection={{ xs: "row", lg: "column" }}
          justifyContent="space-between"
          alignItems="center"
          xs={12}
          lg={3}
        >
          <Typography fontSize={{ xs: "16px", sm: "20px", md: "24px" }} fontWeight="bold">
            Лучшие мастера
            {/* в
            <MUIButton
              variant="plain"
              sx={{ padding: "5px", "--Button-gap": 0 }}
              endDecorator={<ArrowIcon color="#962BED" />}
            >
              <Typography fontSize="24px" fontWeight="bold" textColor="#962BED">
                Москве
              </Typography>
            </MUIButton> */}
          </Typography>

          <Button
            colorVariant="black"
            onClick={showAllClickHandler}
            sx={{
              fontSize: { xs: "13px", sm: "15px" },
              paddingY: { xs: "10px", sm: "16px" },
              paddingX: { xs: "15px", sm: "60px" },
            }}
          >
            Посмотреть все
          </Button>
        </Grid>
        <Grid xs={12} lg={9}>
          <Swiper
            spaceBetween={25}
            modules={[Navigation]}
            navigation={{ nextEl: navigationNextRef.current, prevEl: navigationPrevRef.current }}
            lazyPreloadPrevNext={1}
            onReachEnd={loadMore}
            breakpoints={{
              0: { slidesPerView: 1 },
              600: { slidesPerView: 2 },
              900: { slidesPerView: 3 },
            }}
          >
            <Prev ref={navigationPrevRef}>
              <Box sx={{ transform: "rotate(90deg)", display: "flex" }}>
                <ArrowIcon width={30} color="#96979b" height={30} />
              </Box>
            </Prev>

            <Next
              ref={navigationNextRef}
              sx={{
                "&.swiper-button-disabled": {
                  opacity: isMoreLoading ? 1 : 0,
                },
              }}
            >
              {isMoreLoading ? (
                <CircularProgress size="sm" color="neutral" variant="plain" />
              ) : (
                <Box sx={{ transform: "rotate(-90deg)", display: "flex" }}>
                  <ArrowIcon width={30} color="#96979b" height={30} />
                </Box>
              )}
            </Next>

            {isLoading ? (
              Array.from({ length: 3 }).map((_, idx) => (
                <SwiperSlide key={`${idx}-master`}>
                  <MasterSkeleton />
                </SwiperSlide>
              ))
            ) : masters.length !== 0 ? (
              masters.map((master, idx) => (
                <SwiperSlide key={`${master.id}-${idx}`}>
                  <Master master={master} />
                </SwiperSlide>
              ))
            ) : (
              <Box
                sx={{
                  minHeight: "364px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography fontWeight="bold" fontSize={{ xs: "24px", sm: "28px", md: "34px" }}>
                  Ничего не найдено :(
                </Typography>
              </Box>
            )}
          </Swiper>
        </Grid>
      </Grid>
    </Box>
  );
};
