import { FC } from "react";
import { Chip } from "@mui/joy";
import { RecordStatus } from "../../types/types";

type StatusLabelProps = {
  status: RecordStatus;
};

export const StatusLabel: FC<StatusLabelProps> = ({ status }) => {
  let title = "";
  let backgroundColor = "";
  let color = "";

  switch (status) {
    case "request":
      backgroundColor = "#FFF7E8";
      color = "#FFAF15";
      title = "Ожидает подтверждения";
      break;
    case "accepted":
      backgroundColor = "#EBF9Ef";
      color = "#33C75C";
      title = "Подтверждено";
      break;
    case "completed":
      backgroundColor = "#EBF9Ef";
      color = "#33C75C";
      title = "Завершено";
      break;
    case "cancelled":
      backgroundColor = "#ffd4d4";
      color = "#f61e13";
      title = "Отменено";
      break;
    case "skippedByClient":
    case "inProgress":
  }

  return (
    <Chip
      sx={{
        padding: "5px 10px",
        borderRadius: "10px",
        backgroundColor,
        color,
        overflow: "hidden",
      }}
    >
      {title}
    </Chip>
  );
};
