import { FC } from "react";
import { Skeleton, CardContent, Divider, Box } from "@mui/joy";

export const FilterSkeleton: FC = () => (
  <>
    <CardContent>
      <Skeleton
        height={30}
        animation="wave"
        variant="rectangular"
        sx={{ marginBottom: "10px" }}
      />
      <Box sx={{ display: "flex", gap: 1 }}>
        <Skeleton
          width="40%"
          height={30}
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "20px" }}
        />
        <Skeleton
          width="60%"
          height={30}
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "20px" }}
        />
      </Box>
      <Box sx={{ display: "flex", gap: 1, marginTop: 1 }}>
        <Skeleton
          width="60%"
          height={30}
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "20px" }}
        />
        <Skeleton
          width="40%"
          height={30}
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "20px" }}
        />
      </Box>
    </CardContent>

    <CardContent>
      <Divider inset="context" sx={{ marginBottom: "10px" }} />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Skeleton
          width="60%"
          height={30}
          animation="wave"
          variant="rectangular"
        />
        <Skeleton
          width="40%"
          height={28}
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "20px" }}
        />
      </Box>
      <Skeleton
        height={22}
        animation="wave"
        variant="rectangular"
        sx={{ marginBottom: "10px", marginTop: "10px" }}
      />
    </CardContent>

    <CardContent>
      <Divider inset="context" sx={{ marginBottom: "10px" }} />
      <Skeleton
        height={30}
        animation="wave"
        variant="rectangular"
        sx={{ marginBottom: "10px" }}
      />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Skeleton
          height={45}
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "15px" }}
        />
        <Skeleton
          height={45}
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "15px" }}
        />
      </Box>
    </CardContent>

    <CardContent>
      <Divider inset="context" sx={{ marginBottom: "10px" }} />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Skeleton
          height={30}
          width="60%"
          animation="wave"
          variant="rectangular"
        />
        <Skeleton
          width={36}
          height={20}
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "20px" }}
        />
      </Box>
    </CardContent>

    <CardContent>
      <Divider inset="context" sx={{ marginBottom: "10px" }} />
      <Skeleton
        height={30}
        animation="wave"
        variant="rectangular"
        sx={{ marginBottom: "10px" }}
      />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Skeleton
          height={30}
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "20px" }}
        />
        <Skeleton
          height={30}
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "20px" }}
        />
        <Skeleton
          height={30}
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "20px" }}
        />
      </Box>
    </CardContent>

    <CardContent sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Skeleton
          width="40%"
          height={50}
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "10px" }}
        />
        <Skeleton
          width="60%"
          height={50}
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "10px" }}
        />
      </Box>
    </CardContent>
  </>
);
