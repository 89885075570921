import { FC, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../hooks";
import {
  getOrderStep,
  getOrderModalOpen,
  getChoosingProducts,
  getOrderProducts,
} from "../../redux/order/selectors";
import { getMaster, getWindows } from "../../redux/master/selectors";
import { ChooseDateModal } from "./choose-date-modal";
import { ChangeOrderModal } from "./change-order-modal";
import { OrderDoneModal } from "./order-done-modal";
import { Modal } from "../../ui/modal/modal";
import { ScheduleWindow } from "../../types/types";
import { formatDate } from "../../utils/format-date";
import orderActions from "../../redux/order/order-slice";
import masetrActions from "../../redux/master/master-slice";

import { FullscreenModal } from "../../ui/modal/fullscreen-modal";

const CutyApi = require("cuty_api");

const { changeOrderModalOpen, changeChoosingProducts } = orderActions;
const { changeIsWindowsLoading, changeWindows } = masetrActions;

export const OrderModals: FC = () => {
  const dispatch = useAppDispatch();

  const step = useAppSelector(getOrderStep);
  const master = useAppSelector(getMaster);
  const windows = useAppSelector(getWindows);
  const isModalOpen = useAppSelector(getOrderModalOpen);
  const choosingProducts = useAppSelector(getChoosingProducts);
  const orderProducts = useAppSelector(getOrderProducts);

  useEffect(() => {
    if (isModalOpen && step === "date") {
      const durationMinutes =
        orderProducts
          .map((e) => e.duration)
          .reduce((a, b) => a + b.getUTCHours() * 60 + b.getUTCMinutes(), 0) || 60;

      const masterInstance = new CutyApi.MasterApi();
      dispatch(changeIsWindowsLoading(true));
      masterInstance.getMasterWindows(
        master!.user.user_id,
        { durationMinutes },
        (error: any, data: ScheduleWindow[]) => {
          if (error) {
            console.error(error);
            dispatch(changeWindows([]));
            dispatch(changeIsWindowsLoading(false));
          } else {
            dispatch(changeWindows(data));
            dispatch(changeIsWindowsLoading(false));
          }
        }
      );
    }
  }, [dispatch, isModalOpen, master, orderProducts, step]);

  const getWorkingTime = (i: "start" | "end"): string => {
    if (windows.length !== 0) {
      const firstWindows = windows[0].windows;
      if (firstWindows) {
        const idx = i === "start" ? 0 : firstWindows.length - 1;
        return formatDate(firstWindows[idx].toISOString(), {
          hour: "2-digit",
          minute: "2-digit",
        });
      }
    }
    return "-";
  };
  const working_time = `${getWorkingTime("start")} - ${getWorkingTime("end")}`;

  const closeHandler = () => {
    dispatch(changeOrderModalOpen(false));
    if (choosingProducts) {
      dispatch(changeChoosingProducts(false));
    }
  };

  const getModal = () => {
    return step === "date" ? (
      <ChooseDateModal master_name={master!.user.display_name} />
    ) : step === "sum" ? (
      <ChangeOrderModal working_time={working_time} />
    ) : step === "done" ? (
      <OrderDoneModal />
    ) : null;
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={closeHandler}
        sx={{ display: { xs: "none", md: "block" }, position: "fixed", zIndex: 1 }}
        padding={step === "done" ? "80px 70px" : "27px"}
        width={step === "done" ? "600px" : "1000px"}
      >
        {getModal()}
      </Modal>

      <FullscreenModal
        open={isModalOpen}
        onClose={closeHandler}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        {getModal()}
      </FullscreenModal>
    </>
  );
};
