import { FC, ReactNode } from "react";
import {
  Modal as MUIModal,
  ModalDialog,
  ModalClose,
  modalCloseClasses,
  modalClasses,
} from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";

type FullscreenModalProps = {
  onClose: () => void;
  open: boolean;
  children: ReactNode[] | ReactNode;
  sx?: SxProps;
  marginTop?: number;
};

export const FullscreenModal: FC<FullscreenModalProps> = ({
  open,
  onClose,
  children,
  sx,
  marginTop = 80,
}) => (
  <MUIModal
    open={open}
    onClose={onClose}
    sx={{
      ...sx,
      "::before": {
        content: '""',
        position: "absolute",
        top: `${marginTop - 12}px`,
        borderRadius: "16px",
        background: "#fafafa",
        width: "100%",
        height: "24px",
      },
      "::after": {
        content: '""',
        width: "36px",
        height: "5px",
        borderRadius: "2.5px",
        background: "#c1c1c3",
        position: "absolute",
        top: `${marginTop - 8}px`,
        left: "50%",
        transform: "translateX(-50%)",
        transition: ".2s",
      },
      [`.${modalClasses.backdrop}`]: {
        background: "#0009",
      },
    }}
  >
    <>
      <ModalClose
        variant="solid"
        sx={{
          borderRadius: "50%",
          background: "#a6a6a6",
          color: "#4d4d4d",
          ":hover": {
            background: "#909090",
          },
          [`&&.${modalCloseClasses.root}`]: {
            top: `${marginTop - 55}px`,
            right: "20px",
          },
        }}
      />
      <ModalDialog
        layout="fullscreen"
        sx={{
          paddingBottom: "90px",
          marginTop: `${marginTop}px`,
          background: "#fafafa",
          boxShadow: "none",
          overflow: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {children}
      </ModalDialog>
    </>
  </MUIModal>
);
