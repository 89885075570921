import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MasterType, ProductType, ScheduleWindow } from "../../types/types";

type MasterSliceState = {
  master: MasterType | null;
  isMasterLoading: boolean;
  products: ProductType[];
  isProductsLoading: boolean;
  windows: ScheduleWindow[];
  isWindowsLoading: boolean;
};

const initialState: MasterSliceState = {
  master: null,
  isMasterLoading: false,
  products: [],
  isProductsLoading: false,
  windows: [],
  isWindowsLoading: false,
};

export const masterSlice = createSlice({
  name: "MASTER",
  initialState,
  reducers: {
    changeMaster: (state, action: PayloadAction<MasterType>) => {
      state.master = action.payload;
    },
    changeIsMasterLoading: (state, action: PayloadAction<boolean>) => {
      state.isMasterLoading = action.payload;
    },
    changeProducts: (state, action: PayloadAction<ProductType[]>) => {
      state.products = action.payload;
    },
    changeIsProductsLoading: (state, action: PayloadAction<boolean>) => {
      state.isProductsLoading = action.payload;
    },
    changeWindows: (state, action: PayloadAction<ScheduleWindow[]>) => {
      state.windows = action.payload;
    },
    changeIsWindowsLoading: (state, action: PayloadAction<boolean>) => {
      state.isWindowsLoading = action.payload;
    },
  },
});

export default masterSlice.actions;
