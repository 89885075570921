import { FC } from "react";
import { Skeleton, AspectRatio, CardContent } from "@mui/joy";
import { SearchCategoryCard } from "./search-category";

export const SearchCategorySkeleton: FC = () => (
  <SearchCategoryCard>
    <AspectRatio
      ratio={1}
      sx={{ width: { xs: "46px", md: "70px" }, borderRadius: "50%" }}
    >
      <Skeleton animation="wave" variant="circular" />
    </AspectRatio>
    <CardContent>
      <Skeleton
        animation="wave"
        variant="rectangular"
        sx={{
          marginTop: "2px",
          height: { xs: "11px", md: "17px" },
          width: "80%",
          marginX: "auto",
          marginBottom: "23px",
        }}
      />
    </CardContent>
  </SearchCategoryCard>
);
