import { FC } from "react";
import { Skeleton, Card, CardCover } from "@mui/joy";

export const ProductSkeleton: FC = () => (
  <Card
    sx={{
      minHeight: { xs: "300px", md: "340px" },
      borderRadius: "25px",
      padding: "10px",
      border: "none",
    }}
  >
    <CardCover>
      <Skeleton animation="wave" variant="rectangular" />
    </CardCover>
  </Card>
);
