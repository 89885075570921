import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ScheduleRecordType } from "../../types/types";

type RecordsSliceState = {
  records: ScheduleRecordType[];
  isLoading: boolean;
};

const initialState: RecordsSliceState = {
  records: [],
  isLoading: false,
};

export const recordsSlice = createSlice({
  name: "RECORDS",
  initialState,
  reducers: {
    changeRecords: (state, action: PayloadAction<ScheduleRecordType[]>) => {
      state.records = action.payload;
    },
    changeRecordsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export default recordsSlice.actions;
