import { FC, useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  Box,
  Typography,
  Button as MUIButton,
  styled,
  buttonClasses,
  Chip,
  ChipDelete,
  Skeleton,
  SvgIcon,
  FormControl,
  FormLabel,
} from "@mui/joy";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getMaster, getIsMasterLoading, getIsWindowsLoading } from "../../redux/master/selectors";
import {
  getOrderProducts,
  getOrderTotalPrice,
  getOrderTime,
  getTotalMinutesDuration,
  getOrderLoading,
} from "../../redux/order/selectors";
import { getAuthStatus, getUser, getWhoAmILoading } from "../../redux/auth/selectors";
import { BackButton } from "../back-button/back-button";
import { ReviewsCount } from "../reviews-count/reviews-count";
import { Picture } from "../../ui/picture/picture";
import { ArrowIcon } from "../../ui/icons/arrow-icon";
import { Button } from "../../ui/button/button";
import { FixedBottomBox } from "../../ui/modal/fixed-bottom-box";
import { Input } from "../../ui/input/input";
import { PhoneInput } from "../../ui/phone-input/phone-input";
import { formatDate } from "../../utils/format-date";
import { formatPrice } from "../../utils/format-price";
import {
  getHoursRightDeclension,
  getMinutesRightDeclension,
} from "../../utils/get-right-declension";
import { ProductType, ScheduleRecordType, ScheduleWindow, User } from "../../types/types";
import { toastOptions } from "../../const";

import orderActions from "../../redux/order/order-slice";
import masterActions from "../../redux/master/master-slice";
import authActions from "../../redux/auth/auth-slice";

import noImage from "../../assets/no-image.svg";
import { ReactComponent as Calendar } from "../../assets/calendar.svg";

const CutyApi = require("cuty_api");

const {
  removeProduct,
  changeStep,
  changeOrderLoading,
  clearOrder,
  changeOrderModalOpen,
  changeChoosingProducts,
  changeCreatedId,
} = orderActions;
const { changeIsWindowsLoading, changeWindows } = masterActions;
const { changeAuthModalOpen, changeStep: changeAuthStep } = authActions;

const plusIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M9.99101 17.9095L8.01111 17.9095L8.01111 9.98995L0.091512 9.98995V8.01005L8.01111 8.01005L8.01111 0.0904544L9.99101 0.0904547L9.99101 8.01005L17.9106 8.01005V9.98995L9.99101 9.98995L9.99101 17.9095Z"
      fill="#bfc0c2"
    />
  </svg>
);

const CustomButton = styled(MUIButton)({
  border: "1px dashed #e6e6e6",
  borderRadius: "20px",
  background: "#fff",
  height: "60px",
  padding: "20px",
  fontSize: "17px",
  color: "#000",
  transition: ".2s",
  boxShadow: "0 0 5px -1px #ddd",
  "&:hover": {
    background: "rgba(0,0,0,0.03)",
  },
  "&:active": {
    background: "rgba(0,0,0,0.07)",
  },
});

type ChangeOrderModalProps = {
  working_time: string;
};

export const ChangeOrderModal: FC<ChangeOrderModalProps> = ({ working_time }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const master = useAppSelector(getMaster);
  const products = useAppSelector(getOrderProducts);
  const totalPrice = useAppSelector(getOrderTotalPrice);
  const time = useAppSelector(getOrderTime);
  const totalMinutesDuration = useAppSelector(getTotalMinutesDuration);
  const isLoading = useAppSelector(getOrderLoading);
  const isMasterLoading = useAppSelector(getIsMasterLoading);
  const isWindowsLoading = useAppSelector(getIsWindowsLoading);
  const authStatus = useAppSelector(getAuthStatus);
  const user = useAppSelector(getUser);
  const whoAmILoading = useAppSelector(getWhoAmILoading);

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      email: user && user.email ? user.email : "",
      displayName: user && user.display_name ? user.display_name : "",
      phoneNumber: user && user.phone ? user.phone : "",
    },
  });

  useEffect(() => {
    if (authStatus === "authorized" && user) {
      setValue("email", user.email);
      setValue("displayName", user.display_name);
      setValue("phoneNumber", user.phone);
    }
  }, [authStatus, user, setValue]);

  const closeModalOpen = () => {
    dispatch(changeOrderModalOpen(false));
    dispatch(changeChoosingProducts(false));
  };

  const navigateToMaster = () => {
    dispatch(changeOrderModalOpen(false));
    navigate(`/master/${master!.nickname}`);
  };

  const chooseAnotherProductHandler = () => {
    dispatch(changeChoosingProducts(true));
    navigateToMaster();
  };

  const removeClickHandler = (product: ProductType) => {
    dispatch(removeProduct(product));
  };

  const getHoursDuration = (duration: Date): string | null => {
    const hours = duration.getUTCHours();
    return hours > 0 ? `${hours} ${getHoursRightDeclension(hours)}` : null;
  };

  const getMinutesDuration = (duration: Date): string | null => {
    const minutes = duration.getUTCMinutes();
    return minutes > 0 ? `${minutes} ${getMinutesRightDeclension(minutes)}` : null;
  };

  const submitHandler: SubmitHandler<any> = () => {
    if (authStatus === "unauthorized") {
      dispatch(changeAuthModalOpen(true));
      dispatch(changeAuthStep("signIn"));
    } else if (products.length === 0) {
      toast.warning("Выберите хотя бы одну услугу", toastOptions);
    } else if (time === "") {
      toast.warning("Выберите дату и время", toastOptions);
    } else {
      dispatch(changeOrderLoading(true));
      const userInstance = new CutyApi.UserApi();
      const opts: { user: Partial<User> } = {
        user: {
          display_name: control._formValues.displayName,
          email: control._formValues.email,
          phone: control._formValues.phoneNumber,
        },
      };
      userInstance.updateUser(opts, (error: any, data: User) => {
        if (error) {
          console.error(error);
          dispatch(changeOrderLoading(false));
          toast.error("Что-то пошло не так", toastOptions);
        } else {
          const currentMinutes = new Date(time).getMinutes();
          const scheduleInstance = new CutyApi.ScheduleRecordApi();
          scheduleInstance.createScheduleRecordByClient(
            {
              putScheduleRecord: {
                owner: master?.user.user_id,
                products: products.map((product) => product.id),
                total_price: totalPrice,
                start_day: dayjs(time).format("YYYY-MM-DD"),
                start_time: time,
                end_time: new Date(
                  new Date(time).setMinutes(currentMinutes + totalMinutesDuration)
                ),
              },
            },
            (error: any, data: ScheduleRecordType) => {
              if (error) {
                console.error(error);
                dispatch(changeOrderLoading(false));
              } else {
                dispatch(changeOrderLoading(false));
                dispatch(clearOrder());
                dispatch(changeStep("done"));
                dispatch(changeCreatedId(data.id));
                if (master) {
                  const masterInstance = new CutyApi.MasterApi();
                  dispatch(changeIsWindowsLoading(true));
                  masterInstance.getMasterWindows(
                    master.user.user_id,
                    {},
                    (error: any, data: ScheduleWindow[]) => {
                      if (error) {
                        console.error(error);
                        dispatch(changeIsWindowsLoading(false));
                      } else {
                        dispatch(changeWindows(data));
                        dispatch(changeIsWindowsLoading(false));
                      }
                    }
                  );
                }
              }
            }
          );
        }
      });
    }
  };

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Box
            minHeight="80vh"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gap="15px"
          >
            <Box>
              <Box display="flex" alignItems="center">
                <BackButton onClick={closeModalOpen} />
                <Typography
                  fontSize="24px"
                  fontWeight="bold"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  {isMasterLoading ? (
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      sx={{
                        height: "28px",
                        width: "280px",
                        marginBottom: "6px",
                        marginTop: "2px",
                      }}
                    />
                  ) : (
                    master && master.user.display_name
                  )}
                  {isWindowsLoading || isMasterLoading ? (
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      sx={{
                        height: "18px",
                        width: "120px",
                        marginBottom: "6px",
                        marginTop: "2px",
                      }}
                    />
                  ) : (
                    <Typography fontSize="17px" fontWeight="400" textColor="#939397">
                      {working_time}
                    </Typography>
                  )}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "30px",
                  padding: "10px",
                  borderRadius: "20px",
                  backgroundColor: "#fff",
                  marginTop: "20px",
                  alignItems: "center",
                  boxShadow: "0 1px 5px -1px #ddd",
                  cursor: "pointer",
                  transition: "0.2s",
                  ":hover": {
                    boxShadow: "0 1px 10px 1px #ddd",
                  },
                  ":active": {
                    backgroundColor: "rgba(0,0,0,0.03)",
                  },
                }}
                onClick={navigateToMaster}
              >
                {isMasterLoading ? (
                  <>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      sx={{
                        width: "73px",
                        height: "73px",
                        borderRadius: "10px",
                      }}
                    />
                    <Box>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        sx={{
                          height: "24px",
                          width: "220px",
                          marginBottom: "10px",
                        }}
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        sx={{ height: "20px", width: "120px" }}
                      />
                    </Box>
                  </>
                ) : (
                  master && (
                    <>
                      <Picture
                        src={master.user.photo_url ? master.user.photo_url : noImage}
                        sx={{
                          minWidth: "73px",
                          minHeight: "73px",
                          borderRadius: "10px",
                        }}
                      />
                      <Box>
                        <Typography fontSize="24px" fontWeight="bold">
                          {master.user.display_name}
                        </Typography>
                        {master.reviews_count && master.reviews_score && (
                          <ReviewsCount
                            count={master.reviews_count}
                            score={master.reviews_score}
                            full
                          />
                        )}
                      </Box>
                    </>
                  )
                )}
              </Box>

              {products.map((product) => (
                <Chip
                  key={product.id}
                  size="lg"
                  sx={{
                    padding: "10px",
                    borderRadius: "20px",
                    backgroundColor: "#fff",
                    marginTop: "8px",
                    minWidth: "100%",
                    maxWidth: "100%",
                    boxShadow: "0 1px 5px -1px #ddd",
                  }}
                  endDecorator={
                    <ChipDelete
                      onDelete={() => removeClickHandler(product)}
                      sx={{
                        marginRight: "20px",
                        background: "transparent",
                        transition: ".2s",
                        " svg": {
                          color: "#bfc0c2",
                          width: "24px",
                          height: "24px",
                        },
                        "&:hover": {
                          background: "#c94040",
                          " svg": {
                            color: "#fff",
                          },
                        },
                      }}
                    />
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Picture
                      src={product.photo_url ? product.photo_url : noImage}
                      sx={{
                        minWidth: "73px",
                        minHeight: "73px",
                        borderRadius: "10px",
                      }}
                    />
                    <Box maxWidth="calc(100% - 110px)">
                      <Typography
                        fontSize="24px"
                        fontWeight="bold"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {product.title}
                      </Typography>
                      <Typography fontSize="17px">
                        {formatPrice(product.amount, product.currency)}{" "}
                        <Typography fontSize="17px" textColor="#95969a">
                          &#183; {getHoursDuration(product.duration)}{" "}
                          {getMinutesDuration(product.duration)}
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                </Chip>
              ))}

              <Box
                display="flex"
                gap="5px"
                marginTop="8px"
                sx={{
                  cursor: "pointer",
                  "&:hover button": { background: "rgba(0,0,0,0.03)" },
                  "&:active button": { background: "rgba(0,0,0,0.07)" },
                }}
                onClick={chooseAnotherProductHandler}
              >
                <CustomButton>{plusIcon}</CustomButton>
                <CustomButton
                  sx={{
                    flexGrow: 1,
                    justifyContent: "flex-start",
                    color: "#95969a",
                  }}
                >
                  {products.length === 0 ? "Выбрать услугу" : "Выбрать еще услугу"}
                </CustomButton>
              </Box>

              <CustomButton
                onClick={() => dispatch(changeStep("date"))}
                startDecorator={
                  <SvgIcon component={Calendar} sx={{ width: "24px", height: "24px" }} />
                }
                endDecorator={
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="10px"
                    color="#929497"
                    fontWeight="normal"
                  >
                    {time.length > 0 &&
                      `${formatDate(time, {
                        month: "long",
                        weekday: "short",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}`}
                    <Box sx={{ transform: "rotate(-90deg)" }}>
                      <ArrowIcon />
                    </Box>
                  </Box>
                }
                sx={{
                  border: "none",
                  marginTop: "8px",
                  width: "100%",
                  justifyContent: "flex-start",
                  boxShadow: "0 1px 5px -1px #ddd",
                  [` .${buttonClasses.startDecorator}`]: {
                    marginRight: "40px",
                  },
                  [` .${buttonClasses.endDecorator}`]: {
                    marginLeft: "auto",
                  },
                }}
              >
                Выбрать дату и время
              </CustomButton>

              {products.length !== 0 && (
                <Box marginTop="30px" display="flex" flexDirection="column" gap="15px">
                  <Typography fontSize="17px" textColor="#9E9797">
                    Цена
                  </Typography>
                  <Box>
                    {products.map((product) => (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        key={`${product.id}-order`}
                      >
                        <Typography fontSize="20px" fontWeight="bold" maxWidth="85%">
                          {product.title}
                        </Typography>
                        <Typography fontSize="20px" fontWeight="bold" textColor="#962BED">
                          {formatPrice(product.amount, product.currency)}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography fontSize="24px" fontWeight="bold">
                      Итого
                    </Typography>
                    <Typography fontSize="24px" fontWeight="bold" textColor="#962BED">
                      {formatPrice(totalPrice, products[0].currency)}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>

            {authStatus === "authorized" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px",
                  margin: "-5px",
                  borderRadius: "20px",
                  backgroundColor: "#fff",
                  marginY: "20px",
                  boxShadow: "0 1px 5px -1px #ddd",
                }}
              >
                <Typography fontWeight="bold" fontSize="20px" marginBottom="8px">
                  Ваши данные
                </Typography>
                <Box sx={{ display: "flex", gap: "10px", justifyContent: "space-between" }}>
                  <Controller
                    name="displayName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, name }, fieldState: { invalid } }) => (
                      <FormControl sx={{ width: "33%" }}>
                        {whoAmILoading ? (
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{ height: "48px", borderRadius: "15px", marginTop: "25px" }}
                          />
                        ) : (
                          <Input
                            name={name}
                            label="Имя"
                            sx={{ height: "48px" }}
                            marginY={0}
                            fontSize={16}
                            placeholder="Ваше имя"
                            value={value}
                            onChange={onChange}
                            invalid={invalid}
                          />
                        )}
                      </FormControl>
                    )}
                  />

                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange }, fieldState: { invalid } }) => (
                      <FormControl sx={{ width: "33%" }}>
                        <FormLabel>Телефон</FormLabel>
                        {whoAmILoading ? (
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{ height: "48px", borderRadius: "15px" }}
                          />
                        ) : (
                          <PhoneInput
                            value={value}
                            onChange={onChange}
                            isValid={!invalid}
                            fontSize={16}
                          />
                        )}
                      </FormControl>
                    )}
                  />

                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { value, name } }) => (
                      <FormControl sx={{ width: "33%" }}>
                        {whoAmILoading ? (
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{ height: "48px", borderRadius: "15px", marginTop: "25px" }}
                          />
                        ) : (
                          <Input
                            name={name}
                            label="Почта"
                            sx={{ height: "48px" }}
                            marginY={0}
                            fontSize={16}
                            placeholder="Ваша почта"
                            type="email"
                            disabled={control._formValues.email}
                            value={value}
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Box>
              </Box>
            )}

            <Button type="submit" sx={{ alignSelf: "flex-end" }} loading={isLoading}>
              Записаться
            </Button>
          </Box>
        </form>
      </Box>

      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Box display="flex" alignItems="center">
            <BackButton onClick={closeModalOpen} />
            <Typography
              fontSize="17px"
              fontWeight="bold"
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              width="60%"
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              {isMasterLoading ? (
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    height: "17px",
                    width: "180px",
                    marginBottom: "6px",
                    marginTop: "2px",
                  }}
                />
              ) : (
                master && master.user.display_name
              )}
              {isWindowsLoading || isMasterLoading ? (
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    height: "13px",
                    width: "80px",
                    marginBottom: "6px",
                    marginTop: "2px",
                  }}
                />
              ) : (
                <Typography fontSize="14px" fontWeight="400" textColor="#939397">
                  {working_time}
                </Typography>
              )}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "12px",
              padding: "10px",
              borderRadius: "20px",
              backgroundColor: "#fff",
              marginTop: "20px",
              alignItems: "center",
              boxShadow: "0 1px 5px -1px #ddd",
              cursor: "pointer",
            }}
            onClick={navigateToMaster}
          >
            {isMasterLoading ? (
              <>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "10px",
                  }}
                />
                <Box>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    sx={{
                      height: "17px",
                      width: "220px",
                      marginBottom: "10px",
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    sx={{ height: "18px", width: "120px" }}
                  />
                </Box>
              </>
            ) : (
              master && (
                <>
                  <Picture
                    src={master.user.photo_url ? master.user.photo_url : noImage}
                    sx={{
                      minWidth: "60px",
                      minHeight: "60px",
                      borderRadius: "10px",
                    }}
                  />
                  <Box>
                    <Typography fontSize="17px" fontWeight="bold">
                      {master.user.display_name}
                    </Typography>
                    {master.reviews_count && master.reviews_score && (
                      <ReviewsCount
                        count={master.reviews_count}
                        score={master.reviews_score}
                        full
                      />
                    )}
                  </Box>
                </>
              )
            )}
          </Box>

          {products.map((product) => (
            <Chip
              key={product.id}
              size="lg"
              sx={{
                padding: "10px",
                borderRadius: "20px",
                backgroundColor: "#fff",
                marginTop: "8px",
                minWidth: "100%",
                maxWidth: "100%",
                boxShadow: "0 1px 5px -1px #ddd",
              }}
              endDecorator={
                <ChipDelete
                  onDelete={() => removeClickHandler(product)}
                  sx={{
                    marginRight: "5px",
                    background: "transparent",
                    transition: ".2s",
                    " svg": {
                      color: "#bfc0c2",
                      width: "24px",
                      height: "24px",
                    },
                    "&:hover": {
                      background: "#c94040",
                      " svg": {
                        color: "#fff",
                      },
                    },
                  }}
                />
              }
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <Picture
                  src={product.photo_url ? product.photo_url : noImage}
                  sx={{
                    minWidth: "60px",
                    minHeight: "60px",
                    borderRadius: "10px",
                  }}
                />
                <Box maxWidth="calc(100% - 80px)" display="flex" flexDirection="column">
                  <Typography
                    fontSize="17px"
                    fontWeight="bold"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {product.title}
                  </Typography>
                  <Typography fontSize="17px">
                    {formatPrice(product.amount, product.currency)}{" "}
                    <Typography fontSize="17px" textColor="#95969a">
                      &#183; {getHoursDuration(product.duration)}{" "}
                      {getMinutesDuration(product.duration)}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Chip>
          ))}

          <Box
            display="flex"
            gap="5px"
            marginTop="8px"
            sx={{
              cursor: "pointer",
              "&:hover button": { background: "rgba(0,0,0,0.03)" },
              "&:active button": { background: "rgba(0,0,0,0.07)" },
            }}
            onClick={chooseAnotherProductHandler}
          >
            <CustomButton>{plusIcon}</CustomButton>
            <CustomButton
              sx={{
                fontSize: "14px",
                flexGrow: 1,
                justifyContent: "flex-start",
                color: "#95969a",
              }}
            >
              {products.length === 0 ? "Выбрать услугу" : "Выбрать еще услугу"}
            </CustomButton>
          </Box>

          <CustomButton
            onClick={() => dispatch(changeStep("date"))}
            startDecorator={<SvgIcon component={Calendar} />}
            endDecorator={
              <Box sx={{ transform: "rotate(-90deg)" }}>
                <ArrowIcon />
              </Box>
            }
            sx={{
              border: "none",
              marginTop: "8px",
              width: "100%",
              fontSize: "12px",
              justifyContent: "space-between",
              textAlign: "center",
              boxShadow: "0 1px 5px -1px #ddd",
              [` .${buttonClasses.startDecorator}`]: {
                marginRight: "13px",
              },
              [` .${buttonClasses.endDecorator}`]: {
                marginLeft: "auto",
              },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography fontSize="13px">Выбрать дату и время</Typography>
              <Box
                display="flex"
                alignItems="center"
                gap="10px"
                color="#929497"
                fontWeight="normal"
              >
                {time.length > 0 &&
                  `${formatDate(time, {
                    month: "long",
                    weekday: "short",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}`}
              </Box>
            </Box>
          </CustomButton>

          {products.length !== 0 && (
            <Box marginTop="30px" display="flex" flexDirection="column" gap="10px">
              <Typography fontSize="14px" fontWeight="bold" textColor="#9E9797">
                Цена
              </Typography>
              <Box>
                {products.map((product) => (
                  <Chip
                    key={`${product.id}-order`}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      background: "transparent",
                      minWidth: "100%",
                      maxWidth: "100%",
                      padding: "0",
                    }}
                    endDecorator={
                      <Typography fontSize="14px" fontWeight="bold" textColor="#962BED">
                        {formatPrice(product.amount, product.currency)}
                      </Typography>
                    }
                  >
                    <Typography
                      fontSize="14px"
                      maxWidth="95%"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {product.title}
                    </Typography>
                  </Chip>
                ))}
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize="17px" fontWeight="bold">
                  Итого
                </Typography>
                <Typography fontSize="17px" fontWeight="bold" textColor="#962BED">
                  {formatPrice(totalPrice, products[0].currency)}
                </Typography>
              </Box>
            </Box>
          )}

          {authStatus === "authorized" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "15px",
                margin: "-5px",
                borderRadius: "20px",
                backgroundColor: "#fff",
                marginTop: "20px",
                boxShadow: "0 1px 5px -1px #ddd",
              }}
            >
              <Typography fontWeight="bold" fontSize="17px" marginBottom="8px">
                Ваши данные
              </Typography>
              <Controller
                name="displayName"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, name }, fieldState: { invalid } }) => (
                  <FormControl>
                    {whoAmILoading ? (
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        sx={{ height: "48px", borderRadius: "15px" }}
                      />
                    ) : (
                      <Input
                        name={name}
                        label="Имя"
                        sx={{ height: "48px" }}
                        marginY={0}
                        placeholder="Ваше имя"
                        value={value}
                        onChange={onChange}
                        invalid={invalid}
                      />
                    )}
                  </FormControl>
                )}
              />

              <Controller
                name="phoneNumber"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange }, fieldState: { invalid } }) => (
                  <FormControl sx={{ marginTop: "10px" }}>
                    <FormLabel>Телефон</FormLabel>
                    {whoAmILoading ? (
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        sx={{ height: "48px", borderRadius: "15px" }}
                      />
                    ) : (
                      <PhoneInput value={value} onChange={onChange} isValid={!invalid} />
                    )}
                  </FormControl>
                )}
              />

              <Controller
                name="email"
                control={control}
                render={({ field: { value, name } }) => (
                  <FormControl sx={{ marginTop: "10px" }}>
                    {whoAmILoading ? (
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        sx={{ height: "48px", borderRadius: "15px" }}
                      />
                    ) : (
                      <Input
                        name={name}
                        label="Почта"
                        sx={{ height: "48px" }}
                        marginY={0}
                        placeholder="Ваша почта"
                        type="email"
                        disabled={control._formValues.email}
                        value={value}
                      />
                    )}
                  </FormControl>
                )}
              />
            </Box>
          )}

          <FixedBottomBox>
            <Button type="submit" fullWidth sx={{ alignSelf: "flex-end" }} loading={isLoading}>
              Записаться
            </Button>
          </FixedBottomBox>
        </form>
      </Box>
    </>
  );
};
