import { FC, ReactNode } from "react";
import { Radio as MUIRadio, styled } from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";

const CustomRadio = styled(MUIRadio)({
  backgroundColor: "#f2f2f2",
  maxHeight: "30px",
  fontWeight: "bold",
  alignItems: "center",
});

type RadioProps = {
  value: string | number;
  label: ReactNode;
  padding?: string;
  name?: string;
  sx?: SxProps;
  borderRadius?: string;
  fontSize?: string;
  disabled?: boolean;
};

export const Radio: FC<RadioProps> = ({
  label,
  value,
  padding = "7px 10px",
  name,
  sx,
  borderRadius = "20px",
  fontSize = "14px",
  disabled = false,
}) => (
  <CustomRadio
    label={label}
    value={value}
    name={name}
    disableIcon
    variant="plain"
    sx={{ padding, borderRadius, fontSize, opacity: disabled ? 0.3 : 1, ...sx }}
    slotProps={{
      label: ({ checked }) => ({
        sx: {
          color: checked ? "#fff" : "#000",
          " div": {
            color: checked ? "#fff" : "#000",
            fontSize,
          },
          " img": {
            color: "#000",
          },
        },
      }),
      action: ({ checked }) => ({
        sx: {
          borderRadius,
          transition: ".2s",
          "&:hover": {
            bgcolor: !disabled && "#ddd",
          },
          ...(checked && {
            bgcolor: "#962BED",
            "&:hover": {
              bgcolor: "#962BED",
            },
          }),
        },
      }),
    }}
  />
);
