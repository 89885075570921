import { FC, useState, ChangeEvent } from "react";
import { Typography, Box, RadioGroup, CircularProgress } from "@mui/joy";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { getWindows, getIsWindowsLoading } from "../../redux/master/selectors";
import { getOrderTime, getOrderProducts } from "../../redux/order/selectors";
import { FixedBottomBox } from "../../ui/modal/fixed-bottom-box";
import { BackButton } from "../back-button/back-button";
import { Radio } from "../../ui/radio/radio";
import { Button } from "../../ui/button/button";
import { formatDate } from "../../utils/format-date";
import { ChooseTime } from "../choose-time/choose-time";
import orderActions from "../../redux/order/order-slice";

const { changeStep, changeOrderModalOpen } = orderActions;

type ChooseDateModalProps = {
  master_name: string;
};

export const ChooseDateModal: FC<ChooseDateModalProps> = ({ master_name }) => {
  const dispatch = useAppDispatch();

  const windows = useAppSelector(getWindows);
  const orderTime = useAppSelector(getOrderTime);
  const orderProducts = useAppSelector(getOrderProducts);
  const isWindowsLoading = useAppSelector(getIsWindowsLoading);

  const [datePosition, setDatePosition] = useState<number>(1);

  const morning_windows = windows[datePosition - 1]?.windows?.filter(
    (e) => e.getTime() > Date.now() && e.getHours() < 12
  );
  const day_windows = windows[datePosition - 1]?.windows?.filter(
    (e) => e.getTime() > Date.now() && e.getHours() >= 12 && e.getHours() <= 18
  );
  const evening_windows = windows[datePosition - 1]?.windows?.filter(
    (e) => e.getTime() > Date.now() && e.getHours() > 18
  );

  const dayChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (windows[value - 1].windows) {
      setDatePosition(value);
    }
  };

  const backClickHandler = () => {
    if (orderTime !== "" || orderProducts.length !== 0) {
      dispatch(changeStep("sum"));
    } else {
      dispatch(changeOrderModalOpen(false));
    }
  };

  const nextClickHandler = () => dispatch(changeStep("sum"));

  return (
    <>
      <Box display={{ xs: "none", md: "block" }}>
        <Box minHeight="80vh" display="flex" flexDirection="column" justifyContent="space-between">
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <Box display="flex" alignItems="center">
              <BackButton onClick={backClickHandler} />
              <Typography
                fontSize="24px"
                fontWeight="bold"
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                Выбор даты и времени
                <Typography fontSize="17px" fontWeight="bold" textColor="#939397">
                  {master_name}
                </Typography>
              </Typography>
            </Box>
            {isWindowsLoading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexGrow: 1,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <RadioGroup
                  value={datePosition}
                  onChange={dayChangeHandler}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                    marginTop: "20px",
                    overflow: "scroll",
                    overFlowY: "hidden",
                    padding: "4px",
                    "::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  {windows.map((window, index) => {
                    const [day, date] = formatDate(window.day.toISOString(), {
                      day: "numeric",
                      weekday: "short",
                    }).split(",");

                    return (
                      <Box key={`${day}-${date}`}>
                        <Radio
                          padding="43px 0"
                          sx={{
                            width: "86px",
                            background: "#fff",
                            boxShadow: window.windows ? "0 1px 8px -4px #ddd" : "none",
                          }}
                          disabled={window.windows ? false : true}
                          label={
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                              gap="8px"
                            >
                              <Typography fontSize="17px">{day}</Typography>
                              <Typography fontSize="29px" fontWeight="bold" fontFamily="Rubik">
                                {date}
                              </Typography>
                            </Box>
                          }
                          value={index + 1}
                        />
                      </Box>
                    );
                  })}
                </RadioGroup>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  marginTop="40px"
                  marginBottom="20px"
                  gap="40px"
                >
                  {morning_windows && morning_windows.length > 0 && (
                    <ChooseTime header="Утро" values={morning_windows} />
                  )}

                  {day_windows && day_windows.length > 0 && (
                    <ChooseTime header="День" values={day_windows} />
                  )}

                  {evening_windows && evening_windows.length > 0 && (
                    <ChooseTime header="Вечер" values={evening_windows} />
                  )}
                </Box>
              </>
            )}
          </Box>

          <Button sx={{ alignSelf: "flex-end" }} onClick={nextClickHandler}>
            Далее
          </Button>
        </Box>
      </Box>

      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <form onSubmit={nextClickHandler}>
          <Box display="flex" alignItems="center">
            <BackButton onClick={backClickHandler} />
            <Typography
              fontSize="17px"
              fontWeight="bold"
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="60%"
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              Выбор даты и времени
              <Typography fontSize="14px" fontWeight="bold" textColor="#939397">
                {master_name}
              </Typography>
            </Typography>
          </Box>
          {isWindowsLoading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <RadioGroup
                value={datePosition}
                onChange={dayChangeHandler}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "15px",
                  marginTop: "20px",
                  overflow: "scroll",
                  overFlowY: "hidden",
                  padding: "3px",
                  "::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {windows.map((window, index) => {
                  const [day, date] = formatDate(window.day.toISOString(), {
                    day: "numeric",
                    weekday: "short",
                  }).split(",");

                  return (
                    <Box key={`${day}-${date}`}>
                      <Radio
                        padding="22px 0"
                        borderRadius="10px"
                        sx={{
                          width: "44px",
                          background: "#fff",
                          boxShadow: window.windows ? "0 1px 5px -2px #ddd" : "none",
                        }}
                        disabled={window.windows ? false : true}
                        label={
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography fontSize="12px" lineHeight="15px">
                              {day}
                            </Typography>
                            <Typography fontSize="17px" fontWeight="bold" fontFamily="Rubik">
                              {date}
                            </Typography>
                          </Box>
                        }
                        value={index + 1}
                      />
                    </Box>
                  );
                })}
              </RadioGroup>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginY="30px"
                gap="40px"
              >
                {morning_windows && morning_windows.length > 0 && (
                  <ChooseTime header="Утро" values={morning_windows} />
                )}

                {day_windows && day_windows.length > 0 && (
                  <ChooseTime header="День" values={day_windows} />
                )}

                {evening_windows && evening_windows.length > 0 && (
                  <ChooseTime header="Вечер" values={evening_windows} />
                )}
              </Box>
            </>
          )}
          <FixedBottomBox>
            <Button fullWidth type="submit">
              Далее
            </Button>
          </FixedBottomBox>
        </form>
      </Box>
    </>
  );
};
