import { FC } from "react";
import { Box, Typography, SvgIcon } from "@mui/joy";
import { ArrowIcon } from "../../ui/icons/arrow-icon";
import { ActionButton } from ".";
import { useAppSelector } from "../../hooks";
import { getMaster } from "../../redux/record/selectors";

import { ReactComponent as GoogleMaps } from "../../assets/google-maps.svg";

export const ToGoogleMaps: FC = () => {
  const master = useAppSelector(getMaster);
  const { location } = master!;

  const lon = location.lon;
  const lat = location.lat;
  const url = `https://www.google.com/maps?q=${lat},${lon}&hl=${location.country_iso_code}&z=14&t=m&markers=color:red%7Clabel:pin%7C${lat},${lon}`;

  const clickHandler = () => window.open(url, "_blank", "noreferrer");

  return (
    <ActionButton fullWidth onClick={clickHandler}>
      <Typography startDecorator={<SvgIcon component={GoogleMaps} viewBox="-55.5 0 367 367" />}>
        Как добраться?
      </Typography>
      <Box sx={{ transform: "rotate(-90deg)" }}>
        <ArrowIcon />
      </Box>
    </ActionButton>
  );
};
