import { FC, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/joy";
import { SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getProducts, getProductsLoading } from "../../redux/data/selectors";
import { getFilterCategory } from "../../redux/filter/selectors";
import { Product } from "./product";
import { ProductSkeleton } from "./skeleton";
import { Swiper, Prev, Next } from "../../ui/swiper/swiper";
import { ArrowIcon } from "../../ui/icons/arrow-icon";
import { ProductType } from "../../types/types";
import dataActions from "../../redux/data/data-slice";
import filterActions from "../../redux/filter/filter-slice";

import "swiper/css";
import "swiper/css/navigation";

const CutyApi = require("cuty_api");

const { setProducts, changeProductsLoading, changeProductsError } = dataActions;
const { changeTabValue } = filterActions;

export const Products: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [nextPage, setNextPage] = useState<number>(2);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isMoreLoading, setIsMoreLoading] = useState<boolean>(false);

  const products = useAppSelector(getProducts);
  const isLoading = useAppSelector(getProductsLoading);
  const activeCategory = useAppSelector(getFilterCategory);

  const limit = 15;

  useEffect(() => {
    dispatch(changeProductsLoading(true));
    setHasMore(false);
    const apiInstance = new CutyApi.ProductApi();
    apiInstance.listProducts(
      { limit, page: 1, categoryId: activeCategory },
      (error: string, data: ProductType[]) => {
        if (error) {
          dispatch(changeProductsLoading(false));
          dispatch(changeProductsError(error));
        } else {
          dispatch(changeProductsLoading(false));
          dispatch(setProducts(data));
          setNextPage(2);
          if (data.length < limit) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        }
      }
    );
  }, [dispatch, activeCategory]);

  const showAllClickHandler = () => {
    navigate("/search");
    dispatch(changeTabValue("products"));
  };

  const loadMore = () => {
    if (products.length !== 0 && !isLoading && hasMore) {
      const productInstance = new CutyApi.ProductApi();
      setIsMoreLoading(true);
      productInstance.listProducts(
        {
          limit,
          page: nextPage,
          categoryId: activeCategory,
        },
        (error: string, data: ProductType[]) => {
          if (error) {
            setIsMoreLoading(false);
            dispatch(changeProductsError(error));
          } else {
            setIsMoreLoading(false);
            dispatch(setProducts([...products, ...data]));
            setNextPage((prev) => prev + 1);
            if (data.length < limit) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          }
        }
      );
    }
  };

  return (
    <Box sx={{ marginY: "15px" }}>
      <Box display="flex" gap={2} marginBottom="15px">
        <Typography fontSize={{ xs: "16px", sm: "20px", md: "24px" }} fontWeight="bold">
          Популярные услуги
        </Typography>
        <Typography
          fontSize={{ xs: "16px", sm: "20px", md: "24px" }}
          fontWeight="bold"
          textColor="#B6B6B9"
          onClick={showAllClickHandler}
          sx={{
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          Посмотреть все
        </Typography>
      </Box>

      <Swiper
        spaceBetween={10}
        modules={[Navigation]}
        navigation={{ nextEl: navigationNextRef.current, prevEl: navigationPrevRef.current }}
        lazyPreloadPrevNext={1}
        onReachEnd={loadMore}
        breakpoints={{
          0: { slidesPerView: 1 },
          600: { slidesPerView: 2 },
          900: { slidesPerView: 3 },
          1200: { slidesPerView: 4 },
        }}
      >
        <Prev ref={navigationPrevRef}>
          <Box sx={{ transform: "rotate(90deg)", display: "flex" }}>
            <ArrowIcon width={30} color="#96979b" height={30} />
          </Box>
        </Prev>

        <Next
          ref={navigationNextRef}
          sx={{
            "&.swiper-button-disabled": {
              opacity: isMoreLoading ? 1 : 0,
            },
          }}
        >
          {isMoreLoading ? (
            <CircularProgress size="sm" color="neutral" variant="plain" />
          ) : (
            <Box sx={{ transform: "rotate(-90deg)", display: "flex" }}>
              <ArrowIcon width={30} color="#96979b" height={30} />
            </Box>
          )}
        </Next>

        {isLoading ? (
          Array.from({ length: 4 }).map((_, idx) => (
            <SwiperSlide key={`${idx}-product`}>
              <ProductSkeleton />
            </SwiperSlide>
          ))
        ) : products.length !== 0 ? (
          <>
            {products.map((product) => (
              <SwiperSlide key={product.id}>
                <Product product={product} />
              </SwiperSlide>
            ))}
          </>
        ) : (
          <Box
            sx={{
              minHeight: "360px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography fontWeight="bold" fontSize={{ xs: "24px", sm: "28px", md: "34px" }}>
              Ничего не найдено :(
            </Typography>
          </Box>
        )}
      </Swiper>
    </Box>
  );
};
