export const getReviewsRightDeclension = (number: number): string => {
  const remainder = number % 10;
  if (number >= 11 && number <= 19) {
    return " отзывов";
  } else if (remainder === 1) {
    return " отзыв";
  } else if (remainder >= 2 && remainder <= 4) {
    return " отзыва";
  } else {
    return " отзывов";
  }
};

export const getHoursRightDeclension = (number: number): string => {
  const remainder = number % 10;
  if (number >= 11 && number <= 19) {
    return "часов";
  } else if (remainder === 1) {
    return "час";
  } else if (remainder >= 2 && remainder <= 4) {
    return "часа";
  } else {
    return "часов";
  }
};

export const getMinutesRightDeclension = (number: number): string => {
  const remainder = number % 10;
  if (number >= 11 && number <= 19) {
    return "минут";
  } else if (remainder === 1) {
    return "минута";
  } else if (remainder >= 2 && remainder <= 4) {
    return "минуты";
  } else {
    return "минут";
  }
};
