import { FC } from "react";
import { Box, Typography, SvgIcon } from "@mui/joy";
import { ArrowIcon } from "../../ui/icons/arrow-icon";
import { ActionButton } from ".";
import { useAppSelector } from "../../hooks";
import { getMaster, getRecord } from "../../redux/record/selectors";

import { ReactComponent as GoogleCalendar } from "../../assets/google-calendar.svg";

export const AddToCalendar: FC = () => {
  const master = useAppSelector(getMaster);
  const record = useAppSelector(getRecord);

  const { products, owner, start_day, start_time, end_time, id } = record!;
  const { location } = master!;

  let title = "Запись";
  if (products.length !== 0) {
    title += ` ${products[0].title}`;
  }
  if (owner.display_name != null) {
    title += ` к ${owner.display_name}`;
  }

  const startDate = new Date(
    start_day.getFullYear(),
    start_day.getMonth(),
    start_day.getDate(),
    start_time.getHours(),
    start_time.getMinutes()
  )
    .toISOString()
    .replace(/[^\w\s]/gi, "");

  const endDate = new Date(
    start_day.getFullYear(),
    start_day.getMonth(),
    start_day.getDate(),
    end_time.getHours(),
    end_time.getMinutes()
  )
    .toISOString()
    .replace(/[^\w\s]/gi, "");

  const url = `http://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startDate}/${endDate}&details=Ссылка на запись: https://app.cuty.pro/records/${id}${
    location && `&location=${location.lat},${location.lon}`
  }&sf=false&output=xml
  `;

  const clickHandler = () => window.open(url, "_blank", "noreferrer");

  return (
    <ActionButton onClick={clickHandler} fullWidth>
      <Typography startDecorator={<SvgIcon component={GoogleCalendar} viewBox="0 0 32 32" />}>
        Добавить в календарь
      </Typography>
      <Box sx={{ transform: "rotate(-90deg)" }}>
        <ArrowIcon />
      </Box>
    </ActionButton>
  );
};
