import { FC, ChangeEvent } from "react";
import { Switch as MUISwitch } from "@mui/joy";

type SwitchProps = {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
};

export const Switch: FC<SwitchProps> = ({ checked, onChange, id }) => (
  <MUISwitch
    id={id}
    checked={checked}
    onChange={onChange}
    sx={{
      "--Switch-trackWidth": "36px",
    }}
    slotProps={{
      track: ({ checked }) => ({
        sx: {
          backgroundColor: checked ? "#962BED" : "#bbb",
          transition: ".2s",
        },
      }),
      thumb: {
        sx: { boxShadow: "none", transition: ".2s" },
      },
    }}
  />
);
