import { combineReducers } from "@reduxjs/toolkit";
import { dataSlice } from "./data/data-slice";
import { filterSlice } from "./filter/filter-slice";
import { authSlice } from "./auth/auth-slice";
import { masterSlice } from "./master/master-slice";
import { orderSlice } from "./order/order-slice";
import { recordsSlice } from "./records/records-slice";
import { recordSlice } from "./record/record-slice";

export const rootReducer = combineReducers({
  [dataSlice.name]: dataSlice.reducer,
  [filterSlice.name]: filterSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [masterSlice.name]: masterSlice.reducer,
  [orderSlice.name]: orderSlice.reducer,
  [recordsSlice.name]: recordsSlice.reducer,
  [recordSlice.name]: recordSlice.reducer,
});
