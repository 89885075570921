import { FC } from "react";

type SearchIconProps = {
  color?: string;
  width?: number;
};

export const SearchIcon: FC<SearchIconProps> = ({
  color = "#909195",
  width = 24,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height="26"
    viewBox="0 0 24 26"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.125 16.75C13.2316 16.75 15.75 14.2316 15.75 11.125C15.75 8.0184 13.2316 5.5 10.125 5.5C7.0184 5.5 4.5 8.0184 4.5 11.125C4.5 14.2316 7.0184 16.75 10.125 16.75ZM16.0448 15.7306C17.0351 14.4595 17.625 12.8611 17.625 11.125C17.625 6.98286 14.2671 3.625 10.125 3.625C5.98286 3.625 2.625 6.98286 2.625 11.125C2.625 15.2671 5.98287 18.625 10.125 18.625C11.8611 18.625 13.4594 18.0351 14.7305 17.0449C14.7554 17.0774 14.7827 17.1086 14.8125 17.1384L20.0492 22.3751C20.4153 22.7412 21.0089 22.7412 21.375 22.3751C21.7411 22.009 21.7411 21.4154 21.375 21.0492L16.1383 15.8126C16.1086 15.7828 16.0773 15.7555 16.0448 15.7306Z"
      fill={color}
    />
  </svg>
);
