import { FC } from "react";
import PhoneInput2, { PhoneInputProps } from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";
import ru from "react-phone-input-2/lang/ru.json";

import "./phone-input.css";
import { Typography } from "@mui/joy";

type InputProps = {
  fontSize?: number;
  message?: string;
};

export const PhoneInput: FC<PhoneInputProps & InputProps> = ({
  fontSize = 14,
  message = "Введите номер",
  ...props
}) => {
  return (
    <div>
      <PhoneInput2
        {...props}
        localization={ru}
        disableDropdown
        buttonStyle={{ display: "none" }}
        placeholder="Ваш телефон"
        inputStyle={{
          width: "100%",
          borderRadius: "15px",
          background: "#f5f5f5",
          border: "none",
          height: "48px",
          fontSize: `${fontSize}px`,
          color: "#32383E",
          paddingLeft: "16px",
        }}
      />
      {!props.isValid && (
        <Typography fontSize="0.875rem" textColor="#c41c1c" marginTop="5px">
          {message}
        </Typography>
      )}
    </div>
  );
};
