import { FC } from "react";
import { Typography, Box, styled, Button } from "@mui/joy";
import { ArrowIcon } from "../../ui/icons/arrow-icon";

const BackChip = styled(Button)({
  display: "flex",
  color: "#16161A",
  padding: "7px 10px",
  borderRadius: "15px",
  cursor: "pointer",
  background: "#f1f1f1",
  transition: ".2s",
  "&:hover": {
    background: "#e1e1e1",
  },
  "&:active": {
    background: "#d1d1d1",
  },
});

type BackButtonProps = {
  onClick: () => void;
  marginY?: string;
};

export const BackButton: FC<BackButtonProps> = ({ onClick, marginY = "0px" }) => {
  return (
    <BackChip
      sx={{ marginY, paddingLeft: { xs: "7px", md: 0 }, paddingRight: { xs: "7px", md: "10px" } }}
      onClick={onClick}
      startDecorator={
        <Box sx={{ rotate: "90deg" }}>
          <ArrowIcon color="#16161A" />
        </Box>
      }
    >
      <Typography
        fontWeight="bold"
        level="body-md"
        fontSize="14px"
        sx={{ display: { xs: "none", md: "block" } }}
      >
        Назад
      </Typography>
    </BackChip>
  );
};
