import { FC, ReactNode } from "react";
import { TabList as MUITabList, tabClasses } from "@mui/joy";

type TabListProps = {
  children: ReactNode[] | ReactNode;
};

export const TabList: FC<TabListProps> = ({ children }) => (
  <MUITabList
    disableUnderline
    sx={{
      [`&& .${tabClasses.root}`]: {
        color: "#9e9e9f",
        padding: "4px",
        fontWeight: "bold",
        background: "transparent",
        fontSize: "18px",
        transition: ".15s",
        ":hover": {
          color: "#7e7e7f",
          background: "transparent",
        },
        [`&.${tabClasses.selected}`]: {
          color: "#000",
          "::after": {
            display: "none",
          },
        },
      },
    }}
  >
    {children}
  </MUITabList>
);
