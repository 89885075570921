import { FC } from "react";
import {
  Input as MUIInput,
  InputProps as MUIInputProps,
  FormControl,
  FormHelperText,
  FormLabel,
  inputClasses,
} from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";

type InputProps = MUIInputProps & {
  marginY?: number;
  fontSize?: number;
  background?: string;
  padding?: string;
  sx?: SxProps;
  invalid?: boolean;
  message?: string;
  label?: string;
};

export const Input: FC<InputProps> = ({
  marginY = 10,
  fontSize = 14,
  background = "#F5F5F5",
  padding = "12px 16px",
  sx,
  invalid = false,
  message = "Неправильно заполнено поле",
  label,
  ...props
}) => (
  <FormControl sx={{ marginY: `${marginY}px` }}>
    {label && <FormLabel>{label}</FormLabel>}
    <MUIInput
      variant="plain"
      {...props}
      sx={{
        padding,
        color: "#69696a",
        borderRadius: "15px",
        background,
        fontSize: `${fontSize}px`,
        transition: "box-shadow 0.2s",
        boxShadow: invalid ? "0 0 0 2px #c41c1c" : "none",
        "&::before": {
          display: "none",
        },
        "&:hover": {
          color: "#69696a",
          boxShadow: invalid ? "0 0 0 2px #c41c1c" : "0 0 0 1px #962BED",
        },
        [`&.${inputClasses.focused}`]: {
          boxShadow: invalid ? "0 0 0 2px #c41c1c" : "0 0 0 2px #962BED, 0 0 6px #8627d3",
        },
        ...sx,
      }}
    />
    {invalid && <FormHelperText sx={{ color: "#c41c1c" }}>{message}</FormHelperText>}
  </FormControl>
);
