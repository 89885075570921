import { FC, useState } from "react";
import { AspectRatio, Skeleton } from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";

type PictureProps = {
  src: string;
  ratio?: string;
  sx?: SxProps;
};

export const Picture: FC<PictureProps> = ({ src, ratio = "1", sx = {} }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  return (
    <AspectRatio ratio={ratio} sx={sx}>
      <Skeleton variant="rectangular" animation="wave" loading={isLoading}>
        <img
          src={src}
          alt="pic"
          loading="lazy"
          onLoad={() => setIsLoading(false)}
        />
      </Skeleton>
    </AspectRatio>
  );
};
