import { FC, ChangeEvent, Dispatch, SetStateAction } from "react";
import { Typography, RadioGroup } from "@mui/joy";
import { Radio } from "../../ui/radio/radio";
import { FilterType } from "../../types/types";

type FilterDistanceProps = {
  filter: FilterType;
  setFilter: Dispatch<SetStateAction<FilterType>>;
};

export const FilterDistance: FC<FilterDistanceProps> = ({
  filter,
  setFilter,
}) => {
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setFilter({ ...filter, distance: Number(e.target.value) });

  return (
    <>
      <Typography level="h4" sx={{ marginBottom: "10px" }}>
        Расстояние
      </Typography>
      <RadioGroup
        sx={{ gap: 1, flexDirection: "row", flexWrap: "wrap" }}
        value={filter.distance}
        onChange={changeHandler}
        name="distance"
      >
        {[3, 6, 10].map((e) => (
          <Radio key={e} label={`до ${e} км`} value={e} />
        ))}
      </RadioGroup>
    </>
  );
};
