import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MasterType, ScheduleRecordType } from "../../types/types";

type RecordSliceState = {
  record: ScheduleRecordType | null;
  master: MasterType | null;
  isRecordLoading: boolean;
  isMasterLoading: boolean;
};

const initialState: RecordSliceState = {
  record: null,
  master: null,
  isRecordLoading: false,
  isMasterLoading: false,
};

export const recordSlice = createSlice({
  name: "RECORD",
  initialState,
  reducers: {
    changeRecord: (state, action: PayloadAction<ScheduleRecordType>) => {
      state.record = action.payload;
    },
    changeRecordLoading: (state, action: PayloadAction<boolean>) => {
      state.isRecordLoading = action.payload;
    },
    changeMaster: (state, action: PayloadAction<MasterType>) => {
      state.master = action.payload;
    },
    changeMasterLoading: (state, action: PayloadAction<boolean>) => {
      state.isMasterLoading = action.payload;
    },
  },
});

export default recordSlice.actions;
