import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Card, CardContent, CardOverflow } from "@mui/joy";
import { MasterType } from "../../types/types";
import { ReviewsCount } from "../reviews-count/reviews-count";
import { Picture } from "../../ui/picture/picture";

import noImage from "../../assets/no-image.svg";

type MasterProps = {
  master: MasterType;
};

export const Master: FC<MasterProps> = ({ master }) => {
  const navigate = useNavigate();
  const { location } = master;

  const streetHouse = () => {
    const { street, house } = location;
    if (street != null && house != null) {
      return `${street} ${house}`;
    }
    return null;
  };

  const city = () => {
    const { city } = location;
    if (city != null) {
      return city;
    }
    return null;
  };

  return (
    <Card
      variant="plain"
      sx={{
        padding: 0,
        background: "none",
        cursor: "pointer",
        "&:hover": {
          "& .display_name": { textDecoration: "underline" },
        },
      }}
      onClick={() => navigate(`/master/${master.nickname}`)}
    >
      <CardOverflow>
        <Picture
          sx={{ borderRadius: "10px" }}
          src={master.user.photo_url ? master.user.photo_url : noImage}
        />
      </CardOverflow>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <div className="display_name">
            <Typography fontWeight="bold">{master.user.display_name}</Typography>
          </div>
          {master.reviews_count && master.reviews_score && (
            <ReviewsCount count={master.reviews_count} score={master.reviews_score} />
          )}
        </Box>
        {location && (
          <Typography level="body-sm" textColor="#95969a">
            {`${city()}, ${streetHouse()}`}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
