import { Routes, Route } from "react-router-dom";
import { MainPage } from "./pages/main-page";
import { SearchPage } from "./pages/search-page";
import { MasterPage } from "./pages/master-page";
import { RecordsPage } from "./pages/records-page";
import { RecordPage } from "./pages/record-page";
import { Header } from "./components/header/header";
import { ScrollToTop } from "./components/scroll-to-top/scroll-to-top";
import { OrderModals } from "./components/order-modals/order-modals";
import { PrivateRoute } from "./components/private-route/private-route";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Header />
      <OrderModals />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/master/:param" element={<MasterPage />} />
        <Route path="/records">
          <Route
            index
            element={
              <PrivateRoute requiredAuthStatus="authorized">
                <RecordsPage />
              </PrivateRoute>
            }
          />
          <Route path=":id" element={<RecordPage />} />
        </Route>
        <Route path="*" element={<h1>404</h1>} />
      </Routes>
    </div>
  );
}

export default App;
