import { useState, useEffect, FC } from "react";
import { Box } from "@mui/joy";
import { ArrowIcon } from "../../ui/icons/arrow-icon";

export const ToTheTop: FC = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > 1200) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTheTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => toggleVisible();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      onClick={scrollToTheTop}
      sx={{
        position: "fixed",
        bottom: { xs: "12px", md: "20px" },
        left: { xs: "12px", md: "20px" },
        padding: "5px",
        borderRadius: "7px",
        boxShadow: "0 0 5px #0002",
        backgroundColor: "#fbfcfe",
        zIndex: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        transition: "0.2s",
        transform: visible ? "rotate(180deg) scale(1)" : "rotate(180deg) scale(0.7)",
        opacity: visible ? 1 : 0,
        visibility: visible ? "visible" : "hidden",
        ":hover": {
          boxShadow: "0 0 5px #0003",
          transform: "rotate(180deg) scale(1.05)",
        },
        ":active": {
          transform: "rotate(180deg) scale(0.95)",
        },
      }}
    >
      <ArrowIcon width={28} height={28} color="#9e9e9f" />
    </Box>
  );
};
