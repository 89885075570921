import { FC, useState } from "react";
import { Typography, Box, SvgIcon } from "@mui/joy";
import { signInWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Button } from "../../ui/button/button";
import { ArrowIcon } from "../../ui/icons/arrow-icon";
import { Input } from "../../ui/input/input";
import { FixedBottomBox } from "../../ui/modal/fixed-bottom-box";
import { BackButton } from "../back-button/back-button";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getEmail } from "../../redux/auth/selectors";
import { auth } from "../../services/firebase";
import { toastOptions } from "../../const";
import authActions from "../../redux/auth/auth-slice";

import { ReactComponent as Eye } from "../../assets/eye.svg";

const { changeStep, changeAuthModalOpen } = authActions;

type FormInputs = {
  password: string;
};

export const SignInPasswordModal: FC = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const email = useAppSelector(getEmail);

  const { handleSubmit, control } = useForm<FormInputs>({
    defaultValues: {
      password: "",
    },
  });

  const submitHandler: SubmitHandler<FormInputs> = ({ password }) => {
    setIsLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setIsLoading(false);
        dispatch(changeStep("hidden"));
        dispatch(changeAuthModalOpen(false));
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        toast.error("Неверные данные :(", toastOptions);
      });
  };

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Box
            sx={{
              minHeight: "350px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ marginTop: "-20px" }}>
              <BackButton onClick={() => dispatch(changeStep("signIn_mail"))} />

              <Typography level="h2" marginBottom="7px" marginTop="20px">
                Вход
              </Typography>
              <Typography level="body-md" textColor="#C0C0C0" fontWeight="500" marginBottom="24px">
                {email}
              </Typography>

              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange }, fieldState: { invalid } }) => (
                  <Input
                    placeholder="Ваш пароль"
                    type={isHidden ? "text" : "password"}
                    value={value}
                    onChange={onChange}
                    endDecorator={
                      <SvgIcon component={Eye} onClick={() => setIsHidden(!isHidden)} />
                    }
                    invalid={invalid}
                    message="Пароль не может быть пустым"
                  />
                )}
              />
            </Box>

            <Button
              type="submit"
              fullWidth
              loading={isLoading}
              endDecorator={
                <Box
                  sx={{
                    position: "absolute",
                    right: "32px",
                    top: "50%",
                    transform: "translateY(-50%) rotate(-90deg)",
                  }}
                >
                  <ArrowIcon color="#fff" />
                </Box>
              }
            >
              Далее
            </Button>
          </Box>
        </form>
      </Box>

      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <BackButton onClick={() => dispatch(changeStep("signIn_mail"))} />

          <Typography level="h3" marginBottom="7px" marginTop="10px">
            Вход
          </Typography>
          <Typography level="body-sm" textColor="#C0C0C0" fontWeight="500" marginBottom="24px">
            {email}
          </Typography>

          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { invalid } }) => (
              <Input
                placeholder="Ваш пароль"
                type={isHidden ? "text" : "password"}
                value={value}
                onChange={onChange}
                endDecorator={<SvgIcon component={Eye} onClick={() => setIsHidden(!isHidden)} />}
                invalid={invalid}
                message="Пароль не может быть пустым"
              />
            )}
          />

          <FixedBottomBox>
            <Button
              type="submit"
              fullWidth
              loading={isLoading}
              endDecorator={
                <Box
                  sx={{
                    position: "absolute",
                    right: "32px",
                    top: "50%",
                    transform: "translateY(-50%) rotate(-90deg)",
                  }}
                >
                  <ArrowIcon color="#fff" />
                </Box>
              }
            >
              Далее
            </Button>
          </FixedBottomBox>
        </form>
      </Box>
    </>
  );
};
