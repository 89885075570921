import { FC } from "react";
import { TabPanel, Tab, Box } from "@mui/joy";
import { MastersTab } from "./masters-tab";
import { ProductsTab } from "./products-tab";
import { TabValue } from "../../types/types";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Filter } from "../filter/filter";
import { Tabs } from "../../ui/tabs/tabs";
import { TabList } from "../../ui/tabs/tablist";
import { getTabValue } from "../../redux/filter/selectors";
import filterActions from "../../redux/filter/filter-slice";

const { changeTabValue } = filterActions;

export const SearchTabs: FC = () => {
  const dispatch = useAppDispatch();

  const tabValue = useAppSelector(getTabValue);

  return (
    <Tabs
      defaultValue={0}
      onChange={(e, value) => dispatch(changeTabValue(value as TabValue))}
      value={tabValue}
    >
      <TabList>
        <Tab value="products">Услуги</Tab>
        <Tab value="masters">Мастера</Tab>
      </TabList>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Filter />
      </Box>
      <TabPanel value="products">
        <ProductsTab />
      </TabPanel>
      <TabPanel value="masters">
        <MastersTab />
      </TabPanel>
    </Tabs>
  );
};
