import { FC, FormEvent, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, SvgIcon } from "@mui/joy";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getCreatedId } from "../../redux/order/selectors";
import { FixedBottomBox } from "../../ui/modal/fixed-bottom-box";
import { Button } from "../../ui/button/button";
import orderActions from "../../redux/order/order-slice";

import { ReactComponent as CheckCircle } from "../../assets/check-circle.svg";

const { changeOrderModalOpen } = orderActions;

export const OrderDoneModal: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createdId = useAppSelector(getCreatedId);

  const submitHandler = (e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(changeOrderModalOpen(false));
    navigate(`/records/${createdId}`);
  };

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <form onSubmit={submitHandler} style={{ height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <SvgIcon
                component={CheckCircle}
                viewBox="0 0 60 60"
                sx={{
                  borderRadius: "50%",
                  boxShadow: "0 0 20px 15px #e4c4ff",
                  width: "60px",
                  height: "60px",
                }}
              />
              <Typography fontSize="29px" fontWeight="bold" marginTop="45px">
                Поздравляем!
              </Typography>
              <Typography fontSize="22px">Вы успешно записались на процедуру</Typography>
              <Box marginTop="30px" display="flex" flexDirection="column" gap="27px">
                <Box display="flex" gap="15px" alignItems="baseline">
                  <Typography>🕒</Typography>
                  <Typography fontSize="20px" lineHeight="normal">
                    Запись отменяется минимум за 2 часа
                  </Typography>
                </Box>
                <Box display="flex" gap="15px" alignItems="baseline">
                  <Typography>🥲</Typography>
                  <Typography fontSize="20px" lineHeight="normal">
                    Пропуск или отмена записи понизят статус посещаемости
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Button type="submit" sx={{ marginTop: "70px" }}>
              Открыть запись
            </Button>
          </Box>
        </form>
      </Box>

      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <form onSubmit={submitHandler}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "30px",
            }}
          >
            <SvgIcon
              component={CheckCircle}
              viewBox="0 0 60 60"
              sx={{
                borderRadius: "50%",
                boxShadow: "0 0 20px 15px #e4c4ff",
                width: "60px",
                height: "60px",
              }}
            />
            <Typography fontSize="20px" fontWeight="bold" marginTop="30px">
              Поздравляем!
            </Typography>
            <Typography fontSize="17px">Вы успешно записались на процедуру</Typography>
            <Box marginTop="30px" display="flex" flexDirection="column" gap="27px">
              <Box display="flex" gap="10px" alignItems="baseline">
                <Typography>🗓️</Typography>
                <Typography fontSize="16px" lineHeight="normal">
                  Ознакомьтесь с информацией и подтвердите запись.
                </Typography>
              </Box>
              <Box display="flex" gap="10px" alignItems="baseline">
                <Typography>🕒</Typography>
                <Typography fontSize="16px" lineHeight="normal">
                  Запись отменяется минимум за 2 часа
                </Typography>
              </Box>
              <Box display="flex" gap="10px" alignItems="baseline">
                <Typography>🥲</Typography>
                <Typography fontSize="16px" lineHeight="normal">
                  Пропуск или отмена записи понизят статус посещаемости.
                </Typography>
              </Box>
            </Box>
          </Box>
          <FixedBottomBox>
            <Button fullWidth type="submit">
              Открыть запись
            </Button>
          </FixedBottomBox>
        </form>
      </Box>
    </>
  );
};
