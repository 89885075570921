import { FC } from "react";
import { Skeleton, Card, CardOverflow, CardContent, AspectRatio } from "@mui/joy";

export const MasterSkeleton: FC = () => {
  return (
    <Card variant="plain" sx={{ padding: 0, background: "none" }}>
      <CardOverflow>
        <AspectRatio ratio={1} sx={{ borderRadius: "10px" }}>
          <Skeleton animation="wave" variant="rectangular" />
        </AspectRatio>
      </CardOverflow>
      <CardContent>
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{ height: "20px", marginBottom: "10px" }}
        />
        <Skeleton animation="wave" variant="rectangular" sx={{ height: "15px" }} />
      </CardContent>
    </Card>
  );
};
