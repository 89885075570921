import { FC } from "react";

type ArrowIconProps = {
  width?: number;
  height?: number;
  color?: string;
  opacity?: string;
};

export const ArrowIcon: FC<ArrowIconProps> = ({
  width = 24,
  height = 24,
  color = "#c6c6c6",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 24 24`}
    fill="none"
  >
    <path
      d="M12.0246 13.2505L16.4496 8.82546C16.5996 8.67546 16.7746 8.60463 16.9746 8.61296C17.1746 8.6213 17.3496 8.70046 17.4996 8.85046C17.6496 9.00046 17.7246 9.17963 17.7246 9.38796C17.7246 9.5963 17.6496 9.77546 17.4996 9.92546L12.5496 14.8755C12.4663 14.9588 12.3829 15.0171 12.2996 15.0505C12.2163 15.0838 12.1246 15.1005 12.0246 15.1005C11.9246 15.1005 11.8329 15.0838 11.7496 15.0505C11.6663 15.0171 11.5829 14.9588 11.4996 14.8755L6.52461 9.90046C6.37461 9.75046 6.30378 9.57546 6.31211 9.37546C6.32044 9.17546 6.39961 9.00046 6.54961 8.85046C6.69961 8.70046 6.87878 8.62546 7.08711 8.62546C7.29544 8.62546 7.47461 8.70046 7.62461 8.85046L12.0246 13.2505Z"
      fill={color}
    />
  </svg>
);
