import { FC, ChangeEvent, Dispatch, SetStateAction } from "react";
import { Typography, Box } from "@mui/joy";
import { Switch } from "../../ui/switch/switch";
import { FilterType } from "../../types/types";

type FilterRatingProps = {
  filter: FilterType;
  setFilter: Dispatch<SetStateAction<FilterType>>;
};

export const FilterRating: FC<FilterRatingProps> = ({ filter, setFilter }) => {
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setFilter({ ...filter, highRating: e.target.checked });

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography level="h4">Высокий рейтинг</Typography>
      <Switch id="highRating" checked={filter.highRating} onChange={changeHandler} />
    </Box>
  );
};
