import { FC } from "react";
import { Skeleton, Box, AspectRatio, Divider } from "@mui/joy";

export const MasterInfoSkeleton: FC = () => {
  return (
    <>
      <Box display="flex" gap="20px">
        <AspectRatio
          ratio={1}
          sx={{
            minWidth: { xs: "80px", sm: "100px" },
            minHeight: { xs: "80px", sm: "100px" },
            borderRadius: "20px",
          }}
        >
          <Skeleton animation="wave" variant="rectangular" />
        </AspectRatio>
        <Box width="100%">
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{
              width: { xs: "150px", sm: "250px" },
              height: { xs: "12px", sm: "15px" },
              marginBottom: "12px",
              marginTop: "5px",
            }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{
              width: { xs: "150px", sm: "250px" },
              height: { xs: "15px", sm: "20px" },
              marginBottom: "12px",
            }}
          />
        </Box>
      </Box>

      <Box marginY="20px">
        <Box display="flex">
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{
              height: { xs: "74px", sm: "80px" },
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
            }}
          />
        </Box>
        <Divider sx={{ backgroundColor: "#e0e1e3" }} />
        <Box display="flex">
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{
              height: {
                xs: "72.5px",
                sm: "80px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
              },
            }}
          />
        </Box>
      </Box>

      <Skeleton
        animation="wave"
        variant="rectangular"
        sx={{
          height: { xs: "49px", sm: "53px" },
          borderRadius: "20px",
          marginY: "20px",
        }}
      />

      <Skeleton
        animation="wave"
        variant="rectangular"
        sx={{
          height: { xs: "52.5px", sm: "59.5px" },
          borderRadius: "20px",
        }}
      />
    </>
  );
};
