import { FC } from "react";
import { Box, Typography } from "@mui/joy";
import { StarIcon } from "../../ui/icons/star-icon";
import { getReviewsRightDeclension } from "../../utils/get-right-declension";

type ReviewsCountProps = {
  score: number;
  count: number;
  full?: boolean;
  dot?: boolean;
};

export const ReviewsCount: FC<ReviewsCountProps> = ({
  score,
  count,
  full = false,
  dot = false,
}) => {
  return (
    <Box display="flex" gap="3px" alignItems="flex-start">
      <StarIcon />
      <Typography
        sx={{ fontSize: { xs: "12px", sm: "14px" } }}
        fontWeight="bold"
        textColor="#962BED"
      >
        {score.toFixed(1)}
      </Typography>
      {dot && (
        <Typography sx={{ fontSize: { xs: "12px", sm: "14px" } }} textColor="#95969a">
          &#183;
        </Typography>
      )}
      <Typography sx={{ fontSize: { xs: "12px", sm: "14px" } }} textColor="#95969a">
        ({count}
        {full && getReviewsRightDeclension(count)})
      </Typography>
    </Box>
  );
};
