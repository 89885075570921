import { FC } from "react";
import { Button as MUIButton, ButtonProps as MUIButtonProps } from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";

type ButtonProps = MUIButtonProps & {
  colorVariant?: "light" | "solid" | "black";
  paddingX?: number;
  paddingY?: number;
  fontSize?: number;
  borderRadius?: number;
  sx?: SxProps;
};

export const Button: FC<ButtonProps> = ({
  children,
  colorVariant,
  paddingX = 40,
  paddingY = 14,
  fontSize = 15,
  borderRadius = 10,
  sx,
  ...props
}) => (
  <MUIButton
    {...props}
    sx={{
      backgroundColor:
        colorVariant === "black" ? "#222528" : colorVariant === "light" ? "#EAD5FB" : "#962BED",
      color: colorVariant === "light" ? "#962BED" : "#fff",
      fontSize: `${fontSize}px`,
      padding: `${paddingY}px ${paddingX}px`,
      borderRadius: `${borderRadius}px`,
      transition: ".2s",
      "&:hover": {
        backgroundColor:
          colorVariant === "black" ? "#000" : colorVariant === "light" ? "#e0bbfe" : "#7e21ca",
      },
      "&:active": {
        backgroundColor:
          colorVariant === "black" ? "#000" : colorVariant === "light" ? "#d7a7ff" : "#641aa1",
      },
      ...sx,
    }}
  >
    {children}
  </MUIButton>
);
