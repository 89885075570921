import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { modalClasses, Typography, Box, Button as MUIButton, CircularProgress } from "@mui/joy";
import { toast } from "react-toastify";
import { Modal } from "../../ui/modal/modal";
import { Button } from "../../ui/button/button";
import { FullscreenModal } from "../../ui/modal/fullscreen-modal";
import { FixedBottomBox } from "../../ui/modal/fixed-bottom-box";
import { toastOptions } from "../../const";

const CutyApi = require("cuty_api");

type CancelRecordProps = {
  id: string;
};

export const CancelRecord: FC<CancelRecordProps> = ({ id }) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const cancelClickHandler = () => {
    setIsDeleting(true);
    const apiInstance = new CutyApi.ScheduleRecordApi();
    const opts = {
      setScheduleStatusRequest: { status: "cancelled" },
    };
    apiInstance.setScheduleRecordStatus(id, opts, (error: any) => {
      if (error) {
        setIsDeleting(false);
        console.error(error);
        toast.error("Не удалось отменить запись", toastOptions);
      } else {
        setIsDeleting(false);
        setIsModalOpen(false);
        toast.warning("Вы отменили запись", toastOptions);
        navigate("/records");
      }
    });
  };

  return (
    <>
      <MUIButton
        fullWidth
        sx={{
          background: "#ffd4d4",
          color: "#f61e13",
          padding: "12px",
          border: "2px solid #fe7676",
          borderRadius: "16px",
          fontWeight: "bold",
          fontSize: "15px",
          transition: ".2s",
          ":hover": { background: "#fe767680" },
          ":active": { background: "#fe767699" },
        }}
        onClick={() => setIsModalOpen(true)}
      >
        Отменить запись
      </MUIButton>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        width="480px"
        padding="64px 47px 35px"
        sx={{
          display: { xs: "none", md: "block" },
          [`& .${modalClasses.backdrop}`]: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        }}
      >
        <Box display="flex" flexDirection="column" gap="50px">
          <Box textAlign="center">
            <Typography fontSize="24px" fontWeight="bold" textColor="#16161A" marginBottom="12px">
              Вы действительно
              <br /> хотите отменить запись?
            </Typography>
            <Typography fontSize="20px" textColor="#88888a">
              Вы сможете записаться в другой день
            </Typography>
          </Box>
          <Box display="flex" gap="20px" alignItems="center" justifyContent="space-between">
            <Box width="50%" textAlign="center">
              <Button
                disabled={isDeleting}
                borderRadius={13}
                paddingX={20}
                onClick={() => setIsModalOpen(false)}
              >
                Нет, не отменять
              </Button>
            </Box>
            <Typography
              onClick={cancelClickHandler}
              sx={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "17px",
                fontWeight: "bold",
                color: "#962BED",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {isDeleting ? <CircularProgress size="sm" /> : "Да, отменить"}
            </Typography>
          </Box>
        </Box>
      </Modal>

      <FullscreenModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        sx={{ display: { xs: "block", md: "none" } }}
        marginTop={350}
      >
        <Box display="flex" flexDirection="column" gap="50px">
          <Box textAlign="center">
            <Typography fontSize="20px" fontWeight="bold" textColor="#16161A" marginBottom="20px">
              Вы действительно
              <br /> хотите отменить запись?
            </Typography>
            <Typography fontSize="17px" textColor="#88888a">
              Вы сможете записаться в другой день
            </Typography>
          </Box>
        </Box>

        <FixedBottomBox>
          <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
            <Box width="50%" textAlign="center">
              <Button
                onClick={() => setIsModalOpen(false)}
                type="submit"
                borderRadius={13}
                paddingX={15}
                disabled={isDeleting}
              >
                Нет, не отменять
              </Button>
            </Box>
            <Typography
              onClick={cancelClickHandler}
              sx={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "17px",
                fontWeight: "bold",
                color: "#962BED",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {isDeleting ? <CircularProgress size="sm" /> : "Да, отменить"}
            </Typography>
          </Box>
        </FixedBottomBox>
      </FullscreenModal>
    </>
  );
};
