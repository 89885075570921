import { Button, styled } from "@mui/joy";
import { ReviewMaster } from "./review-master";
import { ToGoogleMaps } from "./to-google-maps";
import { AddToCalendar } from "./add-to-calendar";

export const ActionButton = styled(Button)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "14px",
  fontWeight: "500",
  padding: "0 10px",
  backgroundColor: "transparent",
  cursor: "pointer",
  transition: ".2s",
  ":hover": { backgroundColor: "rgba(0,0,0,.1)" },
  ":active": { backgroundColor: "rgba(0,0,0,.15)" },
});

export { ReviewMaster, ToGoogleMaps, AddToCalendar };
