import { FC } from "react";
import { Button, SvgIcon, Typography, styled } from "@mui/joy";
import { ReactComponent as GoogleIcon } from "../../assets/google-logo.svg";

type GoogleButtonProps = {
  onClick: () => void;
};

const GoogleBtn = styled(Button)({
  padding: "14px",
  backgroundColor: "#f1f1f1",
  display: "flex",
  gap: "5px",
  borderRadius: "10px",
  transition: ".2s",
  ":hover": {
    backgroundColor: "#00000020",
  },
  ":active": {
    backgroundColor: "#00000030",
  },
});

export const GoogleButton: FC<GoogleButtonProps> = ({ onClick }) => {
  return (
    <GoogleBtn fullWidth onClick={onClick}>
      <SvgIcon component={GoogleIcon} />
      <Typography fontSize="16px" fontWeight="500">
        Войти через Google
      </Typography>
    </GoogleBtn>
  );
};
