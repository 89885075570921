import { FC, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Drawer,
  Sheet,
  SvgIcon,
  ModalClose,
  modalClasses,
} from "@mui/joy";
import { ExtraContainer } from "../container/container";
import { ArrowIcon } from "../../ui/icons/arrow-icon";
import { SearchIcon } from "../../ui/icons/search-icon";
import { Input } from "../../ui/input/input";
import { Button } from "../../ui/button/button";
import { CalendarIcon } from "../../ui/icons/calendar-icon";
import { Modal } from "../../ui/modal/modal";
import { FullscreenModal } from "../../ui/modal/fullscreen-modal";
import { FixedBottomBox } from "../../ui/modal/fixed-bottom-box";
import { LoginModal } from "../login-modals/login-modal";
import { auth } from "../../services/firebase";
import { useAppSelector, useAppDispatch, useOnOutsideClick } from "../../hooks";
import { getAuthStatus } from "../../redux/auth/selectors";
import { AuthStep } from "../../types/types";
import authActions from "../../redux/auth/auth-slice";
import filterActions from "../../redux/filter/filter-slice";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as SupportIcon } from "../../assets/support.svg";
import { ReactComponent as NotificationIcon } from "../../assets/notification.svg";
import { ReactComponent as MenuIcon } from "../../assets/menu.svg";

const { changeAuthStatus, changeStep, changeAuthModalOpen, setUser } = authActions;
const { changeSearchQuery } = filterActions;

export const Header: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const authStatus = useAppSelector(getAuthStatus);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isSignOutOpen, setIsSignOutOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

  const mobileInputRef = useRef<HTMLDivElement | null>(null);
  useOnOutsideClick(mobileInputRef, () => setIsSearchOpen(false));

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [authStatus]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(changeSearchQuery(searchQuery));
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [dispatch, searchQuery]);

  const searchClickHandler = () => pathname !== "/search" && navigate("/search");

  const logoClickHandler = () => navigate("/");

  const closeSignOutModal = () => setIsSignOutOpen(false);
  const openSignOutModal = () => setIsSignOutOpen(true);

  const loginClickHandler = (step: AuthStep) => {
    if (authStatus === "authorized") {
      openSignOutModal();
      setIsDrawerOpen(false);
    } else {
      dispatch(changeAuthModalOpen(true));
      dispatch(changeStep(step));
    }
  };

  const signOutHandler = () => {
    signOut(auth).then(() => dispatch(setUser(null)));
    dispatch(changeAuthStatus("unauthorized"));
    closeSignOutModal();
  };

  const isMainPage = pathname === "/";
  const isRecordsPage = pathname === "/records";

  const mainClickHandler = () => !isMainPage && navigate("/");
  const recordsClickHandler = () => !isRecordsPage && navigate("/records");

  return (
    <Box sx={{ backgroundColor: "#fefefe", width: "100%", paddingY: "10px" }}>
      <ExtraContainer>
        <Grid container spacing={2} justifyContent="space-between" position="relative">
          <Grid xs={9}>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Box
                onClick={logoClickHandler}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  height: { xs: "44px", md: "66px" },
                }}
              >
                <SvgIcon
                  component={Logo}
                  sx={{ width: "100%", height: "100%" }}
                  viewBox="0 0 66 66"
                />
              </Box>
              <Grid xs={6}>
                <Input
                  onClick={searchClickHandler}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  variant="plain"
                  placeholder="Найдите идеального мастера и услугу"
                  startDecorator={<SearchIcon />}
                  marginY={0}
                  sx={{ display: { xs: "none", md: "flex" } }}
                />
              </Grid>
              <Typography
                level="title-lg"
                fontSize="17px"
                sx={{
                  color: isMainPage ? "#2B2D35" : "#949599",
                  transition: ".2s",
                  cursor: "pointer",
                  "&:hover": {
                    color: isMainPage ? "#2B2D35" : "#707070",
                  },
                  display: { xs: "none", lg: "block" },
                }}
                onClick={mainClickHandler}
              >
                Главная
              </Typography>
              {authStatus === "authorized" && (
                <Typography
                  level="title-lg"
                  fontSize="17px"
                  sx={{
                    color: isRecordsPage ? "#2B2D35" : "#949599",
                    transition: ".2s",
                    cursor: "pointer",
                    "&:hover": {
                      color: isRecordsPage ? "#2B2D35" : "#707070",
                    },
                    display: { xs: "none", md: "block" },
                  }}
                  onClick={recordsClickHandler}
                >
                  Мои записи
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid xs={3} alignSelf="center">
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {/* <Dropdown>
                <MenuButton
                  sx={{
                    padding: "5px",
                    "--Button-gap": 0,
                    display: { xs: "none", md: "flex" },
                  }}
                  endDecorator={<ArrowIcon />}
                  variant="plain"
                >
                  <SvgIcon component={SupportIcon} />
                </MenuButton>
                <Menu sx={{ minWidth: 160 }}>
                  <MenuItem>Поддержка</MenuItem>
                </Menu>
              </Dropdown>
              {authStatus === "authorized" && (
                <Box sx={{ display: "flex", gap: "8px" }}>
                  <SvgIcon component={NotificationIcon} />
                  <Chip
                    sx={{
                      backgroundColor: "rgba(229, 1, 124, 0.10)",
                      borderRadius: 6,
                      paddingX: "5px",
                      color: "rgba(229, 1, 124, 0.60)",
                      fontWeight: "bold",
                    }}
                  >
                    41
                  </Chip>
                </Box>
              )}
              <MUIButton
                variant="plain"
                sx={{
                  padding: "5px",
                  "--Button-gap": 0,
                  display: { xs: "none", md: "flex" },
                }}
                endDecorator={<ArrowIcon />}
              >
                <Typography level="title-md" fontWeight="bold">
                  Москва
                </Typography>
              </MUIButton> */}

              <Box ref={mobileInputRef} sx={{ display: "flex" }}>
                <IconButton
                  sx={{ display: { xs: isSearchOpen ? "none" : "block", md: "none" } }}
                  onClick={() => {
                    setIsSearchOpen((prev) => !prev);
                    searchClickHandler();
                  }}
                >
                  <SearchIcon color="#292929" />
                </IconButton>

                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  variant="plain"
                  placeholder="Поиск..."
                  startDecorator={<SearchIcon />}
                  padding="7px"
                  marginY={0}
                  sx={{
                    display: {
                      xs: "flex",
                      md: "none",
                    },
                    transition: "0.2s",
                    position: "absolute",
                    boxShadow: "0 0 0 2px #ddd, 0 0 6px #ccc",
                    maxWidth: "210px",
                    transform: isSearchOpen
                      ? "translate(-100%, -20px)"
                      : "translate(-100%, -100px)",
                  }}
                />
              </Box>

              {authStatus === "authorized" && (
                <>
                  <IconButton
                    onClick={() => setIsDrawerOpen(true)}
                    sx={{ display: { xs: "flex", md: "none" } }}
                  >
                    <SvgIcon component={MenuIcon} />
                  </IconButton>
                  <Drawer
                    sx={{ display: { xs: "block", md: "none" } }}
                    open={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                    size="md"
                    variant="plain"
                    anchor="right"
                    slotProps={{
                      content: {
                        sx: {
                          bgcolor: "transparent",
                          p: 3,
                          boxShadow: "none",
                          height: "250px",
                        },
                      },
                    }}
                  >
                    <Sheet
                      sx={{
                        borderRadius: "md",
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        height: "100%",
                        overflow: "auto",
                      }}
                    >
                      <ModalClose size="md" sx={{ right: "auto", left: "0.625rem" }} />

                      <Box marginTop="40px" display="flex" flexDirection="column" gap="15px">
                        <Button
                          onClick={() => {
                            recordsClickHandler();
                            setIsDrawerOpen(false);
                          }}
                        >
                          Мои записи
                        </Button>

                        <Button colorVariant="light" onClick={() => loginClickHandler("phone")}>
                          Выйти
                        </Button>
                      </Box>
                    </Sheet>
                  </Drawer>
                </>
              )}

              <Button
                sx={{
                  fontSize: { xs: "13px", md: "15px" },
                  padding: { xs: "10px 20px", md: "14px 40px" },
                  display: { xs: authStatus === "authorized" ? "none" : "block", md: "block" },
                }}
                colorVariant={authStatus === "authorized" ? "light" : "black"}
                onClick={() => loginClickHandler("signIn")}
              >
                {authStatus === "authorized" ? "Выйти" : "Войти"}
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Modal
          open={isSignOutOpen}
          onClose={closeSignOutModal}
          width="480px"
          padding="64px 47px 35px"
          sx={{
            display: { xs: "none", md: "block" },
            [`& .${modalClasses.backdrop}`]: {
              backgroundColor: "rgba(0,0,0,0.7)",
            },
          }}
        >
          <Box display="flex" flexDirection="column" gap="25px">
            <Box textAlign="center">
              <Typography fontSize="24px" fontWeight="bold" textColor="#16161A">
                Вы действительно
                <br /> хотите выйти?
              </Typography>
            </Box>
            <Box display="flex" gap="20px" alignItems="center" justifyContent="space-evenly">
              <Button borderRadius={13} paddingX={20} onClick={closeSignOutModal}>
                Нет
              </Button>
              <Typography
                onClick={signOutHandler}
                sx={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  color: "#962BED",
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Да
              </Typography>
            </Box>
          </Box>
        </Modal>

        <FullscreenModal
          open={isSignOutOpen}
          onClose={closeSignOutModal}
          sx={{ display: { xs: "block", md: "none" } }}
          marginTop={450}
        >
          <Box display="flex" flexDirection="column" gap="25px">
            <Box textAlign="center">
              <Typography fontSize="20px" fontWeight="bold" textColor="#16161A" marginBottom="20px">
                Вы действительно
                <br /> хотите выйти?
              </Typography>
            </Box>
          </Box>

          <FixedBottomBox>
            <Box
              display="flex"
              width="100%"
              gap="20px"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Button onClick={closeSignOutModal} borderRadius={13} paddingX={20}>
                Нет
              </Button>
              <Typography
                onClick={signOutHandler}
                sx={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  color: "#962BED",
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Да
              </Typography>
            </Box>
          </FixedBottomBox>
        </FullscreenModal>

        <LoginModal />
      </ExtraContainer>
    </Box>
  );
};
