import {
  MasterType,
  ProductType,
  ScheduleWindow,
  State,
} from "../../types/types";

export const getMaster = (state: State): MasterType | null =>
  state.MASTER.master;

export const getIsMasterLoading = (state: State): boolean =>
  state.MASTER.isMasterLoading;

export const getProducts = (state: State): ProductType[] =>
  state.MASTER.products;

export const getIsProductsLoading = (state: State): boolean =>
  state.MASTER.isProductsLoading;

export const getWindows = (state: State): ScheduleWindow[] =>
  state.MASTER.windows;

export const getIsWindowsLoading = (state: State): boolean =>
  state.MASTER.isWindowsLoading;
