import { FC } from "react";
import { Card, CardContent, Typography, Box, Grid, aspectRatioClasses } from "@mui/joy";
import { useAppSelector } from "../../hooks";
import { ReviewsCount } from "../reviews-count/reviews-count";
import { getChoosingProducts } from "../../redux/order/selectors";
import { Button } from "../../ui/button/button";
import { Picture } from "../../ui/picture/picture";
import { formatPrice } from "../../utils/format-price";

import noImage from "../../assets/no-image.svg";

type InfoCardProps = {
  photo_url: string;
  title: string;
  reviews_count?: number;
  reviews_score?: number;
  price?: number;
  currency?: string;
  id: string;
  buttonAction?: () => void;
  pictureLgBreakpoint?: number;
};

export const InfoCard: FC<InfoCardProps> = ({
  photo_url,
  price,
  currency,
  reviews_count,
  reviews_score,
  title,
  id,
  buttonAction = () => {},
  pictureLgBreakpoint,
}) => {
  const choosingProducts = useAppSelector(getChoosingProducts);

  return (
    <Card
      sx={{
        border: "1px solid #e6e6e6",
        borderRadius: "20px",
        padding: "15px",
        overflow: "hidden",
        transition: "0.2s",
        cursor: "pointer",
        ":hover": {
          boxShadow: "0 1px 10px 1px #ddd",
          borderRadius: "10px",
        },
        ":active": {
          background: "rgba(0,0,0,0.03)",
        },
      }}
      onClick={buttonAction}
    >
      <Grid container spacing={2} sx={{ flexDirection: { xs: "column", sm: "row" } }}>
        <Grid xs={12} sm={4} lg={pictureLgBreakpoint ? pictureLgBreakpoint : false}>
          <Picture
            src={photo_url ? photo_url : noImage}
            sx={{
              height: "100%",
              [`.${aspectRatioClasses.content}`]: {
                paddingBottom: { xs: "173px", sm: "150px" },
              },
            }}
          />
        </Grid>
        <Grid xs={12} sm={8} lg={pictureLgBreakpoint ? 12 - pictureLgBreakpoint : false}>
          <CardContent sx={{ height: "100%", justifyContent: "space-between" }}>
            <Box>
              <Typography
                marginBottom="8px"
                fontWeight="bold"
                sx={{
                  fontSize: { xs: "17px", sm: "18px" },
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {title}
              </Typography>
              {reviews_count && reviews_score && (
                <ReviewsCount count={reviews_count} score={reviews_score} full />
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: price ? "space-between" : "flex-end",
                marginTop: "20px",
                height: "45px",
              }}
            >
              {price && (
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                  <Typography sx={{ fontSize: { xs: "17px", sm: "19px" } }} fontWeight="bold">
                    {formatPrice(price, currency)}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#9a9b9f",
                      fontSize: { xs: "12px", sm: "14px" },
                    }}
                  >
                    Онлайн запись доступна
                  </Typography>
                </Box>
              )}
              <Button paddingY={10} paddingX={15} colorVariant="light">
                {choosingProducts ? "Выбрать" : "Записаться"}
              </Button>
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};
