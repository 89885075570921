import { FC } from "react";
import { Box } from "@mui/joy";
import { SearchCategory } from "./search-category";
import { SearchCategorySkeleton } from "./skeleton";
import { useAppSelector } from "../../hooks";
import { getCategoriesLoading, getCategories } from "../../redux/data/selectors";

export const SearchCategories: FC = () => {
  const categories = useAppSelector(getCategories);
  const isLoading = useAppSelector(getCategoriesLoading);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2.5,
        paddingBottom: "10px",
        overflow: "scroll",
        overflowY: "hidden",
        "::-webkit-scrollbar": {
          height: "4px",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#ededed",
          borderRadius: "2px",
          ":active": {
            background: "#d2d2d3",
          },
        },
      }}
    >
      {isLoading
        ? Array.from({ length: 16 }).map((_, idx) => (
            <SearchCategorySkeleton key={`${idx}-s-c-skeleton`} />
          ))
        : categories.map((category) => <SearchCategory key={category.id} category={category} />)}
    </Box>
  );
};
