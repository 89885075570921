import { Box, styled } from "@mui/joy";
import { Swiper as ReactSwiper } from "swiper/react";

export const Swiper = styled(ReactSwiper)({
  position: "relative",
});

const SwiperNavigationButton = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 10,
  cursor: "pointer",
  color: "#8E8F93",
  width: "45px",
  height: "45px",
  background: "#F1F1F1",
  borderRadius: "50%",
  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
  border: "1px solid rgba(43, 45, 53, 0.10)",
  transition: ".15s",
  "&::after": {
    fontSize: "15px",
    fontWeight: "bold",
  },
  "&.swiper-button-disabled": {
    opacity: 0,
  },
});

export const Prev = styled(SwiperNavigationButton)({
  left: "10px",
  "&:hover": {
    transform: "translateY(-50%) translateX(-3px)",
  },
  "&:active": {
    transform: "translateY(-50%) translateX(-1px)",
  },
});

export const Next = styled(SwiperNavigationButton)({
  right: "10px",
  "&:hover": {
    transform: "translateY(-50%) translateX(3px)",
  },
  "&:active": {
    transform: "translateY(-50%) translateX(1px)",
  },
});
