import { FC, ChangeEvent, Dispatch, SetStateAction } from "react";
import { Box, Typography, Sheet, RadioGroup } from "@mui/joy";
import { Radio } from "../../ui/radio/radio";
import { useAppSelector } from "../../hooks";
import { getCategories } from "../../redux/data/selectors";
import { FilterType } from "../../types/types";

type FilterCategoriesProps = {
  filter: FilterType;
  setFilter: Dispatch<SetStateAction<FilterType>>;
};

export const FilterCategories: FC<FilterCategoriesProps> = ({ filter, setFilter }) => {
  const categories = useAppSelector(getCategories);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setFilter({ ...filter, categoryId: e.target.value });

  const reset = () =>
    setFilter({
      categoryId: "",
      price: { min: 0, max: 0 },
      highRating: false,
      onlyWithPhoto: false,
      distance: null,
    });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Typography level="h4">Категории</Typography>
        <Typography
          level="title-md"
          fontWeight="bold"
          textColor="#962BED"
          onClick={reset}
          sx={{
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          Сбросить
        </Typography>
      </Box>

      <RadioGroup
        sx={{ gap: 1, flexDirection: "row", flexWrap: "wrap" }}
        value={filter.categoryId}
        onChange={changeHandler}
      >
        {categories.map((category) => (
          <Sheet key={category.id}>
            <Radio
              label={category.title}
              value={category.id}
              padding="7px 20px"
              name="categoryId"
            />
          </Sheet>
        ))}
      </RadioGroup>
    </>
  );
};
