import { FC } from "react";
import {
  Skeleton,
  Card,
  CardContent,
  Grid,
  AspectRatio,
  Box,
  aspectRatioClasses,
} from "@mui/joy";

export const InfoCardSkeleton: FC<{ pictureLgBreakpoint?: number }> = ({
  pictureLgBreakpoint,
}) => (
  <Card
    sx={{
      border: "1px solid #e6e6e6",
      borderRadius: "20px",
      padding: "15px",
      overflow: "hidden",
    }}
  >
    <Grid
      container
      spacing={2}
      sx={{ flexDirection: { xs: "column", sm: "row" } }}
    >
      <Grid
        xs={12}
        sm={4}
        lg={pictureLgBreakpoint ? pictureLgBreakpoint : false}
      >
        <AspectRatio
          sx={{
            width: "100%",
            height: "100%",
            [`.${aspectRatioClasses.content}`]: {
              paddingBottom: { xs: "173px", sm: "150px" },
            },
          }}
        >
          <Skeleton animation="wave" variant="rectangular" />
        </AspectRatio>
      </Grid>
      <Grid
        xs={12}
        sm={8}
        lg={pictureLgBreakpoint ? 12 - pictureLgBreakpoint : false}
      >
        <CardContent sx={{ height: "100%", justifyContent: "space-between" }}>
          <Box>
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{ height: "24px", marginBottom: "6px" }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                height: "20px",
                width: "30%",
                display: { xs: "none", sm: "block" },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              marginTop: "20px",
            }}
          >
            <Box sx={{ width: "40%" }}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                sx={{ height: "20px", marginBottom: "10px" }}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                sx={{ height: "15px" }}
              />
            </Box>
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{ height: "45px", width: "117px", borderRadius: "10px" }}
            />
          </Box>
        </CardContent>
      </Grid>
    </Grid>
  </Card>
);
