import { FC } from "react";
import {
  Card,
  CardContent,
  CardCover,
  AspectRatio,
  Grid,
  Box,
  Typography,
  SvgIcon,
  aspectRatioClasses,
} from "@mui/joy";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Picture } from "../../ui/picture/picture";
import { formatPrice } from "../../utils/format-price";
import { ProductType, MasterType } from "../../types/types";
import { getOrderProducts } from "../../redux/order/selectors";

import orderActions from "../../redux/order/order-slice";
import masterActions from "../../redux/master/master-slice";

import { ReactComponent as Plus } from "../../assets/plus.svg";
import noImage from "../../assets/no-image.svg";

const CutyApi = require("cuty_api");

const { changeOrderModalOpen, changeStep: changeOrderStep, addProduct, clearOrder } = orderActions;

const { changeIsMasterLoading, changeMaster } = masterActions;

type ProductProps = {
  product: ProductType;
};

export const Product: FC<ProductProps> = ({ product }) => {
  const dispatch = useAppDispatch();

  const orderProducts = useAppSelector(getOrderProducts);

  const clickHandler = (product: ProductType) => {
    dispatch(changeOrderStep("sum"));
    if (orderProducts.length > 0 && orderProducts[0].user_id !== product.user_id) {
      dispatch(clearOrder());
    }
    dispatch(addProduct(product));
    dispatch(changeOrderModalOpen(true));

    const apiInstance = new CutyApi.MasterApi();
    dispatch(changeIsMasterLoading(true));
    apiInstance.getMasterByFilter({ userId: product.user_id }, (error: any, data: MasterType) => {
      if (error) {
        console.error(error);
        dispatch(changeIsMasterLoading(false));
      } else {
        dispatch(changeIsMasterLoading(false));
        dispatch(changeMaster(data));
      }
    });
  };

  return (
    <Card
      sx={{
        minHeight: { xs: "300px", md: "340px" },
        borderRadius: "25px",
        padding: "10px",
        border: "none",
        cursor: "pointer",
        ":hover .plus": {
          backgroundColor: "rgba(0,0,0, 0.3)",
          transform: "scale(1.07)",
          boxShadow: "0 0 7px #0001",
        },
        ":active .plus": {
          transform: "scale(1.03)",
        },
      }}
      onClick={() => clickHandler(product)}
    >
      <CardCover>
        <Picture
          src={product.photo_url ? product.photo_url : noImage}
          ratio="3/4"
          sx={{
            height: "100%",
            [`&& .${aspectRatioClasses.content}`]: {
              paddingBottom: { xs: "320px", md: "360px" },
            },
          }}
        />
      </CardCover>
      <CardContent
        sx={{
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <AspectRatio
          ratio={1}
          className="plus"
          sx={{
            width: "60px",
            borderRadius: "50%",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            transition: "0.2s",
          }}
          variant="plain"
        >
          <SvgIcon component={Plus} viewBox="0 0 47 47" />
        </AspectRatio>
      </CardContent>
      <CardContent sx={{ justifyContent: "flex-end" }}>
        <Box
          sx={{
            borderRadius: "20px",
            background: "#fff",
            padding: "12px",
            fontSize: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={1}
              sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
            >
              <Grid minWidth="50px">
                <Picture src={product.category.icon} sx={{ borderRadius: "50%" }} />
              </Grid>
              <Grid>
                <Typography
                  fontSize={{ xs: "15px", md: "16px" }}
                  fontWeight="bold"
                  sx={{
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {product.title}
                </Typography>
              </Grid>
            </Grid>
            <Box>
              <Typography
                fontSize={{ xs: "16px", sm: "17px", md: "18px" }}
                fontWeight="bold"
                noWrap
                overflow="visible"
              >
                {formatPrice(product.amount, product.currency)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
