import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Grid, Box } from "@mui/joy";
import { Picture } from "../../ui/picture/picture";
import { StatusLabel } from "../../ui/status-label/status-label";
import { ArrowIcon } from "../../ui/icons/arrow-icon";
import { formatDate } from "../../utils/format-date";
import { ScheduleRecordType } from "../../types/types";

import noImage from "../../assets/no-image.svg";

type RecordProps = {
  record: ScheduleRecordType;
};

export const Record: FC<RecordProps> = ({ record }) => {
  const navigate = useNavigate();

  const recordClickHandler = () => navigate(`${record.id}`);

  return (
    <Button
      onClick={recordClickHandler}
      sx={{
        p: 0,
        m: 0,
        borderRadius: "16px",
        background: "transparent",
        ":hover": { background: "none" },
        ":active": { background: "none" },
      }}
    >
      <Grid
        container
        xs={12}
        sx={{
          background: "#fff",
          borderRadius: "16px",
          paddingX: "12px",
          paddingTop: { xs: "12px", md: "6px" },
          paddingBottom: { xs: "0px", md: "6px" },
          boxShadow: "0 0 7px #0001",
          transition: ".2s",
          cursor: "pointer",
          ":hover": {
            boxShadow: "0 0 7px 2px #0002",
          },
          ":active": {
            background: "#f1f1f1",
          },
        }}
      >
        <Grid container xs={11.5}>
          {record.products.map((product) => (
            <Grid
              container
              key={product.id}
              xs={12}
              marginY="0px"
              spacing={{ xs: 0, md: 2 }}
              marginBottom={{ xs: "12px", md: "0" }}
            >
              <Grid
                xs={12}
                md={4}
                display="flex"
                gap="14px"
                alignItems={{ xs: "flex-start", md: "center" }}
              >
                <Picture
                  src={product.photo_url}
                  sx={{
                    minWidth: { xs: "50px", md: "66px" },
                    minHeight: { xs: "50px", md: "66px" },
                    borderRadius: "10px",
                  }}
                />
                <Typography
                  fontSize={{ xs: "14px", sm: "16px", md: "17px" }}
                  fontWeight="bold"
                  sx={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {product.title}
                </Typography>
              </Grid>
              <Grid
                xs={12}
                md={2}
                display="flex"
                gap="9px"
                alignItems="center"
                justifyContent={{ xs: "flex-start", md: "center" }}
                marginLeft={{ xs: "64px", md: "0" }}
              >
                <Picture
                  src={record.owner.photo_url ? record.owner.photo_url : noImage}
                  sx={{
                    minWidth: "24px",
                    minHeight: "24px",
                    borderRadius: "50%",
                  }}
                />
                <Typography fontSize={{ xs: "14px", md: "16px" }}>
                  {record.owner.display_name}
                </Typography>
              </Grid>

              <Grid
                md={2}
                display={{ xs: "none", md: "flex" }}
                alignItems="center"
                justifyContent="center"
              >
                <Typography fontWeight="bold">
                  {formatDate(record.start_time.toISOString(), {
                    day: "2-digit",
                    month: "long",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Typography>
              </Grid>
              <Grid
                md={1}
                display={{ xs: "none", md: "flex" }}
                alignItems="center"
                justifyContent="center"
              >
                <Typography fontWeight="bold">{product.amount} ₽</Typography>
              </Grid>

              <Grid
                xs={12}
                display={{ xs: "flex", md: "none" }}
                alignItems="center"
                justifyContent="space-between"
                order={{ xs: 2, md: 1 }}
                marginLeft={{ xs: "64px", md: "0" }}
              >
                <Typography fontSize="14px" textColor="#95969a">
                  {formatDate(record.start_time.toISOString(), {
                    day: "2-digit",
                    month: "long",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Typography>
                <Typography fontWeight="bold">{product.amount} ₽</Typography>
              </Grid>

              <Grid
                xs={12}
                md={3}
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "flex-start", md: "center" }}
                order={{ xs: 1, md: 2 }}
                marginLeft={{ xs: "64px", md: "0" }}
                marginTop={{ xs: "10px", md: "0" }}
                marginBottom={{ xs: "5px", md: "0" }}
              >
                <StatusLabel status={record.status} />
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid xs={0.5} display="flex" alignItems="center">
          <Box sx={{ transform: "rotate(-90deg)" }}>
            <ArrowIcon />
          </Box>
        </Grid>
      </Grid>
    </Button>
  );
};
