import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box } from "@mui/joy";
import { ExtraContainer } from "../components/container/container";
import { Filter } from "../components/filter/filter";
import { SearchTabs } from "../components/search-tabs/search-tabs";
import { BackButton } from "../components/back-button/back-button";
import { SearchCategories } from "../components/search-categories/search-categories";
import { ToTheTop } from "../components/to-the-top/to-the-top";

export const SearchPage: FC = () => {
  const navigate = useNavigate();

  const backClickHandler = () => navigate(-1);

  return (
    <ExtraContainer>
      <ToTheTop />
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <BackButton onClick={backClickHandler} marginY="20px" />
      </Box>
      <Grid container spacing={2} sx={{ marginTop: { xs: "10px", md: "0" } }}>
        <Grid md={4} lg={3} sx={{ display: { xs: "none", md: "block" } }}>
          <Filter />
        </Grid>
        <Grid md={8} lg={9}>
          <SearchCategories />
          <SearchTabs />
        </Grid>
      </Grid>
    </ExtraContainer>
  );
};
