import { Navigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/joy";
import { getAuthStatus, getIsAuthLoading } from "../../redux/auth/selectors";
import { useAppSelector } from "../../hooks";
import { AuthStatus } from "../../types/types";

type PrivateRouteProps = {
  children: JSX.Element;
  requiredAuthStatus: AuthStatus;
};

export const PrivateRoute = ({
  children,
  requiredAuthStatus,
}: PrivateRouteProps): JSX.Element => {
  const authStatus = useAppSelector(getAuthStatus);
  const isAuthLoading = useAppSelector(getIsAuthLoading);

  return isAuthLoading ? (
    <Box display="flex" justifyContent="center" marginTop="40px">
      <CircularProgress variant="plain" color="neutral" size="lg" />
    </Box>
  ) : authStatus === requiredAuthStatus ? (
    children
  ) : (
    <Navigate to="/" />
  );
};
