import { FC, useState } from "react";
import { signInWithPopup, signInAnonymously } from "firebase/auth";
import { Typography, Box, Divider, SvgIcon, CircularProgress, styled } from "@mui/joy";
import { toast } from "react-toastify";
import { Button } from "../../ui/button/button";
import { GoogleButton } from "../../ui/button/google-button";
import { auth, googleProvider } from "../../services/firebase";
import { useAppDispatch } from "../../hooks";
import { AuthStep } from "../../types/types";
import authActions from "../../redux/auth/auth-slice";

import { ReactComponent as Envelope } from "../../assets/envelope.svg";
import { ReactComponent as Incognito } from "../../assets/incognito.svg";

const { changeStep, changeAuthModalOpen } = authActions;

const AnonymousButton = styled(Button)({
  backgroundColor: "#f1f1f1",
  fontWeight: "500",
  color: "#32383E",
  ":hover": {
    backgroundColor: "#00000020",
  },
  ":active": {
    backgroundColor: "#00000030",
  },
});

export const SignInModal: FC = () => {
  const dispatch = useAppDispatch();

  const [isAnonLoading, setIsAnonLoading] = useState<boolean>(false);

  const googleSignIn = () => {
    signInWithPopup(auth, googleProvider)
      .then(() => {
        dispatch(changeAuthModalOpen(false));
        setIsAnonLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Что-то случилось не так :(");
        setIsAnonLoading(false);
      });
  };

  const loginClickHandler = (step: AuthStep) => {
    dispatch(changeAuthModalOpen(true));
    dispatch(changeStep(step));
  };

  const anonymouslySignIn = () => {
    setIsAnonLoading(true);
    signInAnonymously(auth)
      .then(() => {
        dispatch(changeAuthModalOpen(false));
        setIsAnonLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Что-то случилось не так :(");
        setIsAnonLoading(false);
      });
  };

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Box
          sx={{
            minHeight: "350px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <Box>
            <Typography level="h2" textAlign="center" marginBottom="7px">
              Вход или регистрация
            </Typography>
            <Typography
              level="body-md"
              marginBottom="24px"
              textColor="#C0C0C0"
              fontWeight="500"
              textAlign="center"
            >
              Необходимо авторизоваться в приложении <br />
              чтобы продолжить создание записи
            </Typography>
          </Box>

          <Button
            fontSize={16}
            onClick={() => loginClickHandler("signIn_mail")}
            startDecorator={<SvgIcon component={Envelope} viewBox="0 0 512 512" />}
          >
            Войти по Email
          </Button>

          <GoogleButton onClick={googleSignIn} />

          <Box sx={{ my: "10px", display: "flex", gap: "15px" }}>
            <Divider sx={{ flexGrow: 1, my: "8px" }} />
            <Typography lineHeight="16px">или</Typography>
            <Divider sx={{ flexGrow: 1, my: "8px" }} />
          </Box>

          <AnonymousButton
            sx={{ fontSize: "16px" }}
            startDecorator={!isAnonLoading && <SvgIcon component={Incognito} />}
            onClick={anonymouslySignIn}
            disabled={isAnonLoading}
          >
            {isAnonLoading ? (
              <CircularProgress size="sm" variant="plain" color="neutral" />
            ) : (
              "Продолжить без регистрации"
            )}
          </AnonymousButton>
        </Box>
      </Box>

      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box>
            <Typography level="h3" textAlign="center" marginBottom="7px">
              Вход или регистрация
            </Typography>
            <Typography
              level="body-sm"
              marginBottom="24px"
              textColor="#C0C0C0"
              fontWeight="500"
              textAlign="center"
            >
              Необходимо авторизоваться в приложении <br />
              чтобы продолжить создание записи
            </Typography>
          </Box>

          <Button
            onClick={() => loginClickHandler("signIn_mail")}
            startDecorator={<SvgIcon component={Envelope} viewBox="0 0 512 512" />}
          >
            Войти по Email
          </Button>

          <GoogleButton onClick={googleSignIn} />

          <Box sx={{ my: "5px", display: "flex", gap: "15px" }}>
            <Divider sx={{ flexGrow: 1, my: "8px" }} />
            <Typography lineHeight="16px">или</Typography>
            <Divider sx={{ flexGrow: 1, my: "8px" }} />
          </Box>

          <AnonymousButton
            startDecorator={!isAnonLoading && <SvgIcon component={Incognito} />}
            onClick={anonymouslySignIn}
            disabled={isAnonLoading}
          >
            {isAnonLoading ? (
              <CircularProgress size="sm" variant="plain" color="neutral" />
            ) : (
              "Продолжить без регистрации"
            )}
          </AnonymousButton>
        </Box>
      </Box>
    </>
  );
};
