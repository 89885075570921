import { FC, ChangeEvent, Dispatch, SetStateAction } from "react";
import { Box, Typography, Grid } from "@mui/joy";
import { Input } from "../../ui/input/input";
import { FilterType } from "../../types/types";

type FilterPriceProps = {
  filter: FilterType;
  setFilter: Dispatch<SetStateAction<FilterType>>;
};

export const FilterPrice: FC<FilterPriceProps> = ({ filter, setFilter }) => {
  const { max, min } = filter.price;
  const changePriceHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    !Number.isNaN(value) &&
      setFilter({
        ...filter,
        price: { ...filter.price, [e.target.name]: value },
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h4">Цена</Typography>
      </Box>
      <Grid container spacing={1} alignItems="center">
        <Grid xs={5.25}>
          <Input
            value={min > 0 ? min : ""}
            onChange={changePriceHandler}
            inputMode="numeric"
            placeholder="Мин"
            name="min"
          />
        </Grid>
        <Grid xs={1.5} textAlign="center">
          <Typography fontSize="14px" fontWeight="bold" sx={{ color: "#707070" }}>
            до
          </Typography>
        </Grid>
        <Grid xs={5.25}>
          <Input
            value={max > 0 ? max : ""}
            onChange={changePriceHandler}
            inputMode="numeric"
            placeholder="Макс"
            name="max"
          />
        </Grid>
      </Grid>
    </>
  );
};
