import { FC } from "react";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { getStep, getAuthModalOpen } from "../../redux/auth/selectors";
import { SignInModal } from "./signin-modal";
import { SignInMailModal } from "./signin-mail-modal";
import { RegisterModal } from "./register-modal";
import { SignInPasswordModal } from "./signin-password-modal";
import { Modal } from "../../ui/modal/modal";
import { FullscreenModal } from "../../ui/modal/fullscreen-modal";
import authActions from "../../redux/auth/auth-slice";

const { changeAuthModalOpen } = authActions;

export const LoginModal: FC = () => {
  const dispatch = useAppDispatch();

  const step = useAppSelector(getStep);
  const isModalOpen = useAppSelector(getAuthModalOpen);

  const closeHandler = () => {
    dispatch(changeAuthModalOpen(false));
  };

  const getModal = () => {
    return step === "signIn" ? (
      <SignInModal />
    ) : step === "signIn_mail" ? (
      <SignInMailModal />
    ) : step === "signIn_password" ? (
      <SignInPasswordModal />
    ) : step === "register_password" ? (
      <RegisterModal />
    ) : null;
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={closeHandler}
        sx={{ display: { xs: "none", md: "block" }, position: "fixed", zIndex: 10 }}
      >
        {getModal()}
      </Modal>
      <FullscreenModal
        open={isModalOpen}
        onClose={closeHandler}
        sx={{ display: { xs: "block", md: "none" } }}
        marginTop={350}
      >
        {getModal()}
      </FullscreenModal>
    </>
  );
};
