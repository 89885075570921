import { FC, useState } from "react";
import { Box, Typography, Input as MUIInput, FormLabel } from "@mui/joy";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getUser } from "../../redux/auth/selectors";
import { Modal } from "../../ui/modal/modal";
import { FullscreenModal } from "../../ui/modal/fullscreen-modal";
import { StarIcon } from "../../ui/icons/star-icon";
import { ArrowIcon } from "../../ui/icons/arrow-icon";
import { Button } from "../../ui/button/button";
import { Input } from "../../ui/input/input";
import { FixedBottomBox } from "../../ui/modal/fixed-bottom-box";
import { ActionButton } from ".";
import { MasterType, MasterReview } from "../../types/types";
import { toastOptions } from "../../const";

import recordActions from "../../redux/record/record-slice";
import masterActions from "../../redux/master/master-slice";

const CutyApi = require("cuty_api");

const { changeMaster: changeMasterRecord, changeMasterLoading: changeMasterRecordLoading } =
  recordActions;
const { changeMaster, changeIsMasterLoading } = masterActions;

export type MasterReviewInputs = {
  score: number;
  comment: string | null;
};

type ReviewMasterProps = {
  master: MasterType;
  borderRadius?: string;
  padding?: object | string;
};

export const ReviewMaster: FC<ReviewMasterProps> = ({
  master,
  borderRadius = "14px",
  padding = "0 10px",
}) => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hoveredStar, setHoveredStar] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = useAppSelector(getUser);

  const clickHandler = () => setIsOpen(!isOpen);
  const closeModalOpen = () => setIsOpen(false);

  const { handleSubmit, control } = useForm<MasterReviewInputs>({
    defaultValues: {
      comment: null,
      score: 0,
    },
  });

  const submitHandler: SubmitHandler<MasterReviewInputs> = ({ score, comment }) => {
    setIsLoading(true);
    const apiInstance = new CutyApi.MasterApi();
    const masterId = master!.user.user_id;
    apiInstance.createMasterReview(
      masterId,
      {
        createMasterReviewRequest: { score: Number(score), comment, user_id: user?.user_id },
      },
      (error: any, data: MasterReview) => {
        if (error) {
          console.error(error);
          setIsLoading(false);
          toast.error(error.response.body.message, toastOptions);
        } else {
          setIsLoading(false);
          setIsOpen(false);
          dispatch(changeIsMasterLoading(true));
          dispatch(changeMasterRecordLoading(true));
          apiInstance.getMasterByFilter(
            { userId: master!.user.user_id },
            (error: any, data: MasterType) => {
              if (error) {
                console.error(error);
                dispatch(changeIsMasterLoading(false));
                dispatch(changeMasterRecordLoading(false));
              } else {
                dispatch(changeMaster(data));
                dispatch(changeMasterRecord(data));
                dispatch(changeIsMasterLoading(false));
                dispatch(changeMasterRecordLoading(false));
              }
            }
          );
        }
      }
    );
  };

  return (
    <Box>
      <ActionButton fullWidth onClick={clickHandler} sx={{ padding, borderRadius }}>
        <Typography startDecorator={<StarIcon color="#FFC107" width={24} height={24} />}>
          Оценить мастера
        </Typography>
        <Box sx={{ transform: "rotate(-90deg)" }}>
          <ArrowIcon />
        </Box>
      </ActionButton>

      <Modal open={isOpen} onClose={closeModalOpen} sx={{ display: { xs: "none", md: "block" } }}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Box
            sx={{
              minHeight: "350px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box marginBottom="20px">
                <Typography level="h3" fontWeight="bold" textAlign="center">
                  Оцените работу мастера
                </Typography>
                <Typography textColor="#939397" textAlign="center" fontWeight="bold">
                  {master?.user.display_name}
                </Typography>
              </Box>

              <Controller
                name="score"
                rules={{ validate: (_, { score }) => score > 0 }}
                control={control}
                render={({ field: { name, onChange, value }, fieldState: { invalid } }) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    marginBottom="35px"
                    position="relative"
                  >
                    {Array.from({ length: 5 }).map((_, idx) => (
                      <FormLabel
                        key={`${idx}-score`}
                        onMouseEnter={() => setHoveredStar(idx + 1)}
                        onMouseLeave={() => setHoveredStar(null)}
                        sx={{
                          transition: ".2s",
                          transform: hoveredStar === idx + 1 ? "scale(1.15)" : "scale(1)",
                          cursor: "pointer",
                        }}
                      >
                        <MUIInput
                          sx={{ display: "none" }}
                          type="radio"
                          name={name}
                          value={idx + 1}
                          onChange={onChange}
                        />
                        <StarIcon
                          color={idx + 1 <= value ? "#FFC107" : "#C0C0C0"}
                          width={50}
                          height={50}
                        />
                      </FormLabel>
                    ))}
                    {invalid && (
                      <Typography
                        fontSize="14px"
                        position="absolute"
                        bottom="-25px"
                        textColor="#c41c1c"
                      >
                        Нужно оценить
                      </Typography>
                    )}
                  </Box>
                )}
              />

              <Controller
                name="comment"
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <Input
                    name={name}
                    onChange={onChange}
                    value={value ? value : ""}
                    placeholder="Ваш комментарий"
                  />
                )}
              />
            </Box>

            <Button fullWidth type="submit" loading={isLoading}>
              Готово
            </Button>
          </Box>
        </form>
      </Modal>

      <FullscreenModal
        open={isOpen}
        onClose={closeModalOpen}
        sx={{ display: { xs: "block", md: "none" } }}
        marginTop={350}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Box marginY="10px">
            <Typography level="h3" fontWeight="bold" textAlign="center">
              Оцените работу мастера
            </Typography>
            <Typography textColor="#939397" textAlign="center" fontWeight="bold">
              {master?.user.display_name}
            </Typography>
          </Box>

          <Controller
            name="score"
            rules={{ validate: (_, { score }) => score > 0 }}
            control={control}
            render={({ field: { name, onChange, value }, fieldState: { invalid } }) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginY="5px"
                position="relative"
              >
                {Array.from({ length: 5 }).map((_, idx) => (
                  <FormLabel
                    key={`${idx}-score`}
                    onMouseEnter={() => setHoveredStar(idx + 1)}
                    onMouseLeave={() => setHoveredStar(null)}
                    sx={{
                      transition: ".2s",
                      transform: hoveredStar === idx + 1 ? "scale(1.15)" : "scale(1)",
                    }}
                  >
                    <MUIInput
                      sx={{ display: "none" }}
                      type="radio"
                      name={name}
                      value={idx + 1}
                      onChange={onChange}
                    />
                    <StarIcon
                      color={idx + 1 <= value ? "#FFC107" : "#C0C0C0"}
                      width={50}
                      height={50}
                    />
                  </FormLabel>
                ))}
                {invalid && (
                  <Typography
                    fontSize="14px"
                    position="absolute"
                    bottom="-25px"
                    textColor="#c41c1c"
                  >
                    Нужно оценить
                  </Typography>
                )}
              </Box>
            )}
          />

          {control._formValues.score > 0 && (
            <Controller
              name="comment"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <Input
                  name={name}
                  onChange={onChange}
                  value={value ? value : ""}
                  placeholder="Ваш комментарий"
                />
              )}
            />
          )}

          <FixedBottomBox>
            <Button fullWidth type="submit" loading={isLoading}>
              Готово
            </Button>
          </FixedBottomBox>
        </form>
      </FullscreenModal>
    </Box>
  );
};
