import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthStep, AuthStatus, User } from "../../types/types";

type AuthSliceState = {
  user: User | null;
  email: string;
  step: AuthStep;
  authStatus: AuthStatus;
  isLoading: boolean;
  isModalOpen: boolean;
  whoAmILoading: boolean;
};

const initialState: AuthSliceState = {
  user: null,
  email: "",
  step: "hidden",
  authStatus: "none",
  isLoading: true,
  isModalOpen: false,
  whoAmILoading: false,
};

export const authSlice = createSlice({
  name: "AUTH",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    changeEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    changeStep: (state, action: PayloadAction<AuthStep>) => {
      state.step = action.payload;
    },
    changeAuthStatus: (state, action: PayloadAction<AuthStatus>) => {
      state.authStatus = action.payload;
    },
    changeAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    changeAuthModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
    changeWhoAmILoading: (state, action: PayloadAction<boolean>) => {
      state.whoAmILoading = action.payload;
    },
  },
});

export default authSlice.actions;
