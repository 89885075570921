import { useEffect, MutableRefObject } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { State, AppDispatch } from "./types/types";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;

export const useOnOutsideClick = (
  ref: MutableRefObject<any>,
  callback: () => void
) => {
  useEffect(() => {
    const clickHandler = (e: { target: any }) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    document.addEventListener("click", clickHandler);

    return () => document.removeEventListener("click", clickHandler);
  }, [callback, ref]);
};
