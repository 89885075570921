import { FilterType, State } from "../../types/types";

export const getFilterCategory = (state: State): string => state.FILTER.filter.categoryId;
export const getFilterPrice = (state: State): { min: number; max: number } =>
  state.FILTER.filter.price;
export const getFilterHighRating = (state: State): boolean => state.FILTER.filter.highRating;
export const getFilterWithPhoto = (state: State): boolean => state.FILTER.filter.onlyWithPhoto;
export const getFilterDistance = (state: State): number | null => state.FILTER.filter.distance;
export const getTabValue = (state: State): string => state.FILTER.tabValue;

export const getFilter = (state: State): FilterType => state.FILTER.filter;

export const getSearchQuery = (state: State): string => state.FILTER.searchQuery;
