import { FC, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/joy";
import { useAppDispatch, useAppSelector } from "../hooks";
import { ExtraContainer } from "../components/container/container";
import { BackButton } from "../components/back-button/back-button";
import { InfoCard } from "../components/info-card/info-card";
import { InfoCardSkeleton } from "../components/info-card/skeleton";
import { MasterInfo } from "../components/master-info/master-info";
import { getProducts, getIsProductsLoading } from "../redux/master/selectors";
import { getOrderProducts, getChoosingProducts } from "../redux/order/selectors";
import { MasterType, ProductType } from "../types/types";

import masterActions from "../redux/master/master-slice";
import orderActions from "../redux/order/order-slice";

const CutyApi = require("cuty_api");

const { changeMaster, changeIsMasterLoading, changeProducts, changeIsProductsLoading } =
  masterActions;

const { addProduct, changeStep: changeOrderStep, changeOrderModalOpen, clearOrder } = orderActions;

export const MasterPage: FC = () => {
  const dispatch = useAppDispatch();
  const { param } = useParams();
  const navigate = useNavigate();

  const products = useAppSelector(getProducts);
  const isProductsLoading = useAppSelector(getIsProductsLoading);
  const orderProducts = useAppSelector(getOrderProducts);
  const choosingProducts = useAppSelector(getChoosingProducts);

  const backClickHandler = () => navigate(-1);

  const setIsModalOpen = (value: boolean) => dispatch(changeOrderModalOpen(value));

  const isUUID = (str: string) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
  };

  useEffect(() => {
    const masterInstance = new CutyApi.MasterApi();
    dispatch(changeIsMasterLoading(true));
    dispatch(changeIsProductsLoading(true));

    if (isUUID(param!)) {
      masterInstance.getMaster(param, (error: any, data: MasterType) => {
        if (error) {
          console.error(error);
          dispatch(changeIsMasterLoading(false));
        } else {
          dispatch(changeMaster(data));
          !choosingProducts && dispatch(clearOrder());
          dispatch(changeIsMasterLoading(false));
        }
      });

      masterInstance.getMasterProducts(param, (error: any, data: ProductType[]) => {
        if (error) {
          console.error(error);
          dispatch(changeIsProductsLoading(false));
        } else {
          dispatch(changeProducts(data));
          dispatch(changeIsProductsLoading(false));
        }
      });
    } else {
      masterInstance.getMasterByNickname(param, (error: any, data: MasterType) => {
        if (error) {
          console.error(error);
          dispatch(changeIsMasterLoading(false));
        } else {
          dispatch(changeMaster(data));
          !choosingProducts && dispatch(clearOrder());
          dispatch(changeIsMasterLoading(false));

          masterInstance.getMasterProducts(data.id, (error: any, data: ProductType[]) => {
            if (error) {
              console.error(error);
              dispatch(changeIsProductsLoading(false));
            } else {
              dispatch(changeProducts(data));
              dispatch(changeIsProductsLoading(false));
            }
          });
        }
      });
    }
  }, [dispatch, param]);

  const btnClickHandler = (product: ProductType) => {
    dispatch(addProduct(product));
    dispatch(changeOrderStep("sum"));
    setIsModalOpen(true);
  };

  return (
    <ExtraContainer>
      {/* <Box
        display="flex"
        alignItems="center"
        marginY="20px"
        sx={{ gap: { xs: "12px", md: "24px" } }}
      >
        <BackButton onClick={backClickHandler} />
        <Typography
          fontWeight="bold"
          sx={{ fontSize: { xs: "16px", sm: "20px", md: "24px" } }}
        >
          Выбор процедуры
        </Typography>
      </Box> */}
      <Grid container spacing={4} marginTop="0px">
        <Grid xs={12} md={7} order={{ xs: 2, md: 1 }}>
          {isProductsLoading
            ? Array.from({ length: 4 }).map((_, idx) => (
                <Box key={`${idx}-info`} marginBottom="8px">
                  <InfoCardSkeleton />
                </Box>
              ))
            : products.map((product) => (
                <Box key={product.id} marginBottom="8px">
                  <InfoCard
                    photo_url={product.photo_url}
                    title={product.title}
                    id={product.id}
                    price={product.amount}
                    currency={product.currency}
                    buttonAction={() => btnClickHandler(product)}
                  />
                </Box>
              ))}
        </Grid>
        {!choosingProducts && (
          <Grid xs={12} md={5} order={{ xs: 1, md: 2 }}>
            <MasterInfo />
          </Grid>
        )}
      </Grid>
    </ExtraContainer>
  );
};
