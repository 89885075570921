import { FC, ChangeEvent, Dispatch, SetStateAction } from "react";
import { Box, Typography } from "@mui/joy";
import { Switch } from "../../ui/switch/switch";
import { FilterType } from "../../types/types";

type FilterPhotoProps = {
  filter: FilterType;
  setFilter: Dispatch<SetStateAction<FilterType>>;
};

export const FilterPhoto: FC<FilterPhotoProps> = ({ filter, setFilter }) => {
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setFilter({ ...filter, onlyWithPhoto: e.target.checked });

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography level="h4">Только с фото</Typography>
      <Switch id="onlyWithPhoto" checked={filter.onlyWithPhoto} onChange={changeHandler} />
    </Box>
  );
};
