import { ReactNode, FC, useEffect, useState } from "react";
import { getIdToken, onAuthStateChanged } from "firebase/auth";
import { auth } from "../services/firebase";
import { FullscreenSpinner } from "../components/fullscreen-spinner/fullscreen-spinner";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getIsAuthLoading } from "../redux/auth/selectors";
import authActions from "../redux/auth/auth-slice";
import { User } from "../types/types";

const CutyApi = require("cuty_api");
CutyApi.ApiClient.instance.basePath = process.env.REACT_APP_BASE_URL;
CutyApi.ApiClient.instance.defaultHeaders = {};

type AuthMiddlewareProps = {
  children: ReactNode | ReactNode[];
};

const { setUser, changeAuthStatus, changeAuthLoading, changeWhoAmILoading } = authActions;

export const AuthMiddleware: FC<AuthMiddlewareProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [token, setToken] = useState<string | null>(null);

  const isAuthLoading = useAppSelector(getIsAuthLoading);

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const token = await getIdToken(user);
      setToken(token);
      CutyApi.ApiClient.instance.authentications["bearerAuth"].accessToken = token;
      dispatch(changeAuthStatus("authorized"));
    } else {
      dispatch(changeAuthStatus("unauthorized"));
      dispatch(changeAuthLoading(false));
    }
  });

  useEffect(() => {
    if (token) {
      const apiInstance = new CutyApi.AuthApi();
      dispatch(changeWhoAmILoading(true));
      apiInstance.whoAmI((error: any, data: User) => {
        if (error) {
          console.error(error);
          dispatch(changeWhoAmILoading(false));
          dispatch(changeAuthLoading(false));
        } else {
          dispatch(setUser(data));
          dispatch(changeWhoAmILoading(false));
          dispatch(changeAuthLoading(false));
        }
      });
    }
  }, [dispatch, token]);

  if (isAuthLoading) {
    return <FullscreenSpinner />;
  }

  return <>{children}</>;
};
