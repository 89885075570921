import { FC, ReactNode } from "react";
import { Box } from "@mui/joy";

type FixedBottomBoxProps = {
  children: ReactNode;
};

export const FixedBottomBox: FC<FixedBottomBoxProps> = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      gap: "5px",
      marginTop: "20px",
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "calc(100% - 32px)",
      zIndex: 100,
      borderTop: "1px solid #D9D9D9",
      padding: "8px 16px",
      background: "#FFF",
    }}
  >
    {children}
  </Box>
);
