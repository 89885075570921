import ReactDOM from "react-dom/client";
import { HashRouter, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { CssVarsProvider } from "@mui/joy";
import { AuthMiddleware } from "./middleware/auth-middleware";
import App from "./App";
import { store } from "./redux/store";

import "./index.css";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <CssVarsProvider>
        <AuthMiddleware>
          <App />
          <ToastContainer />
        </AuthMiddleware>
      </CssVarsProvider>
    </Provider>
  </BrowserRouter>
);
