import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TabValue, FilterType } from "../../types/types";

type FilterSliceState = {
  filter: FilterType;
  tabValue: TabValue;
  searchQuery: string;
};

const initialState: FilterSliceState = {
  filter: {
    categoryId: "",
    price: { min: 0, max: 0 },
    highRating: false,
    onlyWithPhoto: false,
    distance: null,
  },
  searchQuery: "",
  tabValue: "products",
};

export const filterSlice = createSlice({
  name: "FILTER",
  initialState,
  reducers: {
    changeFilter: (state, action: PayloadAction<FilterType>) => {
      state.filter = action.payload;
    },
    changeTabValue: (state, action: PayloadAction<TabValue>) => {
      state.tabValue = action.payload;
    },
    changeFilterCategory: (state, action: PayloadAction<string>) => {
      state.filter.categoryId = action.payload;
    },
    changeSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    clearFilter: (state) => {
      state.filter = {
        categoryId: "",
        price: { min: 0, max: 0 },
        highRating: false,
        onlyWithPhoto: false,
        distance: null,
      };
    },
  },
});

export default filterSlice.actions;
