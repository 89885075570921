import { OrderStep, ProductType, State } from "../../types/types";

export const getOrderTime = (state: State): string => state.ORDER.time;

export const getOrderProducts = (state: State): ProductType[] => state.ORDER.products;

export const getOrderTotalPrice = (state: State): number => state.ORDER.totalPrice;

export const getOrderStep = (state: State): OrderStep => state.ORDER.step;

export const getTotalMinutesDuration = (state: State): number => state.ORDER.totalMinutesDuration;

export const getOrderModalOpen = (state: State) => state.ORDER.isModalOpen;

export const getOrderLoading = (state: State): boolean => state.ORDER.isLoading;

export const getChoosingProducts = (state: State): boolean => state.ORDER.choosingProducts;

export const getCreatedId = (state: State): string => state.ORDER.createdId;
