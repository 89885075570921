import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/joy";
import InfiniteScroll from "react-infinite-scroller";
import { InfoCard } from "../info-card/info-card";
import { useAppSelector, useAppDispatch } from "../../hooks";
import {
  getMasters,
  getMastersLoading,
  getMastersHasMore,
  getMastersNextPage,
} from "../../redux/data/selectors";
import { getSearchQuery } from "../../redux/filter/selectors";
import { getFilter } from "../../redux/filter/selectors";
import { InfoCardSkeleton } from "../info-card/skeleton";
import { MasterType } from "../../types/types";

import dataActions from "../../redux/data/data-slice";

const CutyApi = require("cuty_api");

const {
  changeMastersError,
  setMasters,
  changeMastersHasMore,
  changeMastersNextPage,
  changeMastersLoading,
} = dataActions;

export const MastersTab: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const masters = useAppSelector(getMasters);
  const isLoading = useAppSelector(getMastersLoading);
  const hasMore = useAppSelector(getMastersHasMore);
  const nextPage = useAppSelector(getMastersNextPage);
  const { categoryId, distance, onlyWithPhoto, highRating } = useAppSelector(getFilter);
  const searchQuery = useAppSelector(getSearchQuery);

  const loadMore = () => {
    const masterInstance = new CutyApi.MasterApi();
    masterInstance.listMasters(
      {
        limit: 10,
        page: nextPage,
        onlineRecordingAllowed: true,
        categoryId,
        distance,
        onlyWithPhoto,
        ratingMin: highRating ? 4 : null,
        searchQuery,
      },
      (error: string, data: MasterType[]) => {
        if (error) {
          dispatch(changeMastersError(error));
        } else {
          if (data.length > 0) {
            dispatch(setMasters([...masters, ...data]));
            dispatch(changeMastersNextPage(nextPage + 1));
            data.length < 10 && dispatch(changeMastersHasMore(false));
          } else {
            dispatch(changeMastersHasMore(false));
          }
        }
      }
    );
  };

  useEffect(() => {
    const masterInstance = new CutyApi.MasterApi();
    dispatch(changeMastersLoading(true));
    masterInstance.listMasters(
      {
        limit: 10,
        page: 1,
        onlineRecordingAllowed: true,
        categoryId,
        distance,
        onlyWithPhoto,
        ratingMin: highRating ? 4 : null,
        searchQuery,
      },
      (error: string, data: MasterType[]) => {
        if (error) {
          dispatch(changeMastersError(error));
          dispatch(changeMastersLoading(false));
        } else {
          dispatch(setMasters(data));
          dispatch(changeMastersLoading(false));
          dispatch(changeMastersNextPage(2));
          data.length < 10
            ? dispatch(changeMastersHasMore(false))
            : dispatch(changeMastersHasMore(true));
        }
      }
    );
  }, [categoryId, dispatch, distance, highRating, onlyWithPhoto, searchQuery]);

  return (
    <InfiniteScroll
      loadMore={loadMore}
      loader={
        <Grid container spacing={1} marginTop={0.5} key="loader">
          <Grid xs={12} lg={6}>
            <InfoCardSkeleton pictureLgBreakpoint={5} />
          </Grid>
          <Grid lg={6} display={{ xs: "none", lg: "block" }}>
            <InfoCardSkeleton pictureLgBreakpoint={5} />
          </Grid>
        </Grid>
      }
      hasMore={!isLoading && masters.length > 3 && hasMore}
      threshold={100}
    >
      <Grid container spacing={1}>
        {isLoading ? (
          Array.from({ length: 4 }).map((_, idx) => (
            <Grid key={`${idx}-s-cm-skeleton`} xs={12} lg={6}>
              <InfoCardSkeleton pictureLgBreakpoint={5} />
            </Grid>
          ))
        ) : masters.length === 0 ? (
          <Grid xs={12}>
            <Typography level="h4">Ничего не найдено :(</Typography>
          </Grid>
        ) : (
          masters.map((master, idx) => (
            <Grid key={`${master.id}-${idx}`} xs={12} lg={6}>
              <InfoCard
                id={master.id}
                photo_url={master.user.photo_url}
                title={master.user.display_name}
                reviews_count={master.reviews_count}
                reviews_score={master.reviews_score}
                buttonAction={() => navigate(`/master/${master.nickname}`)}
                pictureLgBreakpoint={5}
              />
            </Grid>
          ))
        )}
      </Grid>
    </InfiniteScroll>
  );
};
