import { FC, ReactNode } from "react";
import {
  Modal as MUIModal,
  ModalDialog,
  ModalClose,
  ModalOverflow,
  modalCloseClasses,
} from "@mui/joy";
import { CSSTransition } from "react-transition-group";
import { SxProps } from "@mui/joy/styles/types";

import "./modal.css";

type ModalProps = {
  onClose: () => void;
  open: boolean;
  children: ReactNode[] | ReactNode;
  padding?: string;
  width?: string;
  sx?: SxProps;
};

export const Modal: FC<ModalProps> = ({
  open,
  onClose,
  children,
  padding = "100px 70px",
  width = "593px",
  sx,
}) => (
  <CSSTransition in={open} timeout={200} classNames="modal">
    {(state) => (
      <MUIModal
        className="modal"
        keepMounted
        open={!["exited", "exiting"].includes(state)}
        onClose={onClose}
        sx={{
          visibility: state === "exited" ? "hidden" : "visible",
          ...sx,
        }}
      >
        <ModalOverflow sx={{ height: "95%" }}>
          <ModalDialog
            layout="center"
            sx={{
              padding,
              borderRadius: "40px",
              width,
              background: "#fafafa",
              /* opacity: 0,
              transition: `opacity 300ms`,
              ...transitionStyles[state], */
            }}
          >
            <ModalClose
              variant="solid"
              sx={{
                borderRadius: "50%",
                background: "#bcbdbf",
                [`&&.${modalCloseClasses.root}`]: {
                  top: "27px",
                  right: "27px",
                },
              }}
            />
            {children}
          </ModalDialog>
        </ModalOverflow>
      </MUIModal>
    )}
  </CSSTransition>
);
