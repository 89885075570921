import { FC, ReactNode } from "react";
import {
  Tabs as MUITabs,
  tabPanelClasses,
  TabsProps as MUITabsProps,
} from "@mui/joy";

type TabsProps = MUITabsProps & {
  children: ReactNode[] | ReactNode;
};

export const Tabs: FC<TabsProps> = ({ children, ...props }) => (
  <MUITabs
    {...props}
    sx={{
      marginTop: "10px",
      background: "transparent",
      [`&& .${tabPanelClasses.root}`]: {
        padding: "10px 0",
      },
    }}
  >
    {children}
  </MUITabs>
);
