import { FC, useEffect } from "react";
import { Typography, Box } from "@mui/joy";
import { useAppDispatch } from "../../hooks";
import { SearchCategories } from "../search-categories/search-categories";
import { CategoryType } from "../../types/types";
import dataActions from "../../redux/data/data-slice";
import filterActions from "../../redux/filter/filter-slice";

const CutyApi = require("cuty_api");

const { setCategories, changeCategoriesError, changeCategoriesLoading } = dataActions;
const { clearFilter } = filterActions;

export const Categories: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearFilter());
    dispatch(changeCategoriesLoading(true));
    const apiInstance = new CutyApi.ProductApi();
    apiInstance.listProductCategories((error: any, data: CategoryType[]) => {
      if (error) {
        console.error(error);
        dispatch(changeCategoriesError(error));
        dispatch(changeCategoriesLoading(false));
      } else {
        dispatch(setCategories(data));
        dispatch(changeCategoriesLoading(false));
      }
    });
  }, [dispatch]);

  return (
    <Box sx={{ marginTop: "15px" }}>
      <Typography
        fontSize={{ xs: "16px", sm: "20px", md: "24px" }}
        fontWeight="bold"
        marginBottom="15px"
      >
        Категории
      </Typography>

      <SearchCategories />
    </Box>
  );
};
