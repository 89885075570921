import { FC } from "react";
import { Card, CardContent, Typography, styled } from "@mui/joy";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { Picture } from "../../ui/picture/picture";
import { getFilterCategory } from "../../redux/filter/selectors";
import { CategoryType } from "../../types/types";

import filterActions from "../../redux/filter/filter-slice";

export const SearchCategoryCard = styled(Card)({
  background: "none",
  border: "none",
  padding: "3px",
  maxWidth: "70px",
  cursor: "pointer",
});

const { changeFilterCategory } = filterActions;

type SearchCategoryProps = {
  category: CategoryType;
};

export const SearchCategory: FC<SearchCategoryProps> = ({ category }) => {
  const dispatch = useAppDispatch();
  const activeCategory = useAppSelector(getFilterCategory);

  const clickHandler = () => {
    if (activeCategory === category.id) {
      dispatch(changeFilterCategory(""));
    } else {
      dispatch(changeFilterCategory(category.id));
    }
  };

  return (
    <SearchCategoryCard onClick={clickHandler} sx={{ alignItems: "center" }}>
      <Picture
        src={category.icon}
        sx={{
          width: { xs: "46px", md: "70px" },
          borderRadius: "50%",
          transition: ".2s",
          boxShadow: activeCategory === category.id ? "0 0 0 3px #962BED" : "",
        }}
      />
      <CardContent sx={{ textAlign: "center" }}>
        <Typography
          sx={{ fontSize: { xs: "12px", md: "14px" } }}
          fontWeight="bold"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {category.title}
        </Typography>
      </CardContent>
    </SearchCategoryCard>
  );
};
