import { State, CategoryType, ProductType, MasterType } from "../../types/types";

export const getCategories = (state: State): CategoryType[] => state.DATA.categories.data;
export const getCategoriesLoading = (state: State): boolean => state.DATA.categories.isLoading;
export const getCategoriesError = (state: State): string | null => state.DATA.categories.error;
export const getActiveCategory = (state: State): string | null => state.DATA.categories.active;

export const getProducts = (state: State): ProductType[] => state.DATA.products.data;
export const getProductsLoading = (state: State): boolean => state.DATA.products.isLoading;
export const getProductsError = (state: State): string | null => state.DATA.products.error;
export const getProductsHasMore = (state: State): boolean => state.DATA.products.hasMore;
export const getProductsNextPage = (state: State): number => state.DATA.products.nextPage;

export const getMasters = (state: State): MasterType[] => state.DATA.masters.data;
export const getMastersLoading = (state: State): boolean => state.DATA.masters.isLoading;
export const getMastersError = (state: State): string | null => state.DATA.masters.error;
export const getMastersHasMore = (state: State): boolean => state.DATA.masters.hasMore;
export const getMastersNextPage = (state: State): number => state.DATA.masters.nextPage;
