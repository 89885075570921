import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@mui/joy";
import { auth } from "../services/firebase";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getUser } from "../redux/auth/selectors";
import { getRecords, getRecordsLoading } from "../redux/records/selector";
import { getIsAuthLoading } from "../redux/auth/selectors";
import { ExtraContainer } from "../components/container/container";
import { BackButton } from "../components/back-button/back-button";
import { Record } from "../components/record/record";
import { Input } from "../ui/input/input";
import { SearchIcon } from "../ui/icons/search-icon";
import { ScheduleRecordType } from "../types/types";
import recordsActions from "../redux/records/records-slice";

const CutyApi = require("cuty_api");

const { changeRecords, changeRecordsLoading } = recordsActions;

export const RecordsPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [query, setQuery] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useState<string>("");

  const user = useAppSelector(getUser);
  const records = useAppSelector(getRecords);
  const isLoading = useAppSelector(getRecordsLoading);
  const isAuthLoading = useAppSelector(getIsAuthLoading);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const backClickHandler = () => navigate(-1);

  const filteredRecords = records.filter((item) => {
    if (debouncedValue === "") {
      return item;
    } else if (
      item.products.filter((product) =>
        product.title.toLowerCase().trim().includes(debouncedValue.toLowerCase().trim())
      ).length > 0
    ) {
      return item;
    } else return null;
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(query);
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [query]);

  useEffect(() => {
    if (user) {
      dispatch(changeRecordsLoading(true));
      auth.currentUser
        ?.getIdToken(true)
        .then((token) => {
          CutyApi.ApiClient.instance.authentications["bearerAuth"].accessToken = token;
          const apiInstance = new CutyApi.ScheduleRecordApi();
          apiInstance.listClientScheduleRecords((error: any, data: ScheduleRecordType[]) => {
            if (error) {
              console.error(error);
              dispatch(changeRecordsLoading(false));
            } else {
              dispatch(changeRecords(data));
              dispatch(changeRecordsLoading(false));
            }
          });
        })
        .catch((error) => console.error(error));
    }
  }, [dispatch, user]);

  return (
    <ExtraContainer>
      <Box
        display={{ xs: "block", md: "flex" }}
        gap={{ xs: "16px", md: "32px" }}
        alignItems="center"
        marginY="20px"
      >
        <Box display="flex" gap={{ xs: "16px", md: "32px" }} alignItems="center">
          <BackButton onClick={backClickHandler} />
          <Typography fontSize={{ xs: "16px", sm: "20px", md: "24px" }} fontWeight="bold">
            Мои записи
          </Typography>
        </Box>
        {!isLoading && records.length > 0 && (
          <Box minWidth={{ xs: "none", md: "400px" }} marginTop={{ xs: "10px", md: "0" }}>
            <Input
              value={query}
              onChange={onChangeHandler}
              placeholder="Искать запись"
              marginY={0}
              startDecorator={<SearchIcon width={22} />}
              background="#F1F1F1"
              padding="8px 10px"
            />
          </Box>
        )}
      </Box>
      {isLoading || isAuthLoading ? (
        <Box display="flex" justifyContent="center" marginTop="40px">
          <CircularProgress variant="plain" color="neutral" size="lg" />
        </Box>
      ) : records.length > 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          marginBottom={{ xs: "16px", sm: "24px" }}
        >
          {filteredRecords.length === 0 ? (
            <Typography fontSize={{ xs: "16px", md: "20px" }} fontWeight="bold">
              Не найдено
            </Typography>
          ) : (
            filteredRecords.map((record) => <Record key={record.id} record={record} />)
          )}
        </Box>
      ) : (
        <Box marginY="20px">
          <Typography fontSize={{ xs: "16px", md: "20px" }} fontWeight="bold">
            У Вас нет записей
          </Typography>
        </Box>
      )}
    </ExtraContainer>
  );
};
