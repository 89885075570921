import { FC, ChangeEvent } from "react";
import { RadioGroup, Typography } from "@mui/joy";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { Radio } from "../../ui/radio/radio";
import { formatDate } from "../../utils/format-date";
import { getOrderTime } from "../../redux/order/selectors";
import orderActions from "../../redux/order/order-slice";

type ChooseTimeProps = {
  header: string;
  values: Date[];
};

const { changeTime } = orderActions;

export const ChooseTime: FC<ChooseTimeProps> = ({ values, header }) => {
  const dispatch = useAppDispatch();
  const orderTime = useAppSelector(getOrderTime);

  const changeTimeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(changeTime(e.target.value));
  };

  return (
    <RadioGroup
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "12px",
        border: "1px solid #ddd",
        borderRadius: "20px",
        minWidth: { xs: "none", md: "425px" },
        maxWidth: { xs: "none", md: "655px" },
        minHeight: { xs: "none", md: "50px" },
        padding: { xs: "20px 12px 15px", md: "45px 20px 25px" },
        position: "relative",
      }}
      onChange={changeTimeHandler}
      value={orderTime}
    >
      <Typography
        fontSize={{ xs: "14px", md: "24px" }}
        border="1px solid #ddd"
        borderRadius="13px"
        padding="5px 15px"
        bgcolor="#fbfcfe"
        sx={{ position: "absolute", top: "-24px" }}
      >
        {header}
      </Typography>
      {values.map((e) => (
        <Radio
          borderRadius="12px"
          sx={{
            background: "#fff",
            boxShadow: "0 2px 8px #ddd",
            padding: { xs: "8px 16px", md: "22px 16px" },
            fontSize: { xs: "14px", md: "24px" },
            fontWeight: { xs: "600", md: "700" },
          }}
          key={e.toISOString()}
          label={formatDate(e.toISOString(), {
            hour: "2-digit",
            minute: "2-digit",
          })}
          value={e.toISOString()}
        />
      ))}
    </RadioGroup>
  );
};
