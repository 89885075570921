import { FC, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, CircularProgress, Grid, Typography, SvgIcon, Divider, styled } from "@mui/joy";
import { ExtraContainer } from "../components/container/container";
import { BackButton } from "../components/back-button/back-button";
import { ReviewMaster, ToGoogleMaps, AddToCalendar } from "../components/record-actions";
import { ReviewsCount } from "../components/reviews-count/reviews-count";
import { CancelRecord } from "../components/cancel-record/cancel-record";
import { Picture } from "../ui/picture/picture";
import { StatusLabel } from "../ui/status-label/status-label";
import { ArrowIcon } from "../ui/icons/arrow-icon";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  getRecord,
  getRecordLoading,
  getMaster,
  getMasterLoading,
} from "../redux/record/selectors";
import { getAuthStatus } from "../redux/auth/selectors";
import { ScheduleRecordType, MasterType } from "../types/types";
import { formatPrice } from "../utils/format-price";
import { formatDate } from "../utils/format-date";
import { getHoursRightDeclension } from "../utils/get-right-declension";
import { getMinutesRightDeclension } from "../utils/get-right-declension";
import recordActions from "../redux/record/record-slice";

import { ReactComponent as Calendar } from "../assets/calendar.svg";
import { ReactComponent as Location } from "../assets/location.svg";

const CutyApi = require("cuty_api");

const { changeRecord, changeRecordLoading, changeMaster, changeMasterLoading } = recordActions;

const BoxItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "14px",
  padding: "10px",
  boxShadow: "0 2px 7px #0001",
  borderRadius: "16px",
  backgroundColor: "#fff",
});

export const RecordPage: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const record = useAppSelector(getRecord);
  const isRecordLoading = useAppSelector(getRecordLoading);
  const master = useAppSelector(getMaster);
  const isMasterLoading = useAppSelector(getMasterLoading);
  const authStatus = useAppSelector(getAuthStatus);

  const isLoading = isRecordLoading || isMasterLoading;
  const data = record && master;

  const backClickHandler = () => navigate(-1);

  const getHoursDuration = (start_time: Date | null, end_time: Date): string => {
    const start = start_time ? start_time.getUTCHours() : 0;
    const hours = end_time.getUTCHours() - start;
    return hours > 0 ? `${hours} ${getHoursRightDeclension(hours)}` : "";
  };

  const getMinutesDuration = (start_time: Date | null, end_time: Date): string => {
    const start = start_time ? start_time.getMinutes() : 0;
    const minutes = end_time.getMinutes() - start;
    return minutes > 0 ? `${minutes} ${getMinutesRightDeclension(minutes)}` : "";
  };

  useEffect(() => {
    const apiInstance = new CutyApi.ScheduleRecordApi();
    dispatch(changeRecordLoading(true));
    apiInstance.getScheduleRecord(id, (error: any, data: ScheduleRecordType) => {
      if (error) {
        console.error(error);
        dispatch(changeRecordLoading(false));
      } else {
        dispatch(changeRecord(data));

        const masterInstance = new CutyApi.MasterApi();
        dispatch(changeMasterLoading(true));
        masterInstance.getMasterByFilter(
          { userId: data.owner.user_id },
          (error: any, data: MasterType) => {
            if (error) {
              console.error(error);
              dispatch(changeMasterLoading(false));
            } else {
              dispatch(changeMaster(data));
              dispatch(changeMasterLoading(false));
              dispatch(changeRecordLoading(false));
            }
          }
        );
      }
    });
  }, [dispatch, id]);

  return (
    <ExtraContainer>
      <Grid container spacing={1} marginY="16px">
        <Grid xs={12} display="flex" gap={{ xs: "16px", md: "32px" }} alignItems="center">
          <BackButton onClick={backClickHandler} />
          <Typography fontSize={{ xs: "16px", sm: "20px", md: "24px" }} fontWeight="bold">
            Запись
          </Typography>
          {!isLoading && record && <StatusLabel status={record.status} />}
        </Grid>
        {isLoading ? (
          <Box display="flex" justifyContent="center" marginTop="40px" width="100%">
            <CircularProgress variant="plain" color="neutral" size="lg" />
          </Box>
        ) : !data ? (
          <Box display="flex" justifyContent="center" marginTop="40px">
            Ошибка
          </Box>
        ) : (
          <>
            <Grid xs={12} marginTop="10px">
              <BoxItem
                onClick={() => navigate(`/master/${master!.nickname}`)}
                sx={{
                  justifyContent: "space-between",
                  cursor: "pointer",
                  transition: "0.2s",
                  ":hover": {
                    boxShadow: "0 1px 10px 1px #ddd",
                  },
                  ":active": {
                    background: "rgba(0,0,0,0.03)",
                  },
                }}
              >
                {!master ? (
                  <Box display="flex" justifyContent="center">
                    <Typography level="title-lg">Мастер не найден :(</Typography>
                  </Box>
                ) : (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "14px" }}>
                      <Picture
                        src={master.user.photo_url}
                        sx={{
                          minWidth: "60px",
                          minHeight: "60px",
                          borderRadius: "10px",
                        }}
                      />
                      <Box>
                        <Typography
                          fontSize={{ xs: "14px", sm: "16px", md: "17px" }}
                          fontWeight="bold"
                        >
                          {master.user.display_name}
                        </Typography>
                        {master.reviews_score && (
                          <ReviewsCount count={master.reviews_count} score={master.reviews_score} />
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ transform: "rotate(-90deg)" }}>
                      <ArrowIcon width={26} height={26} />
                    </Box>
                  </>
                )}
              </BoxItem>
            </Grid>

            <Grid xs={12}>
              <BoxItem sx={{ flexDirection: "column", alignItems: "flex-start" }}>
                {record.products.map((product) => (
                  <Box key={product.id} display="flex" gap="14px" alignItems="center">
                    <Picture
                      src={product.photo_url}
                      sx={{
                        minWidth: "60px",
                        minHeight: "60px",
                        borderRadius: "10px",
                      }}
                    />
                    <Box>
                      <Typography
                        fontSize={{ xs: "14px", sm: "16px", md: "17px" }}
                        fontWeight="bold"
                      >
                        {product.title}
                      </Typography>
                      <Typography fontSize={{ xs: "14px", sm: "16px", md: "17px" }}>
                        {`${formatPrice(product.amount, product.currency)} / ${getHoursDuration(
                          null,
                          product.duration
                        )} ${getMinutesDuration(null, product.duration)}`}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </BoxItem>
            </Grid>

            <Grid xs={12}>
              <BoxItem>
                <Box
                  width={60}
                  height={60}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <SvgIcon component={Calendar} size="lg" />
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography fontSize={{ xs: "14px", sm: "16px", md: "17px" }} fontWeight="bold">
                    {formatDate(record.start_day.toISOString(), {
                      day: "2-digit",
                      month: "long",
                      weekday: "long",
                    })}
                  </Typography>
                  <Typography fontSize={{ xs: "14px", sm: "16px", md: "17px" }}>
                    {`${formatDate(record.start_time.toISOString(), {
                      hour: "2-digit",
                      minute: "2-digit",
                    })} - ${formatDate(record.end_time.toISOString(), {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}, ${getHoursDuration(
                      record.start_time,
                      record.end_time
                    )} ${getMinutesDuration(record.start_time, record.end_time)}`}
                  </Typography>
                </Box>
              </BoxItem>
            </Grid>

            {master.location && (
              <Grid xs={12}>
                <BoxItem>
                  <Box
                    width={60}
                    height={60}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <SvgIcon component={Location} size="lg" viewBox="0 0 16 16" />
                  </Box>
                  <Typography fontSize={{ xs: "14px", sm: "16px", md: "17px" }} fontWeight="bold">
                    {`${master.location.street}, ${master.location.house}, ${master.location.apartment}`}
                  </Typography>
                </BoxItem>
              </Grid>
            )}

            <Grid xs={12}>
              <BoxItem sx={{ justifyContent: "space-between", paddingX: "20px" }}>
                <Typography fontSize={{ sm: "16px", md: "17px" }} fontWeight="bold">
                  Итого
                </Typography>
                <Typography>{formatPrice(record.total_price, record.currency.code)}</Typography>
              </BoxItem>
            </Grid>

            <Grid xs={12} marginTop={2}>
              <BoxItem sx={{ display: "block" }}>
                <AddToCalendar />
                <Divider sx={{ marginY: "5px" }} />

                <ReviewMaster master={master} />

                {master.location && (
                  <>
                    <Divider sx={{ marginY: "5px" }} />
                    <ToGoogleMaps />
                  </>
                )}
              </BoxItem>
            </Grid>

            {record.status !== "cancelled" && authStatus === "authorized" && (
              <Grid xs={12} marginTop={2}>
                <CancelRecord id={record.id} />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </ExtraContainer>
  );
};
