import { FC } from "react";
import { Grid } from "@mui/joy";
import { Categories } from "../components/categories/categories";
import { Products } from "../components/products/products";
import { Masters } from "../components/masters/masters";
import { ExtraContainer } from "../components/container/container";

export const MainPage: FC = () => {
  return (
    <ExtraContainer>
      <Grid container>
        <Grid xs={12}>
          <Categories />
        </Grid>
        <Grid xs={12}>
          <Products />
        </Grid>
        <Grid xs={12}>
          <Masters />
        </Grid>
      </Grid>
    </ExtraContainer>
  );
};
