import { FC } from "react";
import { Box, CircularProgress, SvgIcon } from "@mui/joy";
import { ReactComponent as Logo } from "../../assets/logo.svg";

export const FullscreenSpinner: FC = () => (
  <Box
    sx={{
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      bgcolor: "#f0f4f8",
      gap: "5px",
    }}
  >
    <SvgIcon component={Logo} sx={{ width: "100px", height: "100px" }} viewBox="0 0 66 66" />
    <CircularProgress variant="plain" color="neutral" />
  </Box>
);
