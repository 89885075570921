import { FC, useEffect } from "react";
import { Grid, Typography } from "@mui/joy";
import InfiniteScroll from "react-infinite-scroller";
import { useAppSelector, useAppDispatch } from "../../hooks";
import {
  getProducts,
  getProductsLoading,
  getProductsHasMore,
  getProductsNextPage,
} from "../../redux/data/selectors";
import { getFilter, getSearchQuery } from "../../redux/filter/selectors";
import { getOrderProducts } from "../../redux/order/selectors";
import { InfoCard } from "../info-card/info-card";
import { InfoCardSkeleton } from "../info-card/skeleton";
import { MasterType, ProductType } from "../../types/types";

import dataActions from "../../redux/data/data-slice";
import orderActions from "../../redux/order/order-slice";
import masterActions from "../../redux/master/master-slice";

const CutyApi = require("cuty_api");

const {
  changeProductsError,
  setProducts,
  changeProductsHasMore,
  changeProductsNextPage,
  changeProductsLoading,
} = dataActions;

const { changeOrderModalOpen, changeStep: changeOrderStep, addProduct, clearOrder } = orderActions;

const { changeIsMasterLoading, changeMaster } = masterActions;

export const ProductsTab: FC = () => {
  const dispatch = useAppDispatch();

  const products = useAppSelector(getProducts);
  const isLoading = useAppSelector(getProductsLoading);
  const hasMore = useAppSelector(getProductsHasMore);
  const nextPage = useAppSelector(getProductsNextPage);
  const orderProducts = useAppSelector(getOrderProducts);
  const {
    categoryId,
    price: { max, min },
    onlyWithPhoto,
  } = useAppSelector(getFilter);
  const searchQuery = useAppSelector(getSearchQuery);

  const orderClickHandler = (product: ProductType) => {
    dispatch(changeOrderStep("sum"));
    if (orderProducts.length > 0 && orderProducts[0].user_id !== product.user_id) {
      dispatch(clearOrder());
    }
    dispatch(addProduct(product));
    dispatch(changeOrderModalOpen(true));

    const apiInstance = new CutyApi.MasterApi();
    dispatch(changeIsMasterLoading(true));
    apiInstance.getMasterByFilter({ userId: product.user_id }, (error: any, data: MasterType) => {
      if (error) {
        console.error(error);
        dispatch(changeIsMasterLoading(false));
      } else {
        dispatch(changeIsMasterLoading(false));
        dispatch(changeMaster(data));
      }
    });
  };

  const loadMore = () => {
    const productInstance = new CutyApi.ProductApi();
    productInstance.listProducts(
      {
        limit: 10,
        page: nextPage,
        categoryId,
        amountMin: min,
        amountMax: max === 0 ? null : max,
        onlyWithPhoto,
        searchQuery,
      },
      (error: string, data: ProductType[]) => {
        if (error) {
          dispatch(changeProductsError(error));
        } else {
          if (data.length > 0) {
            dispatch(setProducts([...products, ...data]));
            dispatch(changeProductsNextPage(nextPage + 1));
            data.length < 10 && dispatch(changeProductsHasMore(false));
          } else {
            dispatch(changeProductsHasMore(false));
          }
        }
      }
    );
  };

  useEffect(() => {
    dispatch(changeProductsLoading(true));
    const productInstance = new CutyApi.ProductApi();
    productInstance.listProducts(
      {
        limit: 10,
        page: 1,
        categoryId,
        amountMin: min,
        amountMax: max === 0 ? null : max,
        onlyWithPhoto,
        searchQuery,
      },
      (error: string, data: ProductType[]) => {
        if (error) {
          dispatch(changeProductsLoading(false));
          dispatch(changeProductsError(error));
        } else {
          dispatch(changeProductsLoading(false));
          dispatch(setProducts(data));
          dispatch(changeProductsNextPage(2));
          data.length < 10
            ? dispatch(changeProductsHasMore(false))
            : dispatch(changeProductsHasMore(true));
        }
      }
    );
  }, [categoryId, dispatch, max, min, onlyWithPhoto, searchQuery]);

  return (
    <InfiniteScroll
      loadMore={loadMore}
      loader={
        <Grid container spacing={1} marginTop={0.5} key="loader">
          <Grid xs={12} lg={6}>
            <InfoCardSkeleton pictureLgBreakpoint={5} />
          </Grid>
          <Grid lg={6} display={{ xs: "none", lg: "block" }}>
            <InfoCardSkeleton pictureLgBreakpoint={5} />
          </Grid>
        </Grid>
      }
      pageStart={1}
      hasMore={!isLoading && products.length > 3 && hasMore}
      threshold={100}
    >
      <Grid container spacing={1}>
        {isLoading ? (
          Array.from({ length: 4 }).map((_, idx) => (
            <Grid key={`${idx}-s-cp-skeleton`} xs={12} lg={6}>
              <InfoCardSkeleton pictureLgBreakpoint={5} />
            </Grid>
          ))
        ) : products.length === 0 ? (
          <Grid xs={12}>
            <Typography level="h4">Ничего не найдено :(</Typography>
          </Grid>
        ) : (
          products.map((product, idx) => (
            <Grid xs={12} lg={6} key={`${product.id}-${idx}`}>
              <InfoCard
                id={product.id}
                photo_url={product.photo_url}
                title={product.title}
                price={product.amount}
                currency={product.currency}
                pictureLgBreakpoint={5}
                buttonAction={() => orderClickHandler(product)}
              />
            </Grid>
          ))
        )}
      </Grid>
    </InfiniteScroll>
  );
};
