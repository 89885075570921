import { FC, useEffect, useState } from "react";
import { Box, Typography, Divider, SvgIcon, Skeleton } from "@mui/joy";
import { toast } from "react-toastify";
import { useAppSelector, useAppDispatch } from "../../hooks";
import {
  getMaster,
  getIsMasterLoading,
  getWindows,
  getIsWindowsLoading,
} from "../../redux/master/selectors";
import { getOrderTime } from "../../redux/order/selectors";
import { Button } from "../../ui/button/button";
import { Picture } from "../../ui/picture/picture";
import { ReviewsCount } from "../reviews-count/reviews-count";
import { ReviewMaster } from "../record-actions";
import { ArrowIcon } from "../../ui/icons/arrow-icon";
import { MasterInfoSkeleton } from "./skeleton";
import { ScheduleWindow } from "../../types/types";
import { formatDate } from "../../utils/format-date";
import { toastOptions } from "../../const";

import masterActions from "../../redux/master/master-slice";
import orderActions from "../../redux/order/order-slice";

import { ReactComponent as Link } from "../../assets/link.svg";
import { ReactComponent as TelegramIcon } from "../../assets/telegram.svg";
import { ReactComponent as VKIcon } from "../../assets/vk.svg";
import { ReactComponent as InstagramIcon } from "../../assets/instagram.svg";
import { ReactComponent as WhatsAppIcon } from "../../assets/whatsapp.svg";
import { ReactComponent as Phone } from "../../assets/phone.svg";
import noImage from "../../assets/no-image.svg";

const CutyApi = require("cuty_api");

const { changeIsWindowsLoading, changeWindows } = masterActions;
const { changeStep: changeOrderStep, changeOrderModalOpen } = orderActions;

export const MasterInfo: FC = () => {
  const dispatch = useAppDispatch();

  const [showFullNumber, setShowFullNumber] = useState(false);

  const master = useAppSelector(getMaster);
  const isMasterLoading = useAppSelector(getIsMasterLoading);
  const windows = useAppSelector(getWindows);
  const isWindowsLoading = useAppSelector(getIsWindowsLoading);
  const orderTime = useAppSelector(getOrderTime);

  useEffect(() => {
    if (master) {
      const masterInstance = new CutyApi.MasterApi();
      dispatch(changeIsWindowsLoading(true));
      masterInstance.getMasterWindows(
        master.user.user_id,
        {},
        (error: any, data: ScheduleWindow[]) => {
          if (error) {
            console.error(error);
            dispatch(changeWindows([]));
            dispatch(changeIsWindowsLoading(false));
          } else {
            dispatch(changeWindows(data));
            dispatch(changeIsWindowsLoading(false));
          }
        }
      );
    }
  }, [dispatch, master]);

  if (!master || isMasterLoading) {
    return <MasterInfoSkeleton />;
  }

  const getWorkingTime = (i: "start" | "end"): string => {
    if (windows.length !== 0) {
      const firstWindows = windows[0].windows;
      if (firstWindows) {
        const idx = i === "start" ? 0 : firstWindows.length - 1;
        return formatDate(firstWindows[idx].toISOString(), {
          hour: "2-digit",
          minute: "2-digit",
        });
      }
    }
    return "-";
  };

  const {
    reviews_count,
    reviews_score,
    user: { display_name, phone, photo_url },
    location,
  } = master;

  const working_time = `${getWorkingTime("start")} - ${getWorkingTime("end")}`;

  const btnClickHandler = () => {
    dispatch(changeOrderModalOpen(true));
    if (orderTime !== "") {
      dispatch(changeOrderStep("sum"));
    } else {
      dispatch(changeOrderStep("date"));
    }
  };

  const timeClickHandler = () => {
    dispatch(changeOrderStep("date"));
    dispatch(changeOrderModalOpen(true));
  };

  const copyToClipboard = (
    text: string,
    callback: (value: boolean) => void,
    withTimeout: boolean = false
  ) => {
    navigator.clipboard.writeText(text);
    callback(true);
    withTimeout && setTimeout(() => callback(false), 3000);
  };

  const streetHouse = () => {
    const { street, house } = location;
    if (street != null && house != null) {
      return `${street} ${house}`;
    }
    return null;
  };

  const countryCity = () => {
    const { country, city } = location;
    if (country != null && city != null) {
      return `${country}, ${city}`;
    }
    if (city != null) {
      return city;
    }
    return null;
  };

  return (
    <>
      <Box display="flex" gap="20px">
        <Picture
          src={photo_url ? photo_url : noImage}
          sx={{
            minWidth: { xs: "80px", sm: "100px" },
            minHeight: { xs: "80px", sm: "100px" },
            borderRadius: "20px",
          }}
        />
        <Box>
          {reviews_count && reviews_score && (
            <ReviewsCount count={reviews_count} score={reviews_score} full dot />
          )}
          <Typography fontWeight="bold" sx={{ fontSize: { xs: "18px", sm: "24px" } }}>
            {display_name}
          </Typography>
          {phone &&
            phone.length > 0 &&
            (showFullNumber ? (
              <Typography
                sx={{
                  fontSize: { xs: "16px", sm: "20px" },
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
                fontWeight="500"
                textColor="#808186"
                onClick={() => {
                  window.location.href = `tel:${phone}`;
                }}
              >
                {phone}
                <SvgIcon component={Phone} sx={{ width: { xs: "16px", sm: "18px", md: "20px" } }} />
              </Typography>
            ) : (
              <Box sx={{ position: "relative", maxWidth: "250px" }}>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", sm: "20px" },
                    cursor: "pointer",
                    minWidth: "200px",
                    "::after": {
                      content: '""',
                      background: "linear-gradient(90deg, transparent 20%, #f8f8f8 45%)",
                      position: "absolute",
                      top: 0,
                      right: 0,
                      width: "100%",
                      height: "100%",
                    },
                  }}
                  fontWeight="500"
                  textColor="#808186"
                  onClick={() => {
                    window.location.href = `tel:${phone}`;
                  }}
                >
                  {phone.slice(0, -4) + "****"}
                </Typography>
                <Typography
                  textColor="#962BED"
                  onClick={() => setShowFullNumber(true)}
                  sx={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: "15px",
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Показать
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>

      <Box sx={{ background: "#fff", borderRadius: "20px", marginY: "20px" }}>
        <Box display="flex" padding="25px 20px" alignItems="center">
          <Typography
            width="120px"
            sx={{ fontSize: { xs: "15px", sm: "17px" } }}
            textColor="#808186"
          >
            Время
          </Typography>
          {isWindowsLoading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={100}
              height={{ xs: 15, sm: 17 }}
            />
          ) : (
            <Typography
              sx={{ fontSize: { xs: "15px", sm: "17px" }, cursor: "pointer" }}
              fontWeight="bold"
              endDecorator={<ArrowIcon />}
              onClick={timeClickHandler}
            >
              {working_time === "- - -" ? "Есть окна" : working_time}
            </Typography>
          )}
        </Box>
        {location && (
          <>
            <Divider sx={{ backgroundColor: "#e0e1e3" }} />
            <Box display="flex" padding="15px 20px" alignItems="center">
              <Typography
                width="120px"
                sx={{ fontSize: { xs: "15px", sm: "17px" } }}
                textColor="#808186"
              >
                Адрес
              </Typography>
              <Typography sx={{ fontSize: { xs: "15px", sm: "17px" } }} fontWeight="bold">
                {countryCity()}
                <br />
                {streetHouse()}
              </Typography>
            </Box>
          </>
        )}

        <Divider sx={{ backgroundColor: "#e0e1e3" }} />
        <Box display="flex" padding="25px 20px" alignItems="center">
          <Typography
            width="120px"
            sx={{ fontSize: { xs: "15px", sm: "17px" } }}
            textColor="#808186"
          >
            Связаться
          </Typography>

          <Box display="flex" gap="5px" alignItems="center">
            <Box
              onClick={() =>
                copyToClipboard(window.location.href, () =>
                  toast.success("Ссылка скопирована!", toastOptions)
                )
              }
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
              bgcolor="#962BED"
              width={{ xs: "30px", md: "35px" }}
              height={{ xs: "30px", md: "35px" }}
              sx={{
                cursor: "pointer",
                transition: ".15s",
                ":active": { transform: "scale(0.95)" },
              }}
            >
              <SvgIcon component={Link} viewBox="0 0 23 23" />
            </Box>

            {master.links.telegram && (
              <Box
                onClick={() => window.open(`https://t.me/${master.links.telegram}`)}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <SvgIcon
                  component={TelegramIcon}
                  viewBox="0 0 32 32"
                  sx={{
                    width: { xs: "34px", md: "39px" },
                    height: { xs: "34px", md: "39px" },
                    cursor: "pointer",
                    transition: ".15s",
                    ":active": { transform: "scale(0.95)" },
                  }}
                />
              </Box>
            )}

            {master.links.whatsapp && (
              <Box
                onClick={() =>
                  window.open(`https://api.whatsapp.com/send/?phone=${master.links.whatsapp}`)
                }
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding="1px"
              >
                <SvgIcon
                  component={WhatsAppIcon}
                  viewBox="0 0 128 128"
                  sx={{
                    width: { xs: "30px", md: "35px" },
                    height: { xs: "30px", md: "35px" },
                    cursor: "pointer",
                    transition: ".15s",
                    ":active": { transform: "scale(0.95)" },
                  }}
                />
              </Box>
            )}

            {master.links.vk && (
              <Box
                onClick={() => window.open(`https://vk.com/${master.links.vk}`)}
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding="2px"
              >
                <SvgIcon
                  component={VKIcon}
                  viewBox="0 0 35 35"
                  sx={{
                    width: { xs: "30px", md: "35px" },
                    height: { xs: "30px", md: "35px" },
                    cursor: "pointer",
                    transition: ".15s",
                    ":active": { transform: "scale(0.95)" },
                  }}
                />
              </Box>
            )}

            {master.links.instagram && (
              <Box
                onClick={() => window.open(`https://www.instagram.com/${master.links.instagram}`)}
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding="1px"
              >
                <SvgIcon
                  component={InstagramIcon}
                  viewBox="0 0 128 128"
                  sx={{
                    width: { xs: "30px", md: "35px" },
                    height: { xs: "30px", md: "35px" },
                    borderRadius: "50%",
                    cursor: "pointer",
                    transition: ".15s",
                    ":active": { transform: "scale(0.95)" },
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ background: "#fff", borderRadius: "20px", marginY: "20px" }}>
        <ReviewMaster master={master} padding={{ xs: "10px", sm: "12px" }} borderRadius="20px" />
      </Box>

      <Button
        fullWidth
        borderRadius={20}
        onClick={btnClickHandler}
        sx={{
          fontSize: { xs: "15px", sm: "17px" },
          paddingY: { xs: "15px", sm: "17px" },
        }}
      >
        Записаться
      </Button>
    </>
  );
};
