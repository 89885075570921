import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CategoryType, ProductType, MasterType } from "../../types/types";

type DataSliceState = {
  categories: {
    isLoading: boolean;
    error: string | null;
    data: CategoryType[];
    active: string | null;
  };
  products: {
    isLoading: boolean;
    error: string | null;
    data: ProductType[];
    hasMore: boolean;
    nextPage: number;
  };
  masters: {
    isLoading: boolean;
    error: string | null;
    data: MasterType[];
    hasMore: boolean;
    nextPage: number;
  };
};

const initialState: DataSliceState = {
  categories: {
    isLoading: false,
    error: null,
    data: [],
    active: null,
  },
  products: {
    isLoading: false,
    hasMore: true,
    error: null,
    data: [],
    nextPage: 2,
  },
  masters: {
    isLoading: false,
    error: null,
    data: [],
    hasMore: true,
    nextPage: 2,
  },
};

export const dataSlice = createSlice({
  name: "DATA",
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<CategoryType[]>) => {
      state.categories.data = action.payload;
    },
    changeCategoriesLoading: (state, action: PayloadAction<boolean>) => {
      state.categories.isLoading = action.payload;
    },
    changeCategoriesError: (state, action: PayloadAction<string>) => {
      state.categories.error = action.payload;
    },
    changeActiveCategory: (state, action: PayloadAction<string | null>) => {
      state.categories.active = action.payload;
    },

    setProducts: (state, action: PayloadAction<ProductType[]>) => {
      state.products.data = action.payload;
    },
    changeProductsLoading: (state, action: PayloadAction<boolean>) => {
      state.products.isLoading = action.payload;
    },
    changeProductsError: (state, action: PayloadAction<string>) => {
      state.products.error = action.payload;
    },
    changeProductsHasMore: (state, action: PayloadAction<boolean>) => {
      state.products.hasMore = action.payload;
    },
    changeProductsNextPage: (state, action: PayloadAction<number>) => {
      state.products.nextPage = action.payload;
    },

    setMasters: (state, action: PayloadAction<MasterType[]>) => {
      state.masters.data = action.payload;
    },
    changeMastersLoading: (state, action: PayloadAction<boolean>) => {
      state.masters.isLoading = action.payload;
    },
    changeMastersError: (state, action: PayloadAction<string>) => {
      state.masters.error = action.payload;
    },
    changeMastersHasMore: (state, action: PayloadAction<boolean>) => {
      state.masters.hasMore = action.payload;
    },
    changeMastersNextPage: (state, action: PayloadAction<number>) => {
      state.masters.nextPage = action.payload;
    },
  },
});

export default dataSlice.actions;
