export const formatPrice = (price: number, currencyCode?: string) => {
  const symbol = currencyCode && currencies[currencyCode] ? currencies[currencyCode].symbol : '₽';
  return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${symbol}`;
};

interface Currency {
  text: string;
  symbol: string;
}

interface CurrencyList {
  [key: string]: Currency;
}

const currencies: CurrencyList = {
  'RUB': {
    text: 'Russian Ruble',
    symbol: '₽'
  },
  'UAH': {
    text: 'Ukrainian Hryvnia',
    symbol: '₴'
  },
  'BYN': {
    text: 'Belarusian Ruble',
    symbol: 'Br'
  },
  'GEL': {
    text: 'Georgian Lari',
    symbol: '₾'
  },
  'AMD': {
    text: 'Armenian Dram',
    symbol: '֏'
  },
  'EUR': {
    text: 'Euro',
    symbol: '€'
  },
  'USD': {
    text: 'US Dollar',
    symbol: '$'
  },
  'KZT': {
    text: 'Kazakhstani Tenge',
    symbol: '₸'
  }
};