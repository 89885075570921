import { FC, useEffect, useState } from "react";
import { Card, CardContent, Box, Divider, Button as MUIButton, Typography, styled } from "@mui/joy";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getCategoriesLoading } from "../../redux/data/selectors";
import { getTabValue, getFilter, getSearchQuery } from "../../redux/filter/selectors";
import { CategoryType, MasterType, ProductType, FilterType } from "../../types/types";
import { Button } from "../../ui/button/button";
import { ArrowIcon } from "../../ui/icons/arrow-icon";
import { FullscreenModal } from "../../ui/modal/fullscreen-modal";
import { FixedBottomBox } from "../../ui/modal/fixed-bottom-box";
import { FilterCategories } from "./filter-categories";
import { FilterPrice } from "./filter-price";
import { FilterRating } from "./filter-rating";
import { FilterPhoto } from "./filter-photo";
import { FilterDistance } from "./filter-distance";
import { FilterSkeleton } from "./skeleton";

import dataActions from "../../redux/data/data-slice";
import filterActions from "../../redux/filter/filter-slice";

const CutyApi = require("cuty_api");

const {
  changeCategoriesLoading,
  changeCategoriesError,
  setCategories,
  changeMastersLoading,
  changeMastersError,
  setMasters,
  changeMastersHasMore,
  changeMastersNextPage,
  changeProductsLoading,
  changeProductsError,
  setProducts,
  changeProductsHasMore,
  changeProductsNextPage,
} = dataActions;

const { changeFilter, clearFilter } = filterActions;

const FilterButton = styled(MUIButton)({
  width: "100%",
  color: "#16161A",
  padding: "12px 0",
  margin: "12px 0",
  borderRadius: "15px",
  background: "#f1f1f1",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: ".2s",
  "&:hover": {
    background: "#e1e1e1",
  },
  "&:active": {
    background: "#d1d1d1",
  },
});

export const Filter: FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getCategoriesLoading);
  const tabValue = useAppSelector(getTabValue);
  const filterState = useAppSelector(getFilter);
  const searchQuery = useAppSelector(getSearchQuery);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<FilterType>({
    categoryId: "",
    price: { min: 0, max: 0 },
    highRating: false,
    onlyWithPhoto: false,
    distance: null,
  });

  const {
    categoryId,
    distance,
    highRating,
    onlyWithPhoto,
    price: { max, min },
  } = filter;

  const submitHandler = () => {
    setIsOpen(false);
    dispatch(changeFilter(filter));
    dispatch(changeMastersLoading(true));
    const masterInstance = new CutyApi.MasterApi();
    masterInstance.listMasters(
      {
        onlineRecordingAllowed: true,
        categoryId,
        distance,
        onlyWithPhoto,
        ratingMin: highRating ? 4 : null,
        limit: 10,
        page: 1,
        searchQuery,
      },
      (error: any, data: MasterType[]) => {
        if (error) {
          console.error(error);
          dispatch(changeMastersError(error));
          dispatch(changeMastersLoading(false));
        } else {
          dispatch(setMasters(data));
          dispatch(changeMastersLoading(false));
          dispatch(changeMastersNextPage(2));
          data.length < 10
            ? dispatch(changeMastersHasMore(false))
            : dispatch(changeMastersHasMore(true));
        }
      }
    );

    dispatch(changeProductsLoading(true));
    const productInstance = new CutyApi.ProductApi();
    productInstance.listProducts(
      {
        categoryId,
        amountMin: min,
        amountMax: max === 0 ? null : max,
        onlyWithPhoto,
        limit: 10,
        page: 1,
        searchQuery,
      },
      (error: any, data: ProductType[]) => {
        if (error) {
          console.error(error);
          dispatch(changeProductsError(error));
          dispatch(changeProductsLoading(false));
        } else {
          dispatch(setProducts(data));
          dispatch(changeProductsLoading(false));
          dispatch(changeProductsNextPage(2));
          data.length < 10
            ? dispatch(changeProductsHasMore(false))
            : dispatch(changeProductsHasMore(true));
        }
      }
    );
  };

  useEffect(() => {
    dispatch(clearFilter());
    dispatch(changeCategoriesLoading(true));
    const apiInstance = new CutyApi.ProductApi();
    apiInstance.listProductCategories((error: any, data: CategoryType[]) => {
      if (error) {
        console.error(error);
        dispatch(changeCategoriesError(error));
        dispatch(changeCategoriesLoading(false));
      } else {
        dispatch(setCategories(data));
        dispatch(changeCategoriesLoading(false));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    setFilter({ ...filter, categoryId: filterState.categoryId });
  }, [filterState.categoryId]);

  return (
    <>
      <Card
        sx={{
          border: "1px solid #e6e6e6",
          borderRadius: "20px",
          display: { xs: "none", md: "flex" },
        }}
      >
        {isLoading ? (
          <FilterSkeleton />
        ) : (
          <>
            <CardContent>
              <FilterCategories filter={filter} setFilter={setFilter} />
            </CardContent>

            {tabValue === "products" && (
              <CardContent>
                <Divider inset="context" sx={{ marginBottom: "10px" }} />
                <FilterPrice filter={filter} setFilter={setFilter} />
              </CardContent>
            )}

            {tabValue === "masters" && (
              <CardContent>
                <Divider inset="context" sx={{ marginBottom: "10px" }} />
                <FilterRating filter={filter} setFilter={setFilter} />
              </CardContent>
            )}

            <CardContent>
              <Divider inset="context" sx={{ marginBottom: "10px" }} />
              <FilterPhoto filter={filter} setFilter={setFilter} />
            </CardContent>

            {tabValue === "masters" && (
              <CardContent>
                <Divider inset="context" sx={{ marginBottom: "10px" }} />
                <FilterDistance filter={filter} setFilter={setFilter} />
              </CardContent>
            )}

            <CardContent>
              <Box sx={{ display: "flex", gap: "5px", marginTop: "20px" }}>
                <Button fullWidth paddingX={0} onClick={submitHandler}>
                  Показать результат
                </Button>
              </Box>
            </CardContent>
          </>
        )}
      </Card>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <FilterButton endDecorator={<ArrowIcon color="#000" />} onClick={() => setIsOpen(true)}>
          <Typography fontSize="14px" fontWeight="bold">
            Фильтр
          </Typography>
        </FilterButton>

        <FullscreenModal open={isOpen} onClose={() => setIsOpen(false)}>
          <form onSubmit={submitHandler}>
            {isLoading ? (
              <FilterSkeleton />
            ) : (
              <>
                <FilterCategories filter={filter} setFilter={setFilter} />
                {tabValue === "products" && (
                  <>
                    <Divider inset="context" sx={{ marginY: "15px" }} />
                    <FilterPrice filter={filter} setFilter={setFilter} />
                  </>
                )}

                {tabValue === "masters" && (
                  <>
                    <Divider inset="context" sx={{ marginY: "15px" }} />
                    <FilterRating filter={filter} setFilter={setFilter} />
                  </>
                )}

                <Divider inset="context" sx={{ marginY: "15px" }} />
                <FilterPhoto filter={filter} setFilter={setFilter} />

                {tabValue === "masters" && (
                  <>
                    <Divider inset="context" sx={{ marginY: "15px" }} />
                    <FilterDistance filter={filter} setFilter={setFilter} />
                  </>
                )}

                <FixedBottomBox>
                  <Button type="submit" fullWidth paddingX={0}>
                    Показать результат
                  </Button>
                </FixedBottomBox>
              </>
            )}
          </form>
        </FullscreenModal>
      </Box>
    </>
  );
};
