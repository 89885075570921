import { FC, useState } from "react";
import { Typography, Box } from "@mui/joy";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { toast } from "react-toastify";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Button } from "../../ui/button/button";
import { ArrowIcon } from "../../ui/icons/arrow-icon";
import { Input } from "../../ui/input/input";
import { FixedBottomBox } from "../../ui/modal/fixed-bottom-box";
import { BackButton } from "../back-button/back-button";
import { useAppDispatch } from "../../hooks";
import { auth } from "../../services/firebase";
import { toastOptions, emailRegExp } from "../../const";
import authActions from "../../redux/auth/auth-slice";

const { changeStep, changeEmail } = authActions;

type FormInputs = {
  email: string;
};

export const SignInMailModal: FC = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleSubmit, control } = useForm<FormInputs>({
    defaultValues: {
      email: "",
    },
  });

  const submitHandler: SubmitHandler<FormInputs> = ({ email }) => {
    setIsLoading(true);
    fetchSignInMethodsForEmail(auth, email)
      .then((methods) => {
        setIsLoading(false);
        dispatch(changeEmail(email));
        if (methods.includes("password") || methods.includes("emailLink")) {
          dispatch(changeStep("signIn_password"));
        } else {
          dispatch(changeStep("register_password"));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        toast.error("Неверные данные :(", toastOptions);
      });
  };

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Box
            sx={{
              minHeight: "350px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ marginTop: "-20px" }}>
              <BackButton onClick={() => dispatch(changeStep("signIn"))} />

              <Typography level="h2" marginBottom="7px" marginTop="20px" textAlign="center">
                Вход или регистрация
              </Typography>
              <Typography
                level="body-md"
                marginBottom="24px"
                textColor="#C0C0C0"
                fontWeight="500"
                textAlign="center"
              >
                Необходимо авторизоваться в приложении <br />
                чтобы продолжить создание записи
              </Typography>

              <Controller
                name="email"
                control={control}
                rules={{ required: true, pattern: emailRegExp }}
                render={({ field: { value, onChange }, fieldState: { invalid } }) => (
                  <Input
                    sx={{ height: "48px" }}
                    placeholder="Ваша почта"
                    type="email"
                    value={value}
                    onChange={onChange}
                    invalid={invalid}
                  />
                )}
              />
            </Box>

            <Button
              type="submit"
              fullWidth
              loading={isLoading}
              endDecorator={
                <Box
                  sx={{
                    position: "absolute",
                    right: "32px",
                    top: "50%",
                    transform: "translateY(-50%) rotate(-90deg)",
                  }}
                >
                  <ArrowIcon color="#fff" />
                </Box>
              }
            >
              Далее
            </Button>
          </Box>
        </form>
      </Box>

      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <BackButton onClick={() => dispatch(changeStep("signIn"))} />

          <Typography level="h3" marginBottom="7px" marginTop="10px" textAlign="center">
            Вход или регистрация
          </Typography>
          <Typography
            level="body-sm"
            marginBottom="24px"
            textColor="#C0C0C0"
            fontWeight="500"
            textAlign="center"
          >
            Необходимо авторизоваться в приложении <br />
            чтобы продолжить создание записи
          </Typography>

          <Controller
            name="email"
            control={control}
            rules={{ required: true, pattern: emailRegExp }}
            render={({ field: { value, onChange }, fieldState: { invalid } }) => (
              <Input
                sx={{ height: "48px" }}
                placeholder="Ваша почта"
                type="email"
                value={value}
                onChange={onChange}
                invalid={invalid}
              />
            )}
          />

          <FixedBottomBox>
            <Button
              type="submit"
              fullWidth
              loading={isLoading}
              endDecorator={
                <Box
                  sx={{
                    position: "absolute",
                    right: "32px",
                    top: "50%",
                    transform: "translateY(-50%) rotate(-90deg)",
                  }}
                >
                  <ArrowIcon color="#fff" />
                </Box>
              }
            >
              Далее
            </Button>
          </FixedBottomBox>
        </form>
      </Box>
    </>
  );
};
